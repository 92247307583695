import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import RPSGameCountdown from '../../components/rps/game/RPSGameCountdown';
import RPSGameEventsLog from '../../components/rps/game/RPSGameEventsLog';
import RPSGameTotalPointsLeft from '../../components/rps/game/RPSGameTotalPointsLeft';
import RPSGameTotalPointsRight from '../../components/rps/game/RPSGameTotalPointsRight';
import RPSGameTotalPrize from '../../components/rps/game/RPSGameTotalPrize';
import RPSGameUIButton from '../../components/rps/game/RPSGameUIButton';
import RPSInfoCard from '../../components/rps/info/RPSInfoCard';
import LeaveButton from '../../components/utils/buttons/LeaveButton';
import RoomLinkCopyButton from '../../components/utils/buttons/RoomLinkCopyButton';
import RPSTitle from '../../components/utils/graphics/RPSTitle';
import { useContract } from '../../contexts/ContractProvider';
import getAddress from '../../shared/functions/getAddress';
import translate from '../../shared/functions/translate';

const RPSGame = () => {
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  // contexts
  const { rpsContract } = useContract();
  const { chainId } = useWeb3React<Web3Provider>();

  // states and contexts
  const [entryFee, setEntryFee] = useState<string>(
    ethers.BigNumber.from('0').toString()
  );
  const [players, setPlayers] = useState<string[]>(
    new Array(2).fill(ethers.constants.AddressZero)
  );
  const [scores, setScores] = useState<number[]>([0, 0]);
  const [totalPrize, setTotalPrize] = useState<string>(
    ethers.BigNumber.from('0').toString()
  );
  const [gameState, setGameState] = useState<number>(0); // game states 0: not created, 1: not started, 2: started, 3: ended
  const [round, setRound] = useState<number>(0);
  const [isTxSendable, setIsTxSendable] = useState<boolean>(false);
  const [isSigned, setIsSigned] = useState<boolean[]>([false, false]);
  const [selections, setSelections] = useState<number[]>([0, 0]);
  const [winScore, setWinScore] = useState<number>(3);
  const [isNextAvailable, setIsNextAvailable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true); // for button
  const [remainingTime, setRemainingTime] = useState<number>(60);
  const isSubscribed = useRef<boolean>(false); // memory leak fix

  // fetching game info
  const getPeriodicGameInfo = async () => {
    try {
      const gameInfo = await rpsContract?.getGameInfo(path);
      const fetchedEntryFee = gameInfo[6].toString();
      const fetchedPlayers = gameInfo[1];
      let fetchedScores = gameInfo[2];
      fetchedScores = fetchedScores.map((point: number, index: number) =>
        fetchedPlayers[index] === ethers.constants.AddressZero ? 0 : point
      );
      const fetchedTotalPrize = gameInfo[3];
      const fetchedGameState = gameInfo[5];
      const fetchedSelections = gameInfo[8];
      const fetchedWinScore = gameInfo[9];
      if (isSubscribed.current) {
        setEntryFee(fetchedEntryFee);
        setPlayers(fetchedPlayers);
        setScores(fetchedScores);
        setTotalPrize(fetchedTotalPrize.toString());
        setGameState(fetchedGameState);
        setSelections(fetchedSelections);
        setWinScore(fetchedWinScore.toNumber());
      }
      if (fetchedGameState === 2) {
        const fetchedRound = gameInfo[4];
        const fetchedIsTxSendable = await rpsContract?.isTxSendable(path);
        const fetchedIsSigned = gameInfo[7];
        const fetchedIsNextAvailable = await rpsContract?.isRoundRestartable(
          path
        );
        if (isSubscribed.current) {
          setRound(fetchedRound.toNumber());
          setIsTxSendable(fetchedIsTxSendable);
          setIsSigned(
            fetchedIsSigned.map(
              (hash: string) => hash !== ethers.constants.HashZero
            )
          );
          setIsNextAvailable(fetchedIsNextAvailable);
        }
      } else if (isSubscribed.current) {
        setIsNextAvailable(false);
      }
      const [fetchedStartTime, signDuration, txDuration] =
        await rpsContract?.getStartTime(path);
      if (fetchedStartTime.toNumber() !== 0 && fetchedGameState === 2) {
        const remainingSeconds = Math.floor(
          txDuration.toNumber() -
            (Date.now() / 1000 - fetchedStartTime.toNumber())
        );
        if (
          remainingSeconds > signDuration.toNumber() &&
          isSubscribed.current
        ) {
          setRemainingTime(remainingSeconds - signDuration.toNumber());
        } else if (
          remainingSeconds <= signDuration &&
          remainingSeconds > 0 &&
          isSubscribed.current
        ) {
          setRemainingTime(remainingSeconds);
        } else if (isSubscribed.current) {
          setRemainingTime(0);
        }
      }
      if (isSubscribed.current) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // cleanup states on unmount
  const cleanup = () => {
    setEntryFee(ethers.BigNumber.from('0').toString());
    setPlayers(new Array(2).fill(ethers.constants.AddressZero));
    setScores([0, 0]);
    setTotalPrize(ethers.BigNumber.from('0').toString());
    setGameState(0);
    setIsNextAvailable(false);
    setIsTxSendable(false);
    setIsSigned([false, false]);
    setIsLoading(true);
    setRemainingTime(60);
  };

  // lifecycle hooks
  useEffect(() => {
    isSubscribed.current = true;
    const rpsGameJsonRpcProvider = new ethers.providers.JsonRpcProvider(
      getAddress(chainId, 'provider')
    );
    rpsGameJsonRpcProvider.on('block', () => {
      getPeriodicGameInfo();
    });
    return () => {
      isSubscribed.current = false;
      rpsGameJsonRpcProvider.off('block');
      cleanup();
    };
  }, [chainId]);

  return (
    <motion.div
      key="djackGame"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="card">
        <div className="cardTitle">
          <RPSTitle />
        </div>
        <div className="innerCard">
          <div className="innerCardTitle innerCardTitleWithButton">
            <RoomLinkCopyButton title={translate('gamingArena')} />
            <LeaveButton
              players={players}
              contract={rpsContract}
              redirect="/rpslobby"
              seated={false}
            />
          </div>
          <div className="djackGameGamingAreaWrapper">
            <RPSGameTotalPointsLeft
              players={players}
              scores={scores}
              selections={selections}
              winScore={winScore}
            />
            <RPSGameUIButton
              gameState={gameState}
              players={players}
              isNextAvailable={isNextAvailable}
              isLoading={isLoading}
              round={round}
              isTxSendable={isTxSendable}
              isSigned={isSigned}
            />
            <RPSGameTotalPointsRight
              players={players}
              scores={scores}
              selections={selections}
              winScore={winScore}
            />
          </div>
          <div className="djackGameCountdownWrapper">
            <RPSGameCountdown
              remainingTime={remainingTime}
              gameState={gameState}
            />
          </div>
        </div>
        <div className="innerCard">
          <RPSGameTotalPrize
            entryFee={entryFee}
            totalPrize={totalPrize}
            winScore={winScore}
          />
        </div>
        <div className="innerCard" style={{ height: '200px' }}>
          <div className="innerCardTitle">{translate('eventsLog')}</div>
          <RPSGameEventsLog />
        </div>
      </div>
      <RPSInfoCard />
    </motion.div>
  );
};
export default RPSGame;
