import { useEffect, useState } from 'react';
// import { useLocation } from 'react-router';
import { motion } from 'framer-motion';
import { PieChart } from 'react-minimal-pie-chart';
import LoadingSpinner from '../utils/graphics/LoadingSpinner';
import DBetGameDiceRoll from './DBetGameDiceRoll';

interface IProps {
  totalBets: number[];
  rollValue: number;
  isRolled: boolean;
  isLoading: boolean;
}

const DBetGameRollArea: React.FC<IProps> = ({
  totalBets,
  rollValue,
  isRolled,
  isLoading
}: IProps) => {
  // states
  const [diceValue, setDiceValue] = useState<number>(rollValue + 1);

  // effects
  useEffect(() => {
    const interval = setInterval(() => {
      if (isRolled) {
        setDiceValue((prevDiceValue) => (prevDiceValue % 6) + 1);
      }
    }, 1400);
    if (!isRolled || rollValue !== 0) {
      clearInterval(interval);
    }
    setDiceValue(rollValue + 1);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRolled, rollValue]);

  const colors = [
    '#94B3FD',
    '#94DAFF',
    '#99FEFF',
    '#C996CC',
    '#B983FF',
    '#916BBF'
  ];

  let sumBets = totalBets.reduce((a, b) => a + b, 0);
  sumBets = sumBets === 0 ? 1 : sumBets;

  const betData = totalBets.map((bet, index) => ({
    title: `${index + 1}`,
    value: bet <= sumBets / 6 ? sumBets / 6 : bet,
    color: colors[index]
  }));

  return (
    <>
      <PieChart
        radius={48}
        data={betData}
        lineWidth={6}
        startAngle={274}
        paddingAngle={8}
        animate
        label={({ dataEntry }) => dataEntry.title}
        labelStyle={(index) => ({
          fill: betData[index].color,
          fontSize: '8px',
          fontFamily: 'Poppins, sans-serif'
        })}
        labelPosition={75}
        segmentsShift={(index) => (index === diceValue - 1 ? 1 : 0)}
      />
      <div className="dbetGameDiceRollContainer">
        <div className="dbetGameDiceRollWrapper">
          {isLoading ? (
            <motion.div
              key="djackGameRollButtonLoader"
              variants={{
                collapsed: { opacity: 0 },
                open: {
                  opacity: 1
                }
              }}
              initial="collapsed"
              animate="open"
              exit="collapsed"
            >
              <LoadingSpinner />
            </motion.div>
          ) : (
            <motion.div
              key="djackGameRollButtonWrapper"
              variants={{
                collapsed: { opacity: 0 },
                open: {
                  opacity: 1
                }
              }}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              className="djackGameDiceValWrapper"
            >
              <DBetGameDiceRoll diceValue={diceValue} />
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};

export default DBetGameRollArea;
