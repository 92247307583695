import { ethers } from 'ethers';
import { useSelector } from 'react-redux';
import translate from '../../../shared/functions/translate';
import { IReduxState } from '../../../shared/interfaces';

interface IProps {
  entryFee: string;
  totalPrize: string;
}

const OnEGameTotalPrize: React.FC<IProps> = ({
  entryFee,
  totalPrize
}: IProps) => {
  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  return (
    <div className="oneGameTotalPrizeWrapper">
      <div className="prizeWrapper oneGameTotalPrizeItem">
        <div className="prizeText">{translate('entryFee')}</div>
        <div className="prizeValue">
          {`${ethers.utils.formatEther(entryFee)} `}
          <span className="prizeUnit">{coinData.name}</span>
        </div>
      </div>
      <div className="prizeWrapper oneGameTotalPrizeItem">
        <div className="prizeText">{translate('totalPrize')}</div>
        <div className="prizeValue">
          {`${ethers.utils.formatEther(totalPrize)} `}
          <span className="prizeUnit">{coinData.name}</span>
        </div>
      </div>
      <div className="prizeWrapper oneGameTotalPrizeItem">
        <div className="prizeText">{translate('winScore')}</div>
        <div className="prizeValue">20</div>
      </div>
    </div>
  );
};

export default OnEGameTotalPrize;
