import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import PlayerIcon from '../../utils/graphics/PlayerIcon';
import RPSGameTotalPointsScreen from './RPSGameTotalPointsScreen';
import RPSGameTotalPointsText from './RPSGameTotalPointsText';
import RPSGameTotalPointsRecent from './RPSGameTotalPointsRecent';
import AddressCopyButton from '../../utils/buttons/AddressCopyButton';
import translate from '../../../shared/functions/translate';

interface IProps {
  players: string[];
  scores: number[];
  selections: number[];
  winScore: number;
}

const RPSGameTotalPointsLeft: React.FC<IProps> = ({
  players,
  scores,
  selections,
  winScore
}: IProps) => {
  // states and contexts
  const [winState, setWinState] = useState<string>('draw');
  const { account } = useWeb3React<Web3Provider>();

  // functions
  const calculateWinState = () => {
    if (scores[0] === scores[1]) {
      setWinState('draw');
    } else if (scores[0] > scores[1]) {
      setWinState('win');
    } else {
      setWinState('lose');
    }
  };

  useEffect(() => {
    calculateWinState();
  }, [players, scores]);

  return (
    <div className="rpsGameTotalPointsWrapper">
      <RPSGameTotalPointsScreen
        totalPointsInput={scores[0]}
        winState={winState}
        winScore={winScore}
      />
      <div className="rpsGameTotalPointsPlayerContainer">
        <div className="rpsGameTotalPointsTextContainer">
          <span className="rpsGameTotalPointsHeader">{translate('score')}</span>
          <RPSGameTotalPointsText totalPointsInput={scores[0]} />
        </div>
        <div className="playerWrapper">
          <div className="playerIcon">
            <PlayerIcon active={!!(account && players[0] === account)} />
          </div>
          <AddressCopyButton
            address={players[0]}
            active={!!(account && players[0] === account)}
          />
        </div>
        <RPSGameTotalPointsRecent
          positionIndex={0}
          selections={selections}
          players={players}
        />
      </div>
    </div>
  );
};

export default RPSGameTotalPointsLeft;
