import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../redux/notificationSlice';
import { IReduxState } from '../../shared/interfaces';
import Button from '../utils/buttons/Button';
import LoadingDots from '../utils/graphics/LoadingDots';

const FaucetForm = () => {
  // states
  const [isAwaitingReceipt, setIsAwaitingReceipt] = useState<boolean>(false);

  // contexts
  const { account } = useWeb3React<Web3Provider>();

  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);
  const { isVisible } = useSelector((state: IReduxState) => state.notification);
  const dispatch = useDispatch();

  // functions
  const onSubmit = async () => {
    setIsAwaitingReceipt(true);
    try {
      // tx here
      setTimeout(() => {
        console.log(`${coinData.name} faucet request for ${account}.`);
        if (!isVisible) {
          dispatch(setNotification(`Funding successful!`));
        }
        setIsAwaitingReceipt(false);
      }, 3000);
    } catch (error: any) {
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
      setIsAwaitingReceipt(false);
    }
  };

  return (
    <div className="faucetForm">
      <span>
        {coinData.name === 'TBNB'
          ? 'Please use BSC Faucet for $TBNB'
          : coinData.name !== 'BITCO'
          ? `Faucet is not available for $${coinData.name}`
          : 'Give me $BITCO'}
      </span>
      <Button
        onClick={{ onClick: onSubmit }}
        buttonClass="buttonOutlined buttonBlue"
        buttonText={isAwaitingReceipt ? <LoadingDots /> : 'Charge'}
        buttonDisabled={isAwaitingReceipt || coinData.name !== 'BITCO'}
      />
    </div>
  );
};

export default FaucetForm;
