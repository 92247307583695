import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import { DBetIconSvg, OnEIconSvg, RPSIconSvg } from './HomepageIcons';
import controllerIcon from '../../shared/constants/icons/controller_icon.png';
import arrowNextIcon from '../../shared/constants/icons/arrow_next_icon.png';
import translate from '../../shared/functions/translate';

const HomepageGamesList = ({ currentBlock }: IProps) => {
  // redirect
  const history = useHistory();

  const onButtonClick = (text: string) => {
    if (text === 'OnE') {
      history.push('/onelobby');
    } else if (text === 'RPS') {
      history.push('/rpslobby');
      // } else if (text === 'DJack') {
      //   history.push('/djacklobby');
    } else if (text === 'DBet') {
      if (currentBlock > 0) {
        const nextGameBlock = Math.ceil(currentBlock / 100) * 100;
        history.push(`/dbetgame/${nextGameBlock}`);
      }
    }
  };

  // motion
  const info = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 2.5,
        duration: 1
      }
    }
  };
  return (
    <motion.div
      key="homepageGamesListWrapper"
      variants={info}
      initial="hidden"
      animate="visible"
    >
      <div className="cardTitle">
        <img
          src={controllerIcon}
          alt="controllerIcon"
          className="homepageGamesListTitleIcon"
        />
        {translate('gamesList')}
      </div>
      <div className="homepageGamesListContainer">
        {/* <div className="homepageGamesListItem">
          <div className="homepageGamesListItemLeft">
            <div className="homepageGamesListItemIconWrapper">
              <DJackIconSvg white />
            </div>
            <div className="homepageGamesListItemTextWrapper">
              <h4>Dice Jack</h4>
              <p>{translate('djackSubTitle')}</p>
            </div>
          </div>
          <div className="homepageGamesListItemRight">
            <button type="button" onClick={() => onButtonClick('DJack')}>
              <img src={arrowNextIcon} alt="arrowNextIcon" />
            </button>
          </div>
        </div> */}
        <div className="homepageGamesListItem">
          <div className="homepageGamesListItemLeft">
            <div className="homepageGamesListItemIconWrapper">
              <OnEIconSvg white />
            </div>
            <div className="homepageGamesListItemTextWrapper">
              <h4>Odds n Evens</h4>
              <p>{translate('oneSubTitle')}</p>
            </div>
          </div>
          <div className="homepageGamesListItemRight">
            <button type="button" onClick={() => onButtonClick('OnE')}>
              <img src={arrowNextIcon} alt="arrowNextIcon" />
            </button>
          </div>
        </div>
        <div className="homepageGamesListItem">
          <div className="homepageGamesListItemLeft">
            <div className="homepageGamesListItemIconWrapper">
              <RPSIconSvg white />
            </div>
            <div className="homepageGamesListItemTextWrapper">
              <h4>Rock Paper Scissors</h4>
              <p>{translate('rpsSubTitle')}</p>
            </div>
          </div>
          <div className="homepageGamesListItemRight">
            <button type="button" onClick={() => onButtonClick('RPS')}>
              <img src={arrowNextIcon} alt="arrowNextIcon" />
            </button>
          </div>
        </div>
        <div className="homepageGamesListItem">
          <div className="homepageGamesListItemLeft">
            <div className="homepageGamesListItemIconWrapper">
              <DBetIconSvg white />
            </div>
            <div className="homepageGamesListItemTextWrapper">
              <h4>Dice Bet</h4>
              <p>{translate('dbetSubTitle')}</p>
            </div>
          </div>
          <div className="homepageGamesListItemRight">
            <button type="button" onClick={() => onButtonClick('DBet')}>
              <img src={arrowNextIcon} alt="arrowNextIcon" />
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default HomepageGamesList;

interface IProps {
  currentBlock: number;
}
