import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import HomepageContact from '../../components/homepage/HomepageContact';
import HomepageGamesList from '../../components/homepage/HomepageGamesList';
import HomepageInfoButtons from '../../components/homepage/HomepageInfoButtons';
import HomepageInfoCard from '../../components/homepage/HomepageInfoCard';
import HomepageSlider from '../../components/homepage/HomepageSlider';
import HomepageTitle from '../../components/homepage/HomepageTitle';
import getAddress from '../../shared/functions/getAddress';

const Homepage = () => {
  const { chainId } = useWeb3React<Web3Provider>();

  const [currentBlock, setCurrentBlock] = useState<number>(0);

  // lifecycle hooks with unmount subscription cancelation
  useEffect(() => {
    let isSubscribed = true;
    const homepageJsonRpcProvider = new ethers.providers.JsonRpcProvider(
      getAddress(chainId, 'provider')
    );
    homepageJsonRpcProvider.on('block', (blockNumber: number) => {
      if (isSubscribed) {
        setCurrentBlock(blockNumber);
      }
    });
    return () => {
      isSubscribed = false;
      homepageJsonRpcProvider.off('block');
    };
  }, [chainId]);

  return (
    <motion.div
      key="Home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="card">
        <HomepageTitle />
      </div>
      <div className="card">
        <HomepageInfoButtons />
      </div>
      <div className="card">
        <HomepageSlider currentBlock={currentBlock} />
      </div>
      <div className="card">
        <HomepageGamesList currentBlock={currentBlock} />
      </div>
      <div className="card">
        <HomepageInfoCard />
      </div>
      <div className="card">
        <HomepageContact />
      </div>
    </motion.div>
  );
};
export default Homepage;
