import { motion } from 'framer-motion';

interface IProps {
  currentPage: number;
  isLastPage?: boolean;
  onPageChange: (newPage: number) => void;
}

const defaultProps = {
  isLastPage: false
};

const LobbyPagination: React.FC<IProps> = ({
  currentPage,
  isLastPage,
  onPageChange
}: IProps) => {
  // calculate pages
  let pageNumbers: number[] = [];
  if (currentPage === 1 && isLastPage) {
    pageNumbers = [1];
  } else if (currentPage === 1) {
    pageNumbers = [1, 2];
  } else if (isLastPage) {
    pageNumbers = [currentPage - 1, currentPage];
  } else {
    pageNumbers = [currentPage - 1, currentPage, currentPage + 1];
  }

  return (
    <motion.div
      key="lobbyJoinPagination"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1 },
        collapsed: { opacity: 0 }
      }}
      transition={{ duration: 2.4, ease: 'easeInOut' }}
      className="lobbyPaginationContainer"
    >
      <button
        type="button"
        className={`lobbyPaginationButton${
          currentPage === 1 ? ' lobbyPaginationButtonDisabled' : ''
        }`}
        onClick={() => {
          if (currentPage !== 1) onPageChange(currentPage - 1);
        }}
      >
        <div className="lobbyPaginationBackIcon">
          <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
            <path
              fill={currentPage === 1 ? '#484848' : '#f8f8f8'}
              d="M38,52a2,2,0,0,1-1.41-.59l-24-24a2,2,0,0,1,0-2.82l24-24a2,2,0,0,1,2.82,0,2,2,0,0,1,0,2.82L16.83,26,39.41,48.59A2,2,0,0,1,38,52Z"
            />
          </svg>
        </div>
      </button>
      <div className="lobbyPageNumbersWrapper">
        {pageNumbers.map((pageNumber: number) => (
          <button
            key={pageNumber}
            type="button"
            className={`lobbyPaginationButton${
              pageNumber === currentPage ? ' lobbyPaginationButtonActive' : ''
            }`}
            onClick={() => {
              if (currentPage !== pageNumber) onPageChange(pageNumber);
            }}
          >
            {pageNumber}
          </button>
        ))}
      </div>
      <button
        type="button"
        className={`lobbyPaginationButton${
          isLastPage ? ' lobbyPaginationButtonDisabled' : ''
        }`}
        onClick={() => {
          if (!isLastPage) onPageChange(currentPage + 1);
        }}
      >
        <div className="lobbyPaginationBackIcon">
          <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
            <path
              fill={isLastPage ? '#484848' : '#f8f8f8'}
              d="M14,52a2,2,0,0,1-1.41-3.41L35.17,26,12.59,3.41a2,2,0,0,1,0-2.82,2,2,0,0,1,2.82,0l24,24a2,2,0,0,1,0,2.82l-24,24A2,2,0,0,1,14,52Z"
            />
          </svg>
        </div>
      </button>
    </motion.div>
  );
};

LobbyPagination.defaultProps = defaultProps;
export default LobbyPagination;
