import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import arrowLeftIcon from '../../shared/constants/icons/arrow_left_icon.png';
import arrowRightIcon from '../../shared/constants/icons/arrow_right_icon.png';
import translate from '../../shared/functions/translate';
import { DBetIconSvg, OnEIconSvg, RPSIconSvg } from './HomepageIcons';

const HomepageSlider = ({ currentBlock }: IProps) => {
  // slider
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const handleClick = (direction: string) => {
    if (direction === 'left') {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleClick('right');
    }, 5000);
    return () => clearInterval(interval);
  }, [slideIndex]);

  // redirect
  const history = useHistory();

  const onButtonClick = (text: string) => {
    if (text === 'OnE') {
      history.push('/onelobby');
    } else if (text === 'RPS') {
      history.push('/rpslobby');
    } else if (text === 'DBet') {
      // dbet game
      if (currentBlock > 0) {
        const nextGameBlock = Math.ceil(currentBlock / 100) * 100;
        history.push(`/dbetgame/${nextGameBlock}`);
      }
    }
  };

  // motion
  const info = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 2.4,
        duration: 1
      }
    }
  };

  return (
    <motion.div
      key="homepageFeaturedGamesWrapper"
      variants={info}
      initial="hidden"
      animate="visible"
    >
      <h1 className="homepageSliderTitle">{translate('featuredGames')}</h1>
      <div className="homepageSliderWrapper">
        <div
          className="homepageSliderNavigateIconContainer homepageSliderNavigateIconContainerLeft"
          onClick={() => handleClick('left')}
        >
          <img
            src={arrowLeftIcon}
            alt="arrowLeftIcon"
            className="homepageSliderNavigateIcon"
          />
        </div>
        <div
          className="homepageSliderContainer"
          style={{ transform: `translateX(${slideIndex * -33.3333}%)` }}
        >
          <div className="homepageSliderSection" key="homepageSliderSection-1">
            <div className="homepageSliderSectionBackground">
              <OnEIconSvg />
            </div>
            <div className="homepageSliderSectionText">
              <h1>ODDS N EVENS</h1>
              <p>{translate('oneSubTitle')}</p>
              <button type="button" onClick={() => onButtonClick('OnE')}>
                {translate('playNow')}
              </button>
            </div>
          </div>
          <div className="homepageSliderSection" key="homepageSliderSection-2">
            <div className="homepageSliderSectionBackground">
              <RPSIconSvg />
            </div>
            <div className="homepageSliderSectionText">
              <h1>ROCK PAPER SCISSORS</h1>
              <p>{translate('rpsSubTitle')}</p>
              <button type="button" onClick={() => onButtonClick('RPS')}>
                {translate('playNow')}
              </button>
            </div>
          </div>
          <div className="homepageSliderSection" key="homepageSliderSection-3">
            <div className="homepageSliderSectionBackground">
              <DBetIconSvg />
            </div>
            <div className="homepageSliderSectionText">
              <h1>DICE BET</h1>
              <p>{translate('djackSubTitle')}</p>
              <button type="button" onClick={() => onButtonClick('DBet')}>
                {translate('playNow')}
              </button>
            </div>
          </div>
        </div>
        <div
          className="homepageSliderNavigateIconContainer homepageSliderNavigateIconContainerRight"
          onClick={() => handleClick('right')}
        >
          <img
            src={arrowRightIcon}
            alt="arrowRightIcon"
            className="homepageSliderNavigateIcon"
          />
        </div>
        <div
          className="homepageSliderDisplayer"
          style={{ transform: `translateX(${(2 - slideIndex) * -20}%)` }}
        >
          <span className="homepageSliderDisplayerColumn" />
          <span className="homepageSliderDisplayerColumn" />
          <span className="homepageSliderDisplayerColumn homepageSliderDisplayerColumnActive" />
          <span className="homepageSliderDisplayerColumn" />
          <span className="homepageSliderDisplayerColumn" />
        </div>
      </div>
    </motion.div>
  );
};

export default HomepageSlider;

interface IProps {
  currentBlock: number;
}
