import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import infoIcon from '../../shared/constants/icons/info_icon.png';
import arrowUpIcon from '../../shared/constants/icons/arrow_up_icon.png';
import arrowDownIcon from '../../shared/constants/icons/arrow_down_icon.png';
import translate from '../../shared/functions/translate';
import { IJSONObject } from '../../shared/interfaces';

const HomepageInfoCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  // motion
  const info = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 2.6,
        duration: 1
      }
    }
  };

  // fixed hooks translation
  const translatedText: IJSONObject = {
    bitcolympicsDappInfo: translate('bitcolympicsDappInfo'),
    smartContractGames: translate('smartContractGames'),
    smartContractGamesInfo: translate('smartContractGamesInfo'),
    userPrivacy: translate('userPrivacy'),
    userPrivacyInfo: translate('userPrivacyInfo'),
    completeDataIntegrity: translate('completeDataIntegrity'),
    completeDataIntegrityInfo: translate('completeDataIntegrityInfo')
  };

  return (
    <motion.div
      key="homepageInfoCardWrapper"
      variants={info}
      initial="hidden"
      animate="visible"
      className="homepageInfoCardWrapper"
    >
      <div className="cardTitle">
        <img src={infoIcon} alt="infoIcon" className="gameInfoIcon" />
        {translate('about')}
      </div>
      <AnimatePresence key="homepageInfoButton" exitBeforeEnter>
        {!isOpen ? (
          <motion.button
            key="arrowDownButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowDownIcon}
              alt="arrowDownIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        ) : (
          <motion.button
            key="arrowUpButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowUpIcon}
              alt="arrowUpIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        )}
      </AnimatePresence>
      <motion.div
        key="homepageInfoCard"
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: '460px',
            transition: {
              delayChildren: 0.6,
              staggerChildren: 0.6,
              duration: 1.2,
              ease: 'easeInOut'
            }
          },
          collapsed: {
            height: '0px'
          }
        }}
        transition={{ duration: 1.2, ease: 'easeInOut' }}
      >
        {isOpen && (
          <>
            <motion.div
              key="homepageAbout-1"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="homepageInfoCardContainer"
            >
              <h4>Bitcolympics DAPP</h4>
              <p>{translatedText.bitcolympicsDappInfo}</p>
            </motion.div>
            <motion.div
              key="homepageAbout-2"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="homepageInfoCardContainer"
            >
              <h4>{translatedText.smartContractGames}</h4>
              <p>{translatedText.smartContractGamesInfo}</p>
            </motion.div>
            <motion.div
              key="homepageAbout-3"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="homepageInfoCardContainer"
            >
              <h4>{translatedText.userPrivacy}</h4>
              <p>{translatedText.userPrivacyInfo}</p>
            </motion.div>
            <motion.div
              key="homepageAbout-4"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="homepageInfoCardContainer"
            >
              <h4>{translatedText.completeDataIntegrity}</h4>
              <p>{translatedText.completeDataIntegrityInfo}</p>
            </motion.div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

export default HomepageInfoCard;
