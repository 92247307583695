import { motion } from 'framer-motion';

const OnETitle = () => {
  const title = 'Odds n Evens';
  const line = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 1.6,
        staggerChildren: 0.1
      }
    }
  };
  const letter = {
    hidden: {
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };
  const svgAnimation = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2
      }
    }
  };
  const svgVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1
    }
  };
  const svgTransition = {
    ease: 'easeInOut',
    duration: 0.6
  };
  return (
    <>
      <motion.svg
        key="oneTitleSvg"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 118 118"
        variants={svgAnimation}
        initial="hidden"
        animate="visible"
        className="titleSvg"
      >
        <motion.circle
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="6"
          cx="58"
          cy="35"
          r="19.5"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.path
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="6"
          d="M57.2,15.5c-20,23.6,23.4,12.2,0,39"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.circle
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="6"
          cx="30.3"
          cy="83.4"
          r="19.5"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.path
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="6"
          d="M24,65c0,16.9,9.6,30.7,21.5,30.7"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.circle
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="6"
          cx="88.3"
          cy="83.4"
          r="19.5"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.path
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="6"
          d="M82,101.9c0-18.3,8.5-33.2,19.1-33.2"
          variants={svgVariants}
          transition={svgTransition}
        />
      </motion.svg>
      <motion.span
        key="oneTitleText"
        variants={line}
        initial="hidden"
        animate="visible"
      >
        {title.split('').map((char, i) => (
          <motion.span key={i} variants={letter}>
            {char}
          </motion.span>
        ))}
      </motion.span>
    </>
  );
};

export default OnETitle;
