import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import getChainInfo from '../../shared/functions/getChainInfo';
import translate from '../../shared/functions/translate';

interface IProps {
  gameBalance: number;
}

const DBetGameTotalPrize: React.FC<IProps> = ({ gameBalance }: IProps) => {
  const { chainId } = useWeb3React<Web3Provider>();

  return (
    <div className="prizeWrapper">
      <div className="prizeText">{translate('prizePool')}</div>
      <div className="prizeValue">{`${gameBalance} ${getChainInfo(
        chainId,
        'currency'
      )}`}</div>
    </div>
  );
};

export default DBetGameTotalPrize;
