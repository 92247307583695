import { ethers } from 'ethers';
import { supportedChainInfo } from '../../shared/constants/supportedChains';
import translate from '../../shared/functions/translate';
import { IJSONObject } from '../../shared/interfaces';

const NavbarSwitchChainModal = () => {
  const handleSwitchChain = async () => {
    const { ethereum } = window as any;
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexlify(97) }]
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [supportedChainInfo[97]]
          });
        } catch (addError: any) {
          console.error(`Chain adding error: ${addError}`);
        }
      } else {
        console.error(`Chain switching error: ${switchError}`);
      }
    }
  };

  // fixed hooks translation
  const translatedText: IJSONObject = {
    switchSupportedChain: translate('switchSupportedChain')
  };

  return (
    <div className="navbarSwitchChainModal">
      <span>{translatedText.switchSupportedChain}</span>
      <button type="button" onClick={handleSwitchChain}>
        BSC TESTNET
      </button>
    </div>
  );
};

export default NavbarSwitchChainModal;
