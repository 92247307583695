interface IProps {
  color?: string;
}

const defaultProps = {
  color: '#f8f8f8'
};

const NavbarBottomSheetWalletWithdrawIcon: React.FC<IProps> = ({
  color
}: IProps) => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <style>
      {`
        .st0 {
          transition: fill 0.3s;
        }
      `}
    </style>
    <path
      fill={color}
      className="st0"
      d="M152.72,35.75a9.67,9.67,0,0,0-14,0c-3,2-3.5,5-3.5,8v113.5a10,10,0,0,0,20,0V66.75l7.5,7.5a9.67,9.67,0,0,0,14,0c4-3.5,4-10,.5-14Z"
    />
    <path
      fill={color}
      className="st0"
      d="M110.22,142.75h-80a10,10,0,0,0,0,20h80a10,10,0,1,0,0-20Z"
    />
    <path
      fill={color}
      className="st0"
      d="M110.22,62.75h-80a10,10,0,0,0,0,20h80a10,10,0,0,0,0-20Z"
    />
    <path
      fill={color}
      className="st0"
      d="M30.22,122.75h70a10,10,0,0,0,0-20h-70a10,10,0,0,0,0,20Z"
    />
  </svg>
);

NavbarBottomSheetWalletWithdrawIcon.defaultProps = defaultProps;
export default NavbarBottomSheetWalletWithdrawIcon;
