import { ethers } from 'ethers';
import { useSelector } from 'react-redux';
import translate from '../../../shared/functions/translate';
import { IReduxState } from '../../../shared/interfaces';

interface IProps {
  entryFee: string;
  totalPrize: string;
  winScore: number;
}

const RPSGameTotalPrize: React.FC<IProps> = ({
  entryFee,
  totalPrize,
  winScore
}: IProps) => {
  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  return (
    <div className="rpsGameTotalPrizeWrapper">
      <div className="prizeWrapper oneGameTotalPrizeItem">
        <div className="prizeText">{translate('entryFee')}</div>
        <div className="prizeValue">
          {`${ethers.utils.formatEther(entryFee)} `}
          <span className="prizeUnit">{coinData.name}</span>
        </div>
      </div>
      <div className="prizeWrapper oneGameTotalPrizeItem">
        <div className="prizeText">{translate('totalPrize')}</div>
        <div className="prizeValue">
          {`${ethers.utils.formatEther(totalPrize)} `}
          <span className="prizeUnit">{coinData.name}</span>
        </div>
      </div>
      <div className="prizeWrapper oneGameTotalPrizeItem">
        <div className="prizeText">{translate('winScore')}</div>
        <div className="prizeValue">{winScore}</div>
      </div>
    </div>
  );
};

export default RPSGameTotalPrize;
