import { useHistory } from 'react-router';
import bitcolympicsIcon from '../../shared/constants/icons/bitcolympics_icon.png';

const NavbarHomeButton = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  return (
    <button type="button" className="navbarHomeButton" onClick={handleClick}>
      <img
        src={bitcolympicsIcon}
        alt="bitcolympicsIcon"
        className="navbarHomeIcon"
      />
    </button>
  );
};

export default NavbarHomeButton;
