import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotification } from '../../../redux/notificationSlice';
import { IReduxState } from '../../../shared/interfaces';

const CopyNotification = () => {
  // redux
  const { isVisible, message } = useSelector(
    (state: IReduxState) => state.notification
  );
  const dispatch = useDispatch();

  // effects
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        dispatch(clearNotification());
      }, 3000);
    }
  }, [isVisible]);

  // variants
  const slideVariants = {
    enter: {
      y: -50,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1
    },
    exit: {
      y: -50,
      opacity: 0
    }
  };

  return (
    <motion.div
      key="addressCopyNotificationContainer"
      variants={slideVariants}
      initial="enter"
      animate={isVisible ? 'visible' : 'exit'}
      exit="exit"
      transition={{
        duration: 0.3,
        ease: 'easeInOut'
      }}
      className="addressCopyNotificationContainer"
    >
      <span>{message}</span>
    </motion.div>
  );
};

export default CopyNotification;
