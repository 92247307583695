import { motion } from 'framer-motion';
import translate from '../../shared/functions/translate';

const HomepageInfoButtons = () => {
  // functions
  const onGetStartedClick = () => {
    window.open('https://docs.bitcolympics.com', '_blank');
  };
  const onWhitepaperClick = () => {
    window.open('https://docs.bitcolympics.com', '_blank');
  };

  // motion
  const info = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 2.3,
        duration: 1
      }
    }
  };
  return (
    <motion.div
      key="homepageInfoButtonsWrapper"
      variants={info}
      initial="hidden"
      animate="visible"
      className="homepageInfoButtonsWrapper"
    >
      <button type="button" onClick={onGetStartedClick}>
        {translate('gettingStarted')}
      </button>
      <button type="button" onClick={onWhitepaperClick}>
        {translate('whitepaper')}
      </button>
    </motion.div>
  );
};

export default HomepageInfoButtons;
