import addresses from '../constants/addresses.json';
import supportedChains from '../constants/supportedChains';

const getAddress = (id: number | undefined, type: string) => {
  let address: { [key: string]: string } = {};

  if (!id || !supportedChains.includes(id)) {
    address = addresses['97'];
  } else if (id === 43113) {
    address = addresses['43113'];
  } else {
    address = addresses['97'];
  }

  return address[type.toUpperCase()];
};

export default getAddress;
