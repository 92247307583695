import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from '../../shared/interfaces';
import { changeLanguage } from '../../redux/languageSlice';
import ukFlag from '../../shared/constants/icons/flags/uk_flag.png';
import turkeyFlag from '../../shared/constants/icons/flags/turkey_flag.png';
import chinaFlag from '../../shared/constants/icons/flags/china_flag.png';
import germanFlag from '../../shared/constants/icons/flags/germany_flag.png';

interface IIcons {
  [key: string]: string;
}

const NavbarLanguageDropdown = () => {
  // states
  const [expanded, setExpanded] = useState(false);

  // redux
  const languageData = useSelector((state: IReduxState) => state.language.name);
  const dispatch = useDispatch();

  const icons: IIcons = {
    en: ukFlag,
    zh: chinaFlag,
    de: germanFlag,
    tr: turkeyFlag
  };

  return (
    <div className="navbarLanguageDropdownWrapper">
      <motion.div
        className="navbarLanguageDropdownContainer"
        key="navbarLanguageDropdownContainer"
        initial="collapsed"
        animate={expanded ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: `112px`
          },
          collapsed: {
            height: '28px'
          }
        }}
        transition={{ duration: 0.6, ease: 'easeInOut' }}
      >
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`navbarLanguageDropdown-${languageData}`}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="navbarLanguageDropdown"
            onClick={() => setExpanded(!expanded)}
          >
            <img
              src={icons[languageData]}
              alt={`languageIcon-${languageData}`}
              className="navbarLanguageDropdownIcon"
            />
          </motion.div>
        </AnimatePresence>
        {expanded && (
          <motion.div
            className="navbarLanguageDropdownList"
            key="navbarLanguageDropdownList"
            variants={{
              open: {
                transition: {
                  delayChildren: 0.1,
                  staggerChildren: 0.1
                }
              }
            }}
            initial="initial"
            animate="open"
            exit="exit"
          >
            {Object.keys(icons).map(
              (key: string) =>
                key !== languageData && (
                  <motion.div
                    variants={{
                      initial: {
                        opacity: 0,
                        x: 10
                      },
                      open: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.3,
                          ease: 'easeInOut'
                        }
                      },
                      exit: {
                        opacity: 0
                      }
                    }}
                    key={`navbarLanguageDropdownIcon-${key}`}
                    className="navbarLanguageDropdownIconWrapper"
                    onClick={() => {
                      dispatch(changeLanguage(key));
                      setExpanded(false);
                    }}
                  >
                    <img
                      src={icons[key]}
                      alt={`languageIcon-${languageData}`}
                      className="navbarLanguageDropdownIcon"
                    />
                  </motion.div>
                )
            )}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default NavbarLanguageDropdown;
