import { Bytes, SignatureLike } from '@ethersproject/bytes';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useContract } from '../../../contexts/ContractProvider';
import { changeData } from '../../../redux/oneDataSlice';
import translate from '../../../shared/functions/translate';
import { IJSONObject, IReduxState } from '../../../shared/interfaces';
import LoadingDots from '../../utils/graphics/LoadingDots';
import LoadingSpinner from '../../utils/graphics/LoadingSpinner';

interface IProps {
  gameState: number;
  players: string[];
  scores: number[];
  isNextAvailable: boolean;
  isLoading: boolean;
  holderIndex: number;
  round: number;
  isTxSendable: boolean;
  isSigned: boolean[];
}

const OnEGameUIButton: React.FC<IProps> = ({
  gameState,
  players,
  scores,
  isNextAvailable,
  isLoading,
  holderIndex,
  round,
  isTxSendable,
  isSigned
}: IProps) => {
  // contract definition
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  // contexts
  const { oneContract } = useContract();
  const { account, library } = useWeb3React<Web3Provider>();

  // states
  const [holdValue, setHoldValue] = useState<number>(1);
  const [joinButtonDisabled, setJoinButtonDisabled] = useState<boolean>(false);
  const [startButtonDisabled, setStartButtonDisabled] =
    useState<boolean>(false);
  const [claimButtonDisabled, setClaimButtonDisabled] =
    useState<boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
  const [playButtonDisabled, setPlayButtonDisabled] = useState<boolean>(false);
  const [revealButtonDisabled, setRevealButtonDisabled] =
    useState<boolean>(false);
  const [prevRound, setPrevRound] = useState<number>(round);
  const [prevGameState, setPrevGameState] = useState<number>(gameState);

  // redux
  const oneGameData = useSelector((state: IReduxState) => state.oneData.data);
  const dispatch = useDispatch();

  // functions
  const handleJoin = async () => {
    if (!players.includes(ethers.constants.AddressZero)) {
      window.alert('There is no empty space in the room.');
    }
    try {
      setJoinButtonDisabled(true);
      const receipt = await oneContract?.join(path);
      await receipt.wait();
    } catch (error: any) {
      setJoinButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  const handleStart = async () => {
    try {
      setStartButtonDisabled(true);
      dispatch(changeData({}));
      const receipt = await oneContract?.start(path);
      await receipt.wait();
    } catch (error: any) {
      setStartButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  const handleSign = async (message: Bytes) => {
    const signer = library?.getSigner();
    const flatSig = await signer?.signMessage(message);
    const sig = ethers.utils.splitSignature(flatSig as SignatureLike);
    return sig;
  };

  const handleHold = async () => {
    if (!oneContract) {
      return;
    }
    try {
      setPlayButtonDisabled(true);
      const currentTime = Math.round(Date.now() / 1000);
      const message = currentTime * 10 + holdValue;
      const hash = ethers.utils.solidityKeccak256(['uint256'], [message]);
      const bytesDataHash = ethers.utils.arrayify(hash);
      const { v, r, s } = await handleSign(bytesDataHash);
      dispatch(changeData({ message, sig: { v, r, s } }));
      const storedHash = ethers.utils.solidityKeccak256(
        ['uint8', 'bytes32', 'bytes32'],
        [v, r, s]
      );
      const estimatedGas = await oneContract.estimateGas.setSignature(
        path,
        storedHash
      );
      const receipt = await oneContract.setSignature(path, storedHash, {
        gasLimit: estimatedGas.mul(ethers.BigNumber.from(2))
      });
      await receipt.wait();
    } catch (error: any) {
      setPlayButtonDisabled(false);
      window.alert(error.message);
    }
  };

  const handleGuess = async (value: number) => {
    try {
      setPlayButtonDisabled(true);
      const currentTime = Math.round(Date.now() / 1000);
      const message = currentTime * 10 + value;
      const hash = ethers.utils.solidityKeccak256(['uint256'], [message]);
      const bytesDataHash = ethers.utils.arrayify(hash);
      const { v, r, s } = await handleSign(bytesDataHash);
      dispatch(changeData({ message, sig: { v, r, s } }));
      const storedHash = ethers.utils.solidityKeccak256(
        ['uint8', 'bytes32', 'bytes32'],
        [v, r, s]
      );
      const receipt = await oneContract?.setSignature(path, storedHash);
      await receipt.wait();
    } catch (error: any) {
      setPlayButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  const handleReveal = async () => {
    if (!oneContract) {
      return;
    }
    try {
      setRevealButtonDisabled(true);
      const estimatedGas = await oneContract.estimateGas.setTransaction(
        path,
        oneGameData?.message,
        oneGameData?.sig?.v,
        oneGameData?.sig?.r,
        oneGameData?.sig?.s
      );
      const receipt = await oneContract.setTransaction(
        path,
        oneGameData?.message,
        oneGameData?.sig?.v,
        oneGameData?.sig?.r,
        oneGameData?.sig?.s,
        {
          gasLimit: estimatedGas.mul(ethers.BigNumber.from(2))
        }
      );
      await receipt.wait();
    } catch (error: any) {
      setRevealButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  // light cleanup
  const cleanup = () => {
    setJoinButtonDisabled(false);
    setStartButtonDisabled(false);
    setClaimButtonDisabled(false);
    setNextButtonDisabled(false);
    setPlayButtonDisabled(false);
    setRevealButtonDisabled(false);
  };

  const handleNext = async () => {
    try {
      setNextButtonDisabled(true);
      const receipt = await oneContract?.nextRound(path);
      await receipt.wait();
    } catch (error: any) {
      setNextButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  // reset messages on round and game state change
  useEffect(() => {
    if (round !== prevRound && prevRound !== 0) {
      dispatch(changeData({}));
    }
    setPrevRound(round);
  }, [round]);

  useEffect(() => {
    if (gameState !== prevGameState && prevGameState !== 0) {
      dispatch(changeData({}));
    }
    setPrevGameState(gameState);
  }, [gameState]);

  // light cleanup on game state change
  useEffect(() => {
    cleanup();
  }, [gameState, account, round]);

  // fixed hooks translation
  const translatedText: IJSONObject = {
    start: translate('start'),
    join: translate('join'),
    odd: translate('odd'),
    even: translate('even'),
    guess: translate('guess'),
    hold: translate('hold'),
    guessed: translate('guessed'),
    reveal: translate('reveal'),
    next: translate('next')
  };

  // button render
  let oneButton;
  if (gameState === 1) {
    if (account && players.includes(account)) {
      oneButton = (
        <button
          type="button"
          className="buttonOutlined buttonGolden"
          onClick={handleStart}
          disabled={
            players.filter((player) => player !== ethers.constants.AddressZero)
              .length < 2 || startButtonDisabled
          }
        >
          {startButtonDisabled ? <LoadingDots /> : translatedText.start}
        </button>
      );
    } else {
      oneButton = (
        <button
          type="button"
          className="buttonOutlined buttonGreen"
          onClick={handleJoin}
          disabled={
            !account ||
            !players.includes(ethers.constants.AddressZero) ||
            joinButtonDisabled
          }
        >
          {joinButtonDisabled ? <LoadingDots /> : translatedText.join}
        </button>
      );
    }
  } else if (gameState === 2 && !isNextAvailable) {
    if (!account || (!players.includes(account) && !isTxSendable)) {
      oneButton = (
        <div className="oneGameUIButtonWrapper">
          <button
            type="button"
            className="buttonOutlined buttonBlue"
            onClick={() => handleGuess(1)}
            disabled
          >
            {playButtonDisabled ? <LoadingDots /> : translatedText.odd}
          </button>
          <button
            type="button"
            className="buttonOutlined buttonBlue"
            onClick={() => handleGuess(0)}
            disabled
          >
            {playButtonDisabled ? <LoadingDots /> : translatedText.even}
          </button>
          {!!oneGameData && !!oneGameData.message && (
            <div className="prizeWrapper">
              <div className="prizeText">GUESS</div>
              <div className="prizeValue">
                {oneGameData.message % 10 === 0
                  ? translatedText.even
                  : translatedText.odd}
              </div>
            </div>
          )}
        </div>
      );
    } else if (
      holderIndex === players.indexOf(account) &&
      !isTxSendable &&
      !isSigned[players.indexOf(account)]
    ) {
      oneButton = (
        <div className="oneGameUIButtonWrapper">
          <div className="oneGameUITopContainer">
            <button
              className="oneGameUIHoldValueButton"
              type="button"
              disabled={holdValue <= 1}
              onClick={() => holdValue > 1 && setHoldValue(holdValue - 1)}
            >
              -
            </button>
            <span className="oneGameUIValue">{holdValue}</span>
            <button
              className="oneGameUIHoldValueButton"
              type="button"
              disabled={holdValue >= Math.min(8, scores[holderIndex])}
              onClick={() =>
                holdValue < Math.min(8, scores[holderIndex]) &&
                setHoldValue(holdValue + 1)
              }
            >
              +
            </button>
          </div>
          <div className="oneGameUICenterContainer">
            <button
              type="button"
              className="buttonOutlined buttonBlue"
              onClick={handleHold}
              disabled={playButtonDisabled}
            >
              {playButtonDisabled ? <LoadingDots /> : translatedText.hold}
            </button>
          </div>
          {!!oneGameData && !!oneGameData.message && (
            <div className="oneGameUIBottomContainer">
              <div className="prizeWrapper">
                <div className="prizeText">{translatedText.hold}</div>
                <div className="prizeValue">{oneGameData.message % 10}</div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (!isTxSendable && !isSigned[players.indexOf(account)]) {
      oneButton = (
        <div className="oneGameUIButtonWrapper">
          <div className="oneGameUICenterContainer">
            <button
              type="button"
              className="buttonOutlined buttonBlue"
              onClick={() => handleGuess(1)}
              disabled={playButtonDisabled}
            >
              {playButtonDisabled ? <LoadingDots /> : translatedText.odd}
            </button>
            <button
              type="button"
              className="buttonOutlined buttonBlue"
              onClick={() => handleGuess(0)}
              disabled={playButtonDisabled}
            >
              {playButtonDisabled ? <LoadingDots /> : translatedText.even}
            </button>
          </div>
          {!!oneGameData && !!oneGameData.message && (
            <div className="oneGameUIBottomContainer">
              <div className="prizeWrapper">
                <div className="prizeText">GUESSED</div>
                <div className="prizeValue">
                  {oneGameData.message % 10 === 0
                    ? translatedText.even
                    : translatedText.odd}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      oneButton = (
        <div className="oneGameUIButtonWrapper">
          <div className="oneGameUICenterContainer">
            <button
              type="button"
              className="buttonOutlined buttonBlue"
              onClick={handleReveal}
              disabled={revealButtonDisabled || !oneGameData || !isTxSendable}
            >
              {revealButtonDisabled ? <LoadingDots /> : translatedText.reveal}
            </button>
          </div>
          {!!oneGameData &&
            !!oneGameData.message &&
            (holderIndex === players.indexOf(account) ? (
              <div className="oneGameUIBottomContainer">
                <div className="prizeWrapper">
                  <div className="prizeText">{translatedText.hold}</div>
                  <div className="prizeValue">{oneGameData.message % 10}</div>
                </div>
              </div>
            ) : (
              <div className="oneGameUIBottomContainer">
                <div className="prizeWrapper">
                  <div className="prizeText">{translatedText.guess}</div>
                  <div className="prizeValue">
                    {oneGameData.message % 10 === 0
                      ? translatedText.even
                      : translatedText.odd}
                  </div>
                </div>
              </div>
            ))}
        </div>
      );
    }
  } else if (gameState === 2) {
    oneButton = (
      <>
        <button
          type="button"
          className="buttonOutlined buttonBlue"
          onClick={handleNext}
          disabled={!account || claimButtonDisabled || nextButtonDisabled}
        >
          {nextButtonDisabled ? <LoadingDots /> : translatedText.next}
        </button>
      </>
    );
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoading ? (
        <motion.div
          key="oneGameRollButtonLoader"
          variants={{
            collapsed: { opacity: 0 },
            open: {
              opacity: 1
            }
          }}
          initial="collapsed"
          animate="open"
          exit="collapsed"
        >
          <LoadingSpinner />
        </motion.div>
      ) : (
        <motion.div
          key="oneGameRollButtonWrapper"
          variants={{
            collapsed: { opacity: 0 },
            open: {
              opacity: 1
            }
          }}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          className="oneGameDiceValWrapper"
        >
          {oneButton}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OnEGameUIButton;
