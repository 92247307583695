import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import translate from '../../../shared/functions/translate';
import { IJSONObject } from '../../../shared/interfaces';

interface IProps {
  positionIndex: number;
  totalPointsInput: number;
  holderIndex: number;
  gameState: number;
  active?: boolean;
}

const defaultProps = {
  active: true
};

const OnEGameTotalPointsRecent: React.FC<IProps> = ({
  positionIndex,
  totalPointsInput,
  holderIndex,
  gameState,
  active
}: IProps) => {
  // states
  const [prevPoints, setPrevPoints] = useState<number>(totalPointsInput);
  const [output, setOutput] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(true);
  const [large, setLarge] = useState<boolean>(true);

  // fixed hooks translation
  const translatedText: IJSONObject = {
    held: translate('held'),
    guessed: translate('guessed'),
    odd: translate('odd'),
    even: translate('even')
  };

  // effects
  useEffect(() => {
    if (totalPointsInput !== prevPoints) {
      const diff = totalPointsInput - prevPoints;
      if (positionIndex === holderIndex) {
        setTitle(translatedText.held);
        setLarge(true);
        setOutput(`${Math.abs(diff)}`);
        if (diff > 0) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      } else {
        setTitle(translatedText.guessed);
        setLarge(false);
        if (diff > 0) {
          setSuccess(true);
          if (diff % 2) {
            setOutput(translatedText.odd);
          } else {
            setOutput(translatedText.even);
          }
        } else {
          setSuccess(false);
          if (diff % 2) {
            setOutput(translatedText.even);
          } else {
            setOutput(translatedText.odd);
          }
        }
      }
    }
    setPrevPoints(totalPointsInput);
  }, [totalPointsInput, holderIndex]);

  // cleanup
  const cleanup = () => {
    setPrevPoints(totalPointsInput);
    setOutput('');
    setTitle('');
    setSuccess(true);
  };

  useEffect(() => {
    if (totalPointsInput === 10) {
      cleanup();
    }
  }, [gameState]);

  // variants
  const animateVariants = {
    enter: {
      opacity: 0
    },
    visible: {
      opacity: 1
    },
    exit: {
      opacity: 0
    }
  };

  return (
    <div className="oneGameTotalPointsRecentWrapper">
      <span className="oneGameTotalPointsRecentHeader">{title}</span>
      <AnimatePresence key="oneGameTotalPointsText" exitBeforeEnter>
        <motion.div
          key={`oneGameTotalPointsRecentValue-${output}`}
          variants={animateVariants}
          initial="enter"
          animate="visible"
          exit="exit"
          transition={{
            duration: 0.3,
            ease: 'easeInOut'
          }}
          className={`${
            large
              ? 'oneGameTotalPointsValueLarge'
              : 'oneGameTotalPointsValueNormal'
          } 
          ${
            active
              ? ' oneGameTotalPointsValueActive'
              : ' oneGameTotalPointsValueDisabled'
          }`}
          style={success ? { color: '#5e8f46' } : { color: '#b1423a' }}
        >
          {output}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

OnEGameTotalPointsRecent.defaultProps = defaultProps;
export default OnEGameTotalPointsRecent;
