interface IProps {
  positionIndex?: number;
  color?: string;
}

const defaultProps = {
  positionIndex: 0,
  color: '#f8f8f8'
};

const RPSGameRockIcon: React.FC<IProps> = ({
  positionIndex,
  color
}: IProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-49 141 512 512"
  >
    {positionIndex === 0 ? (
      <path
        fill={color}
        stroke={color}
        d="M383.9,549.9c33.9-22.4,42.3-66.1,23.6-98.8c31.5-29.5,31.1-79.1,0-108.3c27-47.2-5.1-108.7-60.8-110.7
      c10.8-46.2-23.8-91-71.2-91l-57,0c-32.7,0-64,14.3-85.6,39.2l-85.1,97.7c-3.7,4.3-5.6,3.1-32.9,3.1c-17.7,0-32,14.3-32,32l0,252
      c0,17.7,14.3,32,32,32c23.5,0,30.7-1,42.4,4l99.7,42.8c14.2,6.1,29.2,9.2,44.6,9.2H316C368.9,653,404.7,598.7,383.9,549.9z
       M201.6,605c-8.8,0-17.5-1.8-25.6-5.3l-99.7-42.8c-12.2-5.2-25-7.9-38.3-7.9H31l0-220h10.3c16.4,0,32-7.1,42.8-19.6l85.1-97.7
      c12.6-14.4,30.6-22.7,49.4-22.7l57,0c33.2,0,33.5,52-0.7,52l-41.2,0c-4.6,0-9,2-12.1,5.5l-6.1,7c-5.8,6.7-5.1,16.8,1.6,22.6
      c2.9,2.5,6.6,3.9,10.5,3.9h116.6c33.1,0,33.7,52-0.7,52h-26.8c-8.8,0-16,7.2-16,16v7c0,8.8,7.2,16,16,16h41.1c33.1,0,33.7,52-0.7,52
      h-40.5c-8.8,0-16,7.2-16,16v7c0,8.8,7.2,16,16,16h27.4c33,0,33.8,52-0.7,52h-26.8c-8.8,0-16,7.2-16,16v7c0,8.8,7.2,16,16,16
      c33.1,0,33.6,52-0.7,52H201.6z"
      />
    ) : (
      <path
        fill={color}
        stroke={color}
        d="M98,653h114.4c15.4,0,30.4-3.1,44.6-9.2l99.7-42.8c11.6-5,18.9-4,42.4-4c17.7,0,32-14.3,32-32l0-252c0-17.7-14.3-32-32-32
	c-27.3,0-29.2,1.1-32.9-3.1L281,180.2c-21.7-24.9-52.9-39.2-85.6-39.2l-57,0c-47.4,0-82,44.8-71.2,91c-55.8,2-87.8,63.5-60.8,110.7
	c-31.1,29.2-31.4,78.9,0,108.3c-18.7,32.7-10.3,76.4,23.6,98.8C9.3,598.7,45.1,653,98,653z M98,605c-34.3,0-33.8-52-0.7-52
	c8.8,0,16-7.2,16-16v-7c0-8.8-7.2-16-16-16H70.5c-34.5,0-33.7-52-0.7-52h27.4c8.8,0,16-7.2,16-16v-7c0-8.8-7.2-16-16-16H56.8
	c-34.4,0-33.8-52-0.7-52h41.1c8.8,0,16-7.2,16-16v-7c0-8.8-7.2-16-16-16H70.5c-34.4,0-33.8-52-0.7-52h116.6c3.9,0,7.6-1.4,10.5-3.9
	c6.7-5.8,7.4-15.9,1.6-22.6l-6.1-7c-3-3.5-7.4-5.5-12.1-5.5l-41.2,0c-34.2,0-33.9-52-0.7-52l57,0c18.9,0,36.9,8.3,49.4,22.7
	l85.1,97.7c10.8,12.4,26.4,19.6,42.8,19.6H383l0,220h-7.1c-13.2,0-26.1,2.7-38.3,7.9L238,599.7c-8.1,3.5-16.8,5.3-25.6,5.3H98z"
      />
    )}
  </svg>
);

RPSGameRockIcon.defaultProps = defaultProps;
export default RPSGameRockIcon;
