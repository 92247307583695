import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGameData, IGameDataState } from '../shared/interfaces';

const initialState: IGameDataState = {};

const oneDataSlice = createSlice({
  name: 'oneData',
  initialState,
  reducers: {
    changeData: (state, action: PayloadAction<IGameData>) => {
      state.data = action.payload;
    }
  }
});

export const { changeData } = oneDataSlice.actions;
export default oneDataSlice.reducer;
