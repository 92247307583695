import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { changeCoin } from '../../redux/coinSlice';
import getCoinInfo from '../../shared/functions/getCoinInfo';
import translate from '../../shared/functions/translate';
import { ICoinState, IJSONObject } from '../../shared/interfaces';

const DBetChangeCoinModal = () => {
  const { chainId } = useWeb3React<Web3Provider>();
  const coins: ICoinState[] = getCoinInfo(chainId);
  const dispatch = useDispatch();

  const handleSwitchCoin = () => {
    dispatch(changeCoin(coins.filter((coin) => coin.name === 'TBNB')[0]));
  };

  // fixed hooks translation
  const translatedText: IJSONObject = {
    switchSupportedCoin: translate('switchSupportedCoin')
  };

  return (
    <div className="navbarSwitchChainModal">
      <span>{translatedText.switchSupportedCoin}</span>
      <button type="button" onClick={handleSwitchCoin}>
        $TBNB
      </button>
    </div>
  );
};

export default DBetChangeCoinModal;
