import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useSelector } from 'react-redux';
import { ethers } from 'ethers';
import { motion } from 'framer-motion';
import Button from '../utils/buttons/Button';
import arrowUpIcon from '../../shared/constants/icons/arrow_up_icon.png';
import DBetGameDiceDropdown from './DBetGameDiceDropdown';
import LoadingDots from '../utils/graphics/LoadingDots';
import { IJSONObject, IReduxState } from '../../shared/interfaces';
import translate from '../../shared/functions/translate';
import { useContract } from '../../contexts/ContractProvider';

interface IOnButtonClick {
  onClick: () => Promise<void> | undefined;
}

interface IProps {
  currentBlock: number;
  rollValue: number;
  gameBalance: number;
  isRolled: boolean;
}

const DBetGameUIButton: React.FC<IProps> = ({
  currentBlock,
  rollValue,
  gameBalance,
  isRolled
}: IProps) => {
  // get roll block
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  // contexts
  const { account } = useWeb3React<Web3Provider>();
  const { dbetContract } = useContract();

  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  // states
  const [buttonClass, setButtonClass] = useState<string>(
    'buttonOutlined buttonBlue'
  );
  const [buttonText, setButtonText] = useState<string>('BET');
  const [onButtonClick, setOnButtonClick] = useState<IOnButtonClick>();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [selectedDice, setSelectedDice] = useState<number>(1);
  const [betInput, setBetInput] = useState<number>(0.001);
  const [betFormVisible, setBetFormVisible] = useState<boolean>(false);
  const [isAwaitingReceipt, setIsAwaitingReceipt] = useState<boolean>(false);

  // scroll function
  const scrollRef = useRef<HTMLDivElement>(null);
  const executeScroll = () =>
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });

  // functions
  const onBet = async () => {
    try {
      if (account) {
        if (!betFormVisible) {
          setBetFormVisible(true);
          executeScroll();
        } else if (betInput >= 0.001) {
          setIsAwaitingReceipt(true);
          const receipt = await dbetContract?.bet(
            selectedDice.toString(),
            ethers.utils.parseEther(betInput.toString()),
            path,
            coinData.id
          );
          await receipt.wait();
          setIsAwaitingReceipt(false);
        } else {
          throw new Error(
            'Bet must be greater than or equal to the minimum bet.'
          );
        }
      } else {
        throw new Error('No account.');
      }
    } catch (error: any) {
      setIsAwaitingReceipt(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  const onCollect = async () => {
    try {
      if (account) {
        setIsAwaitingReceipt(true);
        const receipt = await dbetContract?.claim(path, coinData.id);
        await receipt.wait();
        setIsAwaitingReceipt(false);
      } else {
        throw new Error('No account.');
      }
    } catch (error: any) {
      setIsAwaitingReceipt(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  const onRoll = async () => {
    try {
      if (account) {
        setIsAwaitingReceipt(true);
        const receipt = await dbetContract?.roll(path, coinData.id);
        await receipt.wait();
        setIsAwaitingReceipt(false);
      } else {
        throw new Error('No account.');
      }
    } catch (error: any) {
      setIsAwaitingReceipt(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  // fixed hooks translation
  const translatedText: IJSONObject = {
    bet: translate('bet'),
    collect: translate('collect'),
    placeBet: translate('placeBet'),
    betValue: translate('betValue'),
    roll: translate('roll')
  };

  // set button state
  const buttonUpdater = () => {
    if (!account || !Number(path)) {
      setBetFormVisible(false);
      setButtonClass('buttonOutlined buttonBlue');
      setButtonText(translatedText.bet);
      setButtonDisabled(true);
    } else if (Number(path) - currentBlock > 0) {
      setButtonClass('buttonOutlined buttonBlue');
      setButtonText(translatedText.bet);
      setOnButtonClick({ onClick: onBet });
      setButtonDisabled(false);
    } else if (rollValue === 0 && gameBalance !== 0) {
      setBetFormVisible(false);
      setButtonClass('buttonOutlined buttonGreen');
      setButtonText(translatedText.roll);
      setOnButtonClick({ onClick: onRoll });
      setButtonDisabled(isRolled);
    } else {
      setBetFormVisible(false);
      setButtonClass('buttonOutlined buttonGreen');
      setButtonText(translatedText.collect);
      setOnButtonClick({ onClick: onCollect });
      setButtonDisabled(isAwaitingReceipt || gameBalance === 0);
    }
  };

  useEffect(() => {
    buttonUpdater();
  }, [account, path, currentBlock, betFormVisible, selectedDice, betInput]);

  return (
    <>
      <div className="dbetGameAccordionWrapper" ref={scrollRef}>
        <motion.div
          key="dbetGameBetForm"
          initial="collapsed"
          animate={betFormVisible ? 'open' : 'closed'}
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 }
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <div className="dbetGameAccordionTitle">
            {translatedText.placeBet}
          </div>
          <button
            type="button"
            onClick={() => setBetFormVisible(false)}
            className="dbetGameCollapseButton"
            style={betFormVisible ? {} : { cursor: 'default' }}
          >
            <img src={arrowUpIcon} alt="arrowUpIcon" />
          </button>
          <div className="dbetGameDiceDropdownWrapper">
            <DBetGameDiceDropdown
              selectedDice={selectedDice}
              setSelectedDice={setSelectedDice}
            />
          </div>
          <div className="dbetGameAccordionContainer">
            <div className="dbetGameAccordion">
              <span
                className="dbetGameAccordionLabel"
                style={betFormVisible ? {} : { cursor: 'default' }}
              >
                {translatedText.betValue}
              </span>
              <div className="dbetGameAccordionInputWrapper">
                <input
                  type="number"
                  step="any"
                  min={0.001}
                  placeholder="0.001"
                  className="dbetGameAccordionInput"
                  style={betFormVisible ? {} : { cursor: 'default' }}
                  onChange={(e) => setBetInput(parseFloat(e.target.value))}
                />
                <span
                  className="dbetGameAccordionUnit"
                  style={betFormVisible ? {} : { cursor: 'default' }}
                >
                  {coinData.name}
                </span>
              </div>
            </div>
          </div>
        </motion.div>
        <div className="dbetGameUIButtonContainer">
          <Button
            onClick={onButtonClick}
            buttonClass={buttonClass}
            buttonText={isAwaitingReceipt ? <LoadingDots /> : buttonText}
            buttonDisabled={isAwaitingReceipt ? true : buttonDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default DBetGameUIButton;
