import { ethers } from 'ethers';

const supportedChains = [97];

export const supportedChainInfo = {
  '97': {
    chainId: ethers.utils.hexlify(97),
    chainName: 'Smart Chain - Testnet',
    nativeCurrency: {
      name: 'Binance Smart Chain Token',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com']
  }
};

export default supportedChains;
