import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useContract } from '../../../contexts/ContractProvider';
import getAddress from '../../../shared/functions/getAddress';
import getChainInfo from '../../../shared/functions/getChainInfo';
import translate from '../../../shared/functions/translate';
import { IJSONObject } from '../../../shared/interfaces';

interface IOneEventLog {
  time: number;
  text: string;
  logType: number;
}

interface IOneEventDict {
  [key: number]: string;
}

interface IOneEvent {
  blockNumber: number;
  gameId: string;
  player: string;
  isHolder: number;
  value: number;
  log: number;
}

const shorter = (str: string) =>
  str?.length > 8 ? `${str.slice(0, 5)}...${str.slice(-4)}` : str;

const OnEGameEventsLog = () => {
  // states
  const [oneEventsLog, setOneEventsLog] = useState<IOneEventLog[]>([]);
  const isSubscribed = useRef<boolean>(false); // memory leak fix

  const location = useLocation();
  const path = location.pathname.split('/')[2];

  // context and provider
  const { chainId } = useWeb3React<Web3Provider>();
  const { oneContract } = useContract();

  // fixed hooks translation
  const translatedText: IJSONObject = {
    oneEvents1: translate('oneEvents1'),
    oneEvents2: translate('oneEvents2'),
    oneEvents3: translate('oneEvents3'),
    oneEvents4: translate('oneEvents4'),
    oneEvents5: translate('oneEvents5'),
    oneEvents6: translate('oneEvents6'),
    oneEvents7: translate('oneEvents7'),
    oneEvents8: translate('oneEvents8'),
    oneEvents9: translate('oneEvents9'),
    oneEvents10: translate('oneEvents10'),
    odd: translate('odd'),
    even: translate('even')
  };

  // log query listener
  const listener = async (currentBlock: number) => {
    if (!oneContract) {
      return;
    }
    const event = oneContract.filters.GameLog();
    const allEvents = await oneContract.queryFilter(
      event,
      currentBlock - Number(getChainInfo(chainId, 'event-range')),
      currentBlock
    );

    const events = allEvents.map((el: ethers.Event) => ({
      blockNumber: el.blockNumber,
      gameId: el.args && el.args[0].toString(),
      player: el.args && el.args[1],
      isHolder: el.args && el.args[2],
      value: el.args && el.args[3].toNumber(),
      log: el.args && el.args[4].toNumber()
    }));

    const dict: IOneEventDict = {
      5: translatedText.oneEvents1,
      6: translatedText.oneEvents2,
      7: translatedText.oneEvents3,
      8: translatedText.oneEvents4,
      9: translatedText.oneEvents5,
      10: translatedText.oneEvents6,
      11: translatedText.oneEvents9,
      12: translatedText.oneEvents10
    };

    const parsedEvents = events
      .filter((el: IOneEvent) => el.gameId === path)
      .map((el: IOneEvent) => {
        const actor = el.player;
        const logType = el.log;
        let text = '';
        if (logType === 10) {
          const result = el.value === 0 ? 'EVEN' : 'ODD';
          text = `${
            actor === ethers.constants.AddressZero ? '' : shorter(actor)
          } ${dict[logType]} ${
            el.isHolder ? translatedText.oneEvents7 : translatedText.oneEvents8
          } ${el.isHolder ? el.value : result}.`;
        } else if (logType === 11) {
          text = `${dict[logType]}`;
        } else {
          text = `${
            actor === ethers.constants.AddressZero ? '' : shorter(actor)
          } ${dict[logType]}`;
        }
        return { time: el.blockNumber, text, logType };
      });
    if (isSubscribed.current) {
      setOneEventsLog(parsedEvents);
    }
  };

  // lifecycle hooks
  useEffect(() => {
    isSubscribed.current = true;
    const oneEventsLogJsonRpcProvider = new ethers.providers.JsonRpcProvider(
      getAddress(chainId, 'provider')
    );
    if (oneContract) {
      oneEventsLogJsonRpcProvider.on('block', (blockNumber: number) => {
        if (isSubscribed.current) listener(blockNumber);
      });
    }
    return () => {
      isSubscribed.current = false;
      oneEventsLogJsonRpcProvider.off('block');
    };
  }, [oneContract]);

  return oneEventsLog.length === 0 ? (
    <div className="eventsLogTextDisabled">No events received yet...</div>
  ) : (
    <div className="eventsLogContainer">
      <ul className="eventsLogList">
        {oneEventsLog.map((event, index) => (
          <li key={index}>
            <span
              className={
                event.logType === 12 ? 'eventsLogAlternate' : 'eventsLogTime'
              }
            >{`#${event.time}: `}</span>
            <span
              className={
                event.logType === 12 ? 'eventsLogAlternate' : 'eventsLogText'
              }
            >
              {event.text}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OnEGameEventsLog;
