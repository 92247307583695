import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import {
  AnimatePresence,
  motion,
  PanInfo,
  useAnimation,
  useMotionValue,
  useTransform
} from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useContract } from '../../contexts/ContractProvider';
import accountIcon from '../../shared/constants/icons/account_icon.png';
import { CurrencyType } from '../../shared/enums';
import getAddress from '../../shared/functions/getAddress';
import translate from '../../shared/functions/translate';
import { IJSONObject, IReduxState } from '../../shared/interfaces';
import AddressCopyButton from '../utils/buttons/AddressCopyButton';
import NavbarBottomSheetWalletDepositIcon from './icons/NavbarBottomSheetWalletDepositIcon';
import NavbarBottomSheetWalletDonateIcon from './icons/NavbarBottomSheetWalletDonateIcon';
import NavbarBottomSheetWalletWithdrawIcon from './icons/NavbarBottomSheetWalletWithdrawIcon';

enum WalletAction {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Donate = 'donate'
}

const NavbarBottomSheetWallet = () => {
  // contexts
  const { chainId, account } = useWeb3React<Web3Provider>();
  const { walletContract, erc20Contract, erc1155Contract } = useContract();

  // states
  const [inputValue, setInputValue] = useState<number>();
  const [walletActionType, setWalletActionType] = useState<WalletAction>(
    WalletAction.Deposit
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [depositButtonColor, setDepositButtonColor] =
    useState<string>('#1ab2ff');
  const [withdrawButtonColor, setWithdrawButtonColor] =
    useState<string>('#484848');
  const [donateButtonColor, setDonateButtonColor] = useState<string>('#484848');

  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  // update viewport width on resize
  const [vw, setVw] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => setVw(window.innerWidth));
    return () =>
      window.removeEventListener('resize', () => setVw(window.innerWidth));
  }, []);

  // animations
  const x = useMotionValue(0);
  const xInput = [20, 230];
  const color = useTransform(x, xInput, ['rgb(72, 72, 72)', '#1ab2ff']);
  const tickPath = useTransform(x, xInput, [0, 1]);
  const swipePath = useTransform(x, [20, 0], [0, 1]);

  const controls = useAnimation();

  // wallet actions
  const walletContractAddress = getAddress(chainId, 'wallet');

  const handleWalletAction = async () => {
    if (!inputValue) {
      return;
    }
    try {
      setIsLoading(true);
      if (
        walletActionType === WalletAction.Deposit ||
        walletActionType === WalletAction.Donate
      ) {
        if (coinData.type === CurrencyType.BASE) {
          const bigInputValue = ethers.utils.parseUnits(
            inputValue.toString(),
            coinData.decimal
          );
          if (walletActionType === WalletAction.Deposit) {
            const tx = await walletContract?.deposit(coinData.id, 0, {
              value: bigInputValue
            });
            await tx.wait();
          } else if (walletActionType === WalletAction.Donate) {
            const tx = await walletContract?.donate(coinData.id, 0, {
              value: bigInputValue
            });
            await tx.wait();
          }
        } else if (coinData.type === CurrencyType.ERC20) {
          const allowance = await erc20Contract?.allowance(
            account,
            walletContractAddress
          );
          const bigInputValue = ethers.utils.parseUnits(
            inputValue.toString(),
            coinData.decimal
          );
          if (allowance.lt(bigInputValue)) {
            const ercReceipt = await erc20Contract?.approve(
              walletContractAddress,
              ethers.constants.MaxUint256,
              {
                value: 0,
                gasLimit: 200000
              }
            );
            const awaitedErcReceipt = await ercReceipt.wait();
            if (!awaitedErcReceipt) {
              throw new Error('Failed to approve erc20 contract');
            }
          }
          if (walletActionType === WalletAction.Deposit) {
            const tx = await walletContract?.deposit(
              coinData.id,
              bigInputValue
            );
            await tx.wait();
          } else if (walletActionType === WalletAction.Donate) {
            const tx = await walletContract?.donate(coinData.id, bigInputValue);
            await tx.wait();
          }
        } else if (coinData.type === CurrencyType.ERC1155) {
          const isApproved = await erc1155Contract?.isApprovedForAll(
            account,
            walletContractAddress
          );
          if (!isApproved) {
            const tx = await erc1155Contract?.setApprovalForAll(
              walletContractAddress,
              true
            );
            await tx.wait();
          }
          if (walletActionType === WalletAction.Deposit) {
            const tx = await walletContract?.deposit(coinData.id, inputValue);
            await tx.wait();
          } else if (walletActionType === WalletAction.Donate) {
            const tx = await walletContract?.donate(coinData.id, inputValue);
            await tx.wait();
          }
        }
      } else if (walletActionType === WalletAction.Withdraw) {
        const bigInputValue = ethers.utils.parseUnits(
          inputValue.toString(),
          coinData.decimal
        );
        const tx = await walletContract?.withdraw(coinData.id, bigInputValue);
        await tx.wait();
      }
      setInputValue(undefined);
      setIsLoading(false);
      controls.start('left');
    } catch (error: any) {
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
      setIsLoading(false);
      controls.start('left');
    }
  };

  const onDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const isDragSuccess = info.velocity.x > 20 || info.point.x > vw / 2 + 20;
    if (isDragSuccess) {
      controls.start('right');
      handleWalletAction();
    } else {
      controls.start('left');
    }
  };

  // fixed hooks translation
  const translatedText: IJSONObject = {
    deposit: translate('deposit'),
    withdraw: translate('withdraw'),
    donate: translate('donate'),
    awaitingMetamask: translate('awaitingMetamask'),
    swipeRightConfirm: translate('swipeRightConfirm'),
    enterAmountToX: translate('enterAmountToX')
  };

  return (
    <div className="navbarBottomSheetWallet">
      <div className="navbarBottomSheetWalletHeader">
        <img src={accountIcon} alt="walletIcon" />
        <div>
          <AddressCopyButton
            address={account || ethers.constants.AddressZero}
            active
            large
            long
          />
        </div>
      </div>
      <div className="navbarBottomSheetWalletInputWrapper">
        <AnimatePresence exitBeforeEnter>
          <motion.span
            key={`navbarBottomSheetWalletInputTitle-${walletActionType}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            {translatedText[walletActionType]}
          </motion.span>
        </AnimatePresence>
        <div className="navbarBottomSheetWalletInputContainer">
          <input
            key={`navbarBottomSheetWalletInput-${Number(isLoading)}`}
            type="number"
            placeholder="0"
            value={inputValue}
            onChange={(e) => setInputValue(parseFloat(e.target.value))}
          />
          <span>{coinData.name}</span>
        </div>
      </div>
      <div className="navbarBottomSheetWalletActionButtonWrapper">
        <button
          type="button"
          className={
            walletActionType === WalletAction.Deposit
              ? 'navbarBottomSheetWalletActionButtonActive'
              : ''
          }
          onClick={() => {
            setWalletActionType(WalletAction.Deposit);
            setDepositButtonColor('#1ab2ff');
            setWithdrawButtonColor('#484848');
            setDonateButtonColor('#484848');
          }}
          onMouseOver={() => setDepositButtonColor('#66ccff')}
          onMouseOut={() =>
            walletActionType === WalletAction.Deposit
              ? setDepositButtonColor('#1ab2ff')
              : setDepositButtonColor('#484848')
          }
          onMouseDown={() => setDepositButtonColor('#66ccff')}
        >
          <>
            <div className="navbarBottomSheetWalletActionButtonIcon">
              <NavbarBottomSheetWalletDepositIcon color={depositButtonColor} />
            </div>
            <div className="navbarBottomSheetWalletActionButtonText">
              {translatedText[WalletAction.Deposit]}
            </div>
          </>
        </button>
        <button
          type="button"
          className={
            walletActionType === WalletAction.Withdraw
              ? 'navbarBottomSheetWalletActionButtonActive'
              : ''
          }
          onClick={() => {
            setWalletActionType(WalletAction.Withdraw);
            setWithdrawButtonColor('#1ab2ff');
            setDepositButtonColor('#484848');
            setDonateButtonColor('#484848');
          }}
          onMouseOver={() => setWithdrawButtonColor('#66ccff')}
          onMouseOut={() =>
            walletActionType === WalletAction.Withdraw
              ? setWithdrawButtonColor('#1ab2ff')
              : setWithdrawButtonColor('#484848')
          }
          onMouseDown={() => setWithdrawButtonColor('#66ccff')}
        >
          <>
            <div className="navbarBottomSheetWalletActionButtonIcon">
              <NavbarBottomSheetWalletWithdrawIcon
                color={withdrawButtonColor}
              />
            </div>
            <div className="navbarBottomSheetWalletActionButtonText">
              {translatedText[WalletAction.Withdraw]}
            </div>
          </>
        </button>
        <button
          type="button"
          className={
            walletActionType === WalletAction.Donate
              ? 'navbarBottomSheetWalletActionButtonActive'
              : ''
          }
          onClick={() => {
            setWalletActionType(WalletAction.Donate);
            setDonateButtonColor('#1ab2ff');
            setDepositButtonColor('#484848');
            setWithdrawButtonColor('#484848');
          }}
          onMouseOver={() => setDonateButtonColor('#66ccff')}
          onMouseOut={() =>
            walletActionType === WalletAction.Donate
              ? setDonateButtonColor('#1ab2ff')
              : setDonateButtonColor('#484848')
          }
          onMouseDown={() => setDonateButtonColor('#66ccff')}
        >
          <>
            <div className="navbarBottomSheetWalletActionButtonIcon">
              <NavbarBottomSheetWalletDonateIcon color={donateButtonColor} />
            </div>
            <div className="navbarBottomSheetWalletActionButtonText">
              {translatedText[WalletAction.Donate]}
            </div>
          </>
        </button>
      </div>
      <motion.div
        className={`navbarBottomSheetWalletSwiperContainer${
          inputValue && inputValue > 0
            ? ' navbarBottomSheetWalletSwiperContainerAnimated'
            : ''
        }`}
      >
        <motion.div
          className="navbarBottomSheetWalletSwiper"
          style={{ x }}
          drag="x"
          dragElastic={0.01}
          dragConstraints={{ left: 0, right: 230 }}
          onDragEnd={onDragEnd}
          dragListener={!!(inputValue && inputValue > 0)}
          initial="left"
          animate={controls}
          transition={{
            type: 'spring',
            damping: 40,
            stiffness: 400
          }}
          variants={{
            left: { x: 0 },
            right: { x: 230 }
          }}
        >
          <motion.svg
            className="navbarBottomSheetWalletSwiperIcon"
            viewBox="0 0 50 50"
            key="navbarBottomSheetWalletTickIcon"
          >
            <motion.path
              fill="none"
              strokeWidth="4"
              stroke={color}
              d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
              style={{ translateX: 5, translateY: 5 }}
            />
            <motion.path
              fill="none"
              strokeWidth="4"
              stroke={color}
              d="M15,16 L 22,25 L 15,34"
              strokeDasharray="0 1"
              style={{ pathLength: swipePath }}
              animate={inputValue && inputValue > 0 ? { x: 4 } : { x: 2 }}
              transition={{
                repeat: inputValue && inputValue > 0 ? Infinity : 1,
                repeatType:
                  inputValue && inputValue > 0 ? 'reverse' : undefined,
                duration: 1,
                ease: 'easeInOut'
              }}
            />
            <motion.path
              fill="none"
              strokeWidth="4"
              stroke={color}
              d="M25,16 L 32,25 L 25,34"
              strokeDasharray="0 1"
              style={{ pathLength: swipePath }}
              animate={inputValue && inputValue > 0 ? { x: 4 } : { x: 2 }}
              transition={{
                repeat: inputValue && inputValue > 0 ? Infinity : 1,
                repeatType:
                  inputValue && inputValue > 0 ? 'reverse' : undefined,
                duration: 1,
                ease: 'easeInOut'
              }}
            />

            <motion.path
              fill="none"
              strokeWidth="4"
              stroke={color}
              d="M14,26 L 22,33 L 35,16"
              strokeDasharray="0 1"
              style={{ pathLength: tickPath }}
            />
          </motion.svg>
        </motion.div>
        <AnimatePresence exitBeforeEnter>
          {isLoading ? (
            <motion.span
              key="navbarBottomSheetWalletSwiperSpanLoading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              {translatedText.awaitingMetamask}
            </motion.span>
          ) : inputValue && inputValue > 0 ? (
            <motion.span
              key="navbarBottomSheetWalletSwiperSpanConfirm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              {translatedText.swipeRightConfirm}
            </motion.span>
          ) : (
            <motion.span
              key={`navbarBottomSheetWalletSwiperSpanInput-${walletActionType}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              {translatedText.enterAmountToX.replace(
                '{0}',
                translatedText[walletActionType]
              )}
            </motion.span>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default NavbarBottomSheetWallet;
