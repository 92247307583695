import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../../contexts/ModalProvider';
import { loginFailure, loginStart, loginSuccess } from '../../redux/userSlice';
import supportedChains from '../../shared/constants/supportedChains';
import { IReduxState } from '../../shared/interfaces';
import NavbarHomeButton from './NavbarHomeButton';
import NavbarLanguageDropdown from './NavbarLanguageDropdown';
import NavbarMenuButton from './NavbarMenuButton';
import NavbarSwitchChainModal from './NavbarSwitchChainModal';
import NavbarWalletBalance from './NavbarWalletBalance';
import NavbarWalletButton from './NavbarWalletButton';
import injected from './NavbarWalletConnectors';

interface IProps {
  sheetToggle: () => void;
}

const Navbar: React.FC<IProps> = ({ sheetToggle }: IProps) => {
  const { active, activate, chainId } = useWeb3React<Web3Provider>();
  const [prevChainId, setPrevChainId] = useState<number | undefined>();

  // change chainId modal
  const { createModal } = useModal();

  // redux
  const { relogin } = useSelector((state: IReduxState) => state.user);
  const dispatch = useDispatch();

  async function connect() {
    try {
      dispatch(loginStart());
      await activate(injected);
      dispatch(loginSuccess());
    } catch (err) {
      dispatch(loginFailure());
      console.error(err);
    }
  }

  // re-login user on page refresh
  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized && relogin) {
        activate(injected);
      }
    });
  }, [relogin]);

  // chainId change effects
  useEffect(() => {
    // reload page on chainId change
    if (prevChainId && chainId && chainId !== prevChainId) {
      window.location.reload();
    }
    // check whether the chainId is supported
    if (chainId && !supportedChains.includes(chainId)) {
      createModal(<NavbarSwitchChainModal />);
    }
    setPrevChainId(chainId);
  }, [chainId]);

  return (
    <div className="navbar">
      <NavbarHomeButton />
      <div className="navbarRight">
        <NavbarLanguageDropdown />
        <AnimatePresence key="walletButton" exitBeforeEnter>
          {!active ? (
            <motion.div
              key="loginButtonMotion"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <button
                type="button"
                className="navbarLoginButton"
                onClick={connect}
              >
                Login
              </button>
            </motion.div>
          ) : (
            <motion.div
              key="walletButtonMotion"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="navbarWalletMenuButtons"
            >
              <NavbarWalletButton buttonText={<NavbarWalletBalance />} />
              <NavbarMenuButton onClick={sheetToggle} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Navbar;
