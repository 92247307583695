import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import mailIcon from '../../shared/constants/icons/mail_icon.png';
import arrowUpIcon from '../../shared/constants/icons/arrow_up_icon.png';
import arrowDownIcon from '../../shared/constants/icons/arrow_down_icon.png';
import { IJSONObject } from '../../shared/interfaces';
import translate from '../../shared/functions/translate';

const HomepageContact = () => {
  const [isOpen, setIsOpen] = useState(false);

  // motion
  const info = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 2.7,
        duration: 1
      }
    }
  };

  // fixed hooks translation
  const translatedText: IJSONObject = {
    contact: translate('contact'),
    joinDiscord: translate('joinDiscord'),
    join: translate('join')
  };

  const handleJoinDiscord = () => {
    window.open('https://discord.gg/hSgdU3q94d', '_blank');
  };

  return (
    <motion.div
      key="homepageContactWrapper"
      variants={info}
      initial="hidden"
      animate="visible"
      className="homepageContactWrapper"
    >
      <div className="cardTitle">
        <img src={mailIcon} alt="mailIcon" className="gameInfoIcon" />
        {translatedText.contact}
      </div>
      <AnimatePresence key="homepageInfoButton" exitBeforeEnter>
        {!isOpen ? (
          <motion.button
            key="arrowDownButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowDownIcon}
              alt="arrowDownIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        ) : (
          <motion.button
            key="arrowUpButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowUpIcon}
              alt="arrowUpIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        )}
      </AnimatePresence>
      <motion.div
        key="homepageContact"
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: '115px',
            transition: {
              delayChildren: 0.6,
              staggerChildren: 0.6,
              duration: 0.4,
              ease: 'easeInOut'
            }
          },
          collapsed: {
            height: '0px'
          }
        }}
        transition={{ duration: 0.4, ease: 'easeInOut' }}
      >
        {isOpen && (
          <>
            <motion.div
              key="homepageContact-1"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="homepageContactContainer"
            >
              <p>{translatedText.joinDiscord}</p>
              <button type="button" onClick={handleJoinDiscord}>
                {translatedText.join}
              </button>
            </motion.div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

export default HomepageContact;
