const LoadingSpinner = () => (
  <div className="spinner">
    <div className="spinner-container">
      <svg viewBox="25 25 50 50">
        <circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  </div>
);

export default LoadingSpinner;
