interface IProps {
  positionIndex?: number;
  color?: string;
}

const defaultProps = {
  positionIndex: 0,
  color: '#f8f8f8'
};

const RPSGamePaperIcon: React.FC<IProps> = ({
  positionIndex,
  color
}: IProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-81 141 512 512"
  >
    {positionIndex === 0 ? (
      <path
        fill={color}
        stroke={color}
        d="M318.4,545.6h10.8c43.6,0,76.7-40.5,65.5-83c49.5-25.6,47.5-94.1-0.7-118c10.6-41.3-20.9-82.4-65-82.4H202.9
	c7.4-20,5.1-43.3-8.9-62.1c-21.8-29.4-63.3-35.8-93.2-14.6l-168.4,120c-8.4,6-13.4,15.7-13.4,26.1l0,222.9
	c0,14.9,10.3,27.8,24.8,31.2l131,30.2c15,3.4,30.3,5.2,45.7,5.2H252C292.6,621,324,585.5,318.4,545.6z M120.4,573
	c-11.7,0-23.5-1.3-34.9-4L-33,541.7l0-201.9l161.7-115.2c20.3-14.4,42.8,15,22.6,29.4l-38.1,27.1c-12.6,9-6.3,29,9.3,29H329
	c25.6,0,24.8,36.6-0.7,36.6H175c-8.8,0-16,7.2-16,16v6.9c0,8.8,7.2,16,16,16h189c25.7,0,24.8,36.6-0.7,36.6H175c-8.8,0-16,7.2-16,16
	v6.9c0,8.8,7.2,16,16,16h154.9c25.7,0,24.8,36.6-0.7,36.6H175c-8.8,0-16,7.2-16,16v6.9c0,8.8,7.2,16,16,16h76.3
	c26.2,0,25.6,36.6,0.7,36.6L120.4,573L120.4,573z"
      />
    ) : (
      <path
        fill={color}
        stroke={color}
        d="M98,621h131.6c15.4,0,30.7-1.7,45.7-5.2l131-30.2c14.5-3.3,24.8-16.3,24.8-31.2V331.5c0-10.3-5-20.1-13.4-26.1l-168.4-120
	c-29.8-21.2-71.4-14.8-93.2,14.6c-13.9,18.8-16.3,42.1-8.9,62.1H21c-44.1,0-75.5,41.1-65,82.4c-48.2,23.9-50.2,92.4-0.7,118
	c-11.1,42.5,21.9,83,65.5,83h10.8C26,585.5,57.4,621,98,621z M229.6,573H98c-24.9,0-25.6-36.6,0.7-36.6H175c8.8,0,16-7.2,16-16v-6.9
	c0-8.8-7.2-16-16-16H20.8c-25.5,0-26.4-36.6-0.7-36.6H175c8.8,0,16-7.2,16-16v-6.9c0-8.8-7.2-16-16-16H-13.3
	c-25.5,0-26.4-36.6-0.7-36.6h189c8.8,0,16-7.2,16-16v-6.9c0-8.8-7.2-16-16-16H21.7c-25.5,0-26.3-36.6-0.7-36.6h206.5
	c15.6,0,21.9-20,9.3-29L198.7,254c-20.2-14.4,2.3-43.8,22.6-29.4L383,339.8v201.9L264.4,569C253,571.7,241.3,573,229.6,573
	L229.6,573z"
      />
    )}
  </svg>
);

RPSGamePaperIcon.defaultProps = defaultProps;
export default RPSGamePaperIcon;
