import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useContract } from '../../../contexts/ContractProvider';
import arrowUpIcon from '../../../shared/constants/icons/arrow_up_icon.png';
import getChainInfo from '../../../shared/functions/getChainInfo';
import translate from '../../../shared/functions/translate';
import { IReduxState } from '../../../shared/interfaces';

interface IProps {
  forcePageRefresh: React.MutableRefObject<boolean>;
  createGameBlock: React.MutableRefObject<number>;
  currentPage: React.MutableRefObject<number>;
}

const OnELobbyCreate: React.FC<IProps> = ({
  forcePageRefresh,
  createGameBlock,
  currentPage
}: IProps) => {
  // contexts
  const { account, chainId } = useWeb3React<Web3Provider>();
  const { oneContract } = useContract();

  // states and contexts
  const [expanded, setExpanded] = useState<boolean>(false);
  const [joinFee, setJoinFee] = useState<number>(
    Number(getChainInfo(chainId, 'min-amount'))
  );

  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  // boundary conditions
  const minJoinFee = Number(getChainInfo(chainId, 'min-amount'));
  const maxJoinFee = Number(getChainInfo(chainId, 'max-amount'));

  // functions
  const setLastPage = async () => {
    try {
      const fetchedGameAdresses = await oneContract?.getGames(
        `${100}`,
        `${(currentPage.current - 1) * 100}`,
        coinData.address
      );
      const gameAddressesLength = fetchedGameAdresses.filter(
        (value: string) => value.toString() !== '0'
      ).length;
      if (Math.floor(gameAddressesLength / 8) > 0) {
        currentPage.current += Math.floor(gameAddressesLength / 8);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // UI functions
  const onCreate = async () => {
    if (account) {
      if (!expanded) {
        setExpanded(true);
      } else if (joinFee >= minJoinFee && joinFee <= maxJoinFee) {
        try {
          const bigJoinFee = ethers.utils.parseUnits(
            joinFee.toString(),
            coinData.decimal
          );
          const receipt = await oneContract?.create(
            bigJoinFee.toString(),
            coinData.id
          );
          const awaitedReceipt = await receipt.wait();
          await setLastPage();
          createGameBlock.current = awaitedReceipt.blockNumber;
          forcePageRefresh.current = true;
        } catch (error: any) {
          if (error.data) {
            window.alert(error.data.message);
          } else {
            window.alert(error.message);
          }
        }
      }
    }
  };

  return (
    <div className="oneLobbyAccordionWrapper">
      <motion.div
        key="oneLobbyCreateForm"
        initial="collapsed"
        animate={expanded ? 'open' : 'closed'}
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { opacity: 0, height: 0 }
        }}
        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
      >
        <div className="oneLobbyAccordionTitle">
          {translate('createNewContract')}
        </div>
        <button
          type="button"
          onClick={() => setExpanded(false)}
          className="oneLobbyCollapseButton"
        >
          <img src={arrowUpIcon} alt="arrowUpIcon" />
        </button>
        <div className="oneLobbyAccordionContainer">
          <div className="oneLobbyAccordion">
            <span>{translate('entryFee')}</span>
            <div className="oneLobbyAccordionInputWrapper">
              <input
                type="number"
                min={minJoinFee}
                placeholder={`${minJoinFee}`}
                onChange={(e) => setJoinFee(parseFloat(e.target.value))}
              />
              <span>{coinData.name}</span>
            </div>
          </div>
        </div>
      </motion.div>
      <button
        className="oneLobbyCreateButton"
        type="button"
        disabled={!account}
        onClick={onCreate}
      >
        {translate('create')}
      </button>
    </div>
  );
};

export default OnELobbyCreate;
