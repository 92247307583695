import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import infoIcon from '../../../shared/constants/icons/info_icon.png';
import arrowUpIcon from '../../../shared/constants/icons/arrow_up_icon.png';
import arrowDownIcon from '../../../shared/constants/icons/arrow_down_icon.png';
import { IJSONObject } from '../../../shared/interfaces';
import translate from '../../../shared/functions/translate';

const RPSInfoCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  // fixed hooks translation
  const translatedText: IJSONObject = {
    gameInfo: translate('gameInfo'),
    howToPlay: translate('howToPlay'),
    rpsHowToPlay1: translate('rpsHowToPlay1'),
    rpsHowToPlay2: translate('rpsHowToPlay2'),
    rpsHowToPlay3: translate('rpsHowToPlay3'),
    rpsHowToPlay4: translate('rpsHowToPlay4'),
    rpsHowToPlay5: translate('rpsHowToPlay5'),
    rpsHowToPlay6: translate('rpsHowToPlay6'),
    rpsHowToPlay7: translate('rpsHowToPlay7'),
    rpsHowToPlay8: translate('rpsHowToPlay8'),
    rpsHowToPlay9: translate('rpsHowToPlay9'),
    rules: translate('rules'),
    rpsRules1: translate('rpsRules1'),
    rpsRules2: translate('rpsRules2'),
    rpsRules3: translate('rpsRules3'),
    rpsRules4: translate('rpsRules4'),
    rpsRules5: translate('rpsRules5'),
    rpsRules6: translate('rpsRules6')
  };

  return (
    <div className="card">
      <div className="cardTitle">
        <img src={infoIcon} alt="infoIcon" className="gameInfoIcon" />
        {translatedText.gameInfo}
      </div>
      <AnimatePresence key="gameInfoButton" exitBeforeEnter>
        {!isOpen ? (
          <motion.button
            key="arrowDownButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowDownIcon}
              alt="arrowDownIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        ) : (
          <motion.button
            key="arrowUpButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowUpIcon}
              alt="arrowUpIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        )}
      </AnimatePresence>
      <motion.div
        key="gameInfoCard"
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: '647px',
            transition: {
              delayChildren: 0.6,
              staggerChildren: 0.6,
              duration: 1.2,
              ease: 'easeInOut'
            }
          },
          collapsed: {
            height: '0px'
          }
        }}
        transition={{ duration: 1.2, ease: 'easeInOut' }}
      >
        {isOpen && (
          <>
            <motion.div
              key="gameInfoHowToPlay"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="innerCard"
            >
              <div className="innerCardTitle">{translatedText.gameInfo}</div>
              <ul className="gameInfoCardBody">
                <li>
                  <p>
                    {translatedText.rpsHowToPlay1}
                    <a href="https://metamask.io/">MetaMask</a>
                    {translatedText.rpsHowToPlay2}
                  </p>
                </li>
                <li>
                  <p>{translatedText.rpsHowToPlay3}</p>
                </li>
                <li>
                  <p>{translatedText.rpsHowToPlay4}</p>
                </li>
                <li>
                  <p>{translatedText.rpsHowToPlay5}</p>
                </li>
                <li>
                  <p>{translatedText.rpsHowToPlay6}</p>
                </li>
                <li>
                  <p>{translatedText.rpsHowToPlay7}</p>
                </li>
                <li>
                  <p>{translatedText.rpsHowToPlay8}</p>
                </li>
                <li>
                  <p>{translatedText.rpsHowToPlay9}</p>
                </li>
              </ul>
            </motion.div>
            <motion.div
              key="gameInfoRules"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="innerCard"
            >
              <div className="innerCardTitle">{translatedText.rules}</div>
              <ul className="gameInfoCardBody">
                <li>
                  <p>{translatedText.rpsRules1}</p>
                </li>
                <li>
                  <p>{translatedText.rpsRules2}</p>
                </li>
                <li>
                  <p>{translatedText.rpsRules3}</p>
                </li>
                <li>
                  <p>{translatedText.rpsRules4}</p>
                </li>
                <li>
                  <p>{translatedText.rpsRules5}</p>
                </li>
                <li>
                  <p>{translatedText.rpsRules6}</p>
                </li>
              </ul>
            </motion.div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default RPSInfoCard;
