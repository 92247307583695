import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

interface IProps {
  totalPointsInput: number;
  large?: boolean;
  active?: boolean;
}

const defaultProps = {
  large: true,
  active: true
};

const RPSGameTotalPointsText: React.FC<IProps> = ({
  totalPointsInput,
  large,
  active
}: IProps) => {
  // states
  const [totalPoints, setTotalPoints] = useState<number>(totalPointsInput);
  const interval = useRef<NodeJS.Timeout | null>(null);

  // effects
  useEffect(() => {
    if (totalPointsInput > totalPoints) {
      interval.current = setInterval(() => {
        setTotalPoints((prevTotalPoints) => prevTotalPoints + 1);
      }, 700);
    } else if (totalPointsInput < totalPoints) {
      interval.current = setInterval(() => {
        setTotalPoints((prevTotalPoints) => prevTotalPoints - 1);
      }, 700);
    }
    if ((totalPointsInput === 0 || totalPoints > 20) && interval.current) {
      clearInterval(interval.current);
      setTotalPoints(0);
    }
    if (totalPoints === totalPointsInput && interval.current) {
      clearInterval(interval.current);
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [totalPoints, totalPointsInput]);

  // variants
  const slideVariants = {
    enter: {
      y: -20,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1
    },
    exit: {
      y: 20,
      opacity: 0
    }
  };

  return (
    <AnimatePresence key="rpsGameTotalPointsText" exitBeforeEnter>
      <motion.div
        key={`rpsGameTotalPointsTextValue-${totalPoints}`}
        variants={slideVariants}
        initial="enter"
        animate="visible"
        exit="exit"
        transition={{
          duration: 0.3,
          ease: 'easeInOut'
        }}
        className={`${
          large
            ? 'rpsGameTotalPointsValueLarge'
            : 'rpsGameTotalPointsValueNormal'
        } 
          ${
            active
              ? ' rpsGameTotalPointsValueActive'
              : ' rpsGameTotalPointsValueDisabled'
          }`}
      >
        {totalPoints}
      </motion.div>
    </AnimatePresence>
  );
};

RPSGameTotalPointsText.defaultProps = defaultProps;
export default RPSGameTotalPointsText;
