import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import infoIcon from '../../shared/constants/icons/info_icon.png';
import arrowUpIcon from '../../shared/constants/icons/arrow_up_icon.png';
import arrowDownIcon from '../../shared/constants/icons/arrow_down_icon.png';
import translate from '../../shared/functions/translate';
import { IJSONObject } from '../../shared/interfaces';

const FaucetInfoCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  // fixed hooks translation
  const translatedText: IJSONObject = {
    gameInfo: translate('gameInfo'),
    howToPlay: translate('howToPlay'),
    djackHowToPlay1: translate('djackHowToPlay1'),
    djackHowToPlay2: translate('djackHowToPlay2'),
    djackHowToPlay3: translate('djackHowToPlay3'),
    djackHowToPlay4: translate('djackHowToPlay4'),
    djackHowToPlay5: translate('djackHowToPlay5'),
    djackHowToPlay6: translate('djackHowToPlay6'),
    djackHowToPlay7: translate('djackHowToPlay7'),
    djackHowToPlay8: translate('djackHowToPlay8'),
    rules: translate('rules'),
    djackRules1: translate('djackRules1'),
    djackRules2: translate('djackRules2'),
    djackRules3: translate('djackRules3'),
    djackRules4: translate('djackRules4'),
    djackRules5: translate('djackRules5'),
    djackRules6: translate('djackRules6'),
    djackRules7: translate('djackRules7'),
    djackRules8: translate('djackRules8')
  };

  return (
    <div className="card">
      <div className="cardTitle">
        <img src={infoIcon} alt="infoIcon" className="gameInfoIcon" />
        Info
      </div>
      <AnimatePresence key="gameInfoButton" exitBeforeEnter>
        {!isOpen ? (
          <motion.button
            key="arrowDownButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowDownIcon}
              alt="arrowDownIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        ) : (
          <motion.button
            key="arrowUpButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowUpIcon}
              alt="arrowUpIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        )}
      </AnimatePresence>
      <motion.div
        key="gameInfoCard"
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            minHeight: '685px',
            transition: {
              delayChildren: 0.6,
              staggerChildren: 0.6,
              duration: 1.2,
              ease: 'easeInOut'
            }
          },
          collapsed: {
            height: '0px'
          }
        }}
        transition={{ duration: 1.2, ease: 'easeInOut' }}
      >
        {isOpen && (
          <>
            <motion.div
              key="gameInfoHowToPlay"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="innerCard"
            >
              <div className="innerCardTitle">{translatedText.howToPlay}</div>
              <ul className="gameInfoCardBody">
                <li>
                  <p>
                    {translatedText.djackHowToPlay1}
                    <a href="https://metamask.io/">MetaMask</a>
                    {translatedText.djackHowToPlay2}
                  </p>
                </li>
                <li>
                  <p>{translatedText.djackHowToPlay3}</p>
                </li>
                <li>
                  <p>{translatedText.djackHowToPlay4}</p>
                </li>
                <li>
                  <p>{translatedText.djackHowToPlay5}</p>
                </li>
                <li>
                  <p>{translatedText.djackHowToPlay6}</p>
                </li>
                <li>
                  <p>{translatedText.djackHowToPlay7}</p>
                </li>
                <li>
                  <p>{translatedText.djackHowToPlay8}</p>
                </li>
              </ul>
            </motion.div>
            <motion.div
              key="gameInfoRules"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="innerCard"
            >
              <div className="innerCardTitle">{translatedText.rules}</div>
              <ul className="gameInfoCardBody">
                <li>
                  <p>{translatedText.djackRules1}</p>
                </li>
                <li>
                  <p>{translatedText.djackRules2}</p>
                </li>
                <li>
                  <p>{translatedText.djackRules3}</p>
                </li>
                <li>
                  <p>{translatedText.djackRules4}</p>
                </li>
                <li>
                  <p>{translatedText.djackRules5}</p>
                </li>
                <li>
                  <p>{translatedText.djackRules6}</p>
                </li>
                <li>
                  <p>{translatedText.djackRules7}</p>
                </li>
                <li>
                  <p>{translatedText.djackRules8}</p>
                </li>
              </ul>
            </motion.div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default FaucetInfoCard;
