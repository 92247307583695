import { motion } from 'framer-motion';
import FaucetEventLog from '../../components/faucet/FaucetEventLog';
import FaucetForm from '../../components/faucet/FaucetForm';
import FaucetInfoCard from '../../components/faucet/FaucetInfoCard';
import FaucetTitle from '../../components/faucet/FaucetTitle';

const Faucet = () => (
  <motion.div
    key="Home"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="card">
      <div className="cardTitle">
        <FaucetTitle />
      </div>
      <div className="innerCard">
        <FaucetForm />
      </div>
      <div className="innerCard">
        <FaucetEventLog />
      </div>
    </div>
    <FaucetInfoCard />
  </motion.div>
);

export default Faucet;
