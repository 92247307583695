import { useHistory, useLocation } from 'react-router';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from 'ethers';
import Button from './Button';
import { IJSONObject } from '../../../shared/interfaces';
import translate from '../../../shared/functions/translate';

interface IProps {
  players: string[];
  contract: Contract | undefined;
  redirect: string;
  seated?: boolean;
}

const defaultProps = {
  seated: true
};

const LeaveButton: React.FC<IProps> = ({
  players,
  contract,
  redirect,
  seated
}: IProps) => {
  // gameId
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  // history
  const history = useHistory();

  // context
  const { account } = useWeb3React<Web3Provider>();

  // functions
  const onLeave = async () => {
    try {
      if (!account || !players.includes(account)) {
        window.alert('You are not in the room.');
      }
      let receipt;
      if (seated) {
        receipt = await contract?.leave(
          path,
          players.indexOf(account as string)
        );
      } else {
        receipt = await contract?.leave(path);
      }
      await receipt.wait();
      history.push(redirect);
    } catch (error: any) {
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  // fixed hooks translation
  const translatedText: IJSONObject = {
    leave: translate('leave')
  };

  return (
    <>
      {account && players.includes(account) && (
        <Button
          onClick={{ onClick: onLeave }}
          buttonClass="buttonText buttonPurple"
          buttonText={translatedText.leave}
        />
      )}
    </>
  );
};

LeaveButton.defaultProps = defaultProps;
export default LeaveButton;
