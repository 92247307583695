import { BigNumber } from '@ethersproject/bignumber';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import DBetChangeCoinModal from '../../components/dbet/DBetChangeCoinModal';
import DBetGameBlockStats from '../../components/dbet/DBetGameBlockStats';
import DBetGameCurrentRates from '../../components/dbet/DBetGameCurrentRates';
import DBetGameEventsLog from '../../components/dbet/DBetGameEventsLog';
import DBetGameInfoCard from '../../components/dbet/DBetGameInfoCard';
import DBetGameNavigate from '../../components/dbet/DBetGameNavigate';
import DBetGameRollArea from '../../components/dbet/DBetGameRollArea';
import DBetGameTotalPrize from '../../components/dbet/DBetGameTotalPrize';
import DBetGameUIButton from '../../components/dbet/DBetGameUIButton';
import RoomLinkCopyButton from '../../components/utils/buttons/RoomLinkCopyButton';
import DBetTitle from '../../components/utils/graphics/DBetTitle';
import { useContract } from '../../contexts/ContractProvider';
import { useModal } from '../../contexts/ModalProvider';
import getAddress from '../../shared/functions/getAddress';
import translate from '../../shared/functions/translate';
import { IReduxState } from '../../shared/interfaces';

const sum = (a: number, b: number) => a + b;

const DBetGame = () => {
  // providers
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const history = useHistory();

  const { chainId } = useWeb3React<Web3Provider>();

  const { dbetContract } = useContract();

  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  // game states
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [totalBets, setTotalBets] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  const [rollValue, setRollValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gameId, setGameId] = useState<string>('');
  const [isRolled, setIsRolled] = useState<boolean>(false);
  const isSubscribed = useRef<boolean>(false); // memory leak fix

  // fetch periodic game info
  const getPeriodicGameInfo = async () => {
    if (gameId.length === 0) return;
    try {
      const betValues = await dbetContract?.getBetValues(path, coinData.id);
      const fetchedTotalBets = betValues[1].map((item: BigNumber) =>
        Number(ethers.utils.formatEther(item))
      );
      const gameInfo = await dbetContract?.games(gameId);
      const fetchedRollValue = gameInfo[7];
      const fetchedIsRolled = !gameInfo[1].eq(ethers.constants.Zero);
      if (isSubscribed.current) {
        setTotalBets(fetchedTotalBets);
        setRollValue(fetchedRollValue ? fetchedRollValue.toNumber() : 0);
        setIsLoading(false);
        setIsRolled(fetchedIsRolled);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // fetch game id
  const getGameId = async () => {
    if (!dbetContract || !path) return;
    try {
      const fetchedGameId = await dbetContract?.getGameId(path, coinData.id);
      if (isSubscribed.current) {
        setGameId(fetchedGameId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // lifecycle hooks
  useEffect(() => {
    isSubscribed.current = true;
    getGameId();
    const dbetJsonRpcProvider = new ethers.providers.JsonRpcProvider(
      getAddress(chainId, 'provider')
    );
    dbetJsonRpcProvider.on('block', (blockNumber: number) => {
      getPeriodicGameInfo();
      if (isSubscribed.current) {
        setCurrentBlock(blockNumber);
      }
    });
    return () => {
      isSubscribed.current = false;
      dbetJsonRpcProvider.off('block');
    };
  }, [path, chainId, dbetContract, gameId]);

  // cleanup
  const cleanup = () => {
    setTotalBets([0, 0, 0, 0, 0, 0]);
    setRollValue(0);
    setIsRolled(false);
    setIsLoading(true);
  };

  // cleanup on path change
  useEffect(() => {
    cleanup();
  }, [path]);

  // redirect to correct path on chainId change
  useEffect(() => {
    if (
      currentBlock < Number(path) - 500 ||
      currentBlock > Number(path) + 500
    ) {
      const nextGameBlock = Math.ceil(currentBlock / 100) * 100;
      history.push(`/dbetgame/${nextGameBlock}`);
    }
  }, [chainId, path, currentBlock]);

  // switch to babi modal
  const { createModal, clearModal } = useModal();

  useEffect(() => {
    if (coinData.name !== 'TBNB') {
      createModal(<DBetChangeCoinModal />);
    } else {
      clearModal();
    }
  }, [coinData]);

  return (
    <motion.div
      key="dbetGame"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="card">
        <div className="cardTitle">
          <DBetTitle />
        </div>
        <div className="innerCard">
          <div className="innerCardTitle innerCardTitleWithButton">
            <RoomLinkCopyButton title={translate('rollNo')} />
            <RoomLinkCopyButton title={`#${path}`} />
          </div>
          <div className="dbetGameRollAreaWrapper">
            <DBetGameRollArea
              totalBets={totalBets}
              rollValue={rollValue}
              isRolled={isRolled}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="innerCard">
          <div className="innerCardTitle">
            <DBetGameUIButton
              currentBlock={currentBlock}
              rollValue={rollValue}
              gameBalance={totalBets.reduce(sum, 0)}
              isRolled={isRolled}
            />
          </div>
        </div>
        <div className="innerCard">
          <div className="dbetGameBlockStatsWrapper">
            <DBetGameBlockStats currentBlock={currentBlock} />
          </div>
        </div>
        <div className="innerCard">
          <div className="dbetGameCurrentRatesWrapper">
            <DBetGameCurrentRates totalBets={totalBets} />
          </div>
        </div>
        <div className="innerCard">
          <DBetGameTotalPrize
            gameBalance={Math.round(totalBets.reduce(sum, 0) * 1000) / 1000}
          />
        </div>
        <div className="innerCard">
          <div className="innerCardTitle">
            <span>{translate('eventsLog')}</span>
          </div>
          <DBetGameEventsLog />
        </div>
      </div>
      <DBetGameNavigate />
      <DBetGameInfoCard />
    </motion.div>
  );
};

export default DBetGame;
