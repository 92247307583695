import { createContext, useContext, useState } from 'react';

interface IProps {
  children: React.ReactNode;
}

interface IModalContext {
  createModal: (content: React.ReactNode) => void;
  clearModal: () => void;
}

const ModalContext = createContext<IModalContext>({
  createModal: () => {},
  clearModal: () => {}
});

const ModalProvider = ({ children }: IProps) => {
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);

  const createModal = (content: React.ReactNode) => {
    setModalContent(content);
  };

  const clearModal = () => {
    setModalContent(null);
  };

  return (
    <ModalContext.Provider
      value={{
        createModal,
        clearModal
      }}
    >
      {children}
      {modalContent && (
        <div className="modalContainer">
          <div className="modal">{modalContent}</div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
