import coins from '../constants/coins.json';
import supportedChains from '../constants/supportedChains';
import { CurrencyType } from '../enums';

interface ICoin {
  id: string;
  address: string;
  tokenId: number;
  name: string;
  decimal: number;
  type: CurrencyType;
  logo: string;
}

const getCoinInfo = (id: number | undefined) => {
  let coinList: ICoin[] = [];

  if (!id || !supportedChains.includes(id)) {
    coinList = coins['97'];
  } else if (id === 43113) {
    coinList = coins['43113'];
  } else {
    coinList = coins['97'];
  }

  return coinList;
};

export default getCoinInfo;
