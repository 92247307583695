import { motion } from 'framer-motion';
import translate from '../../shared/functions/translate';

const HomepageTitle = () => {
  const title = 'BITCOLYMPICS';
  const line = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.8,
        staggerChildren: 0.1
      }
    }
  };
  const letter = {
    hidden: {
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };
  const desc = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 2
      }
    }
  };
  const info = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 2.2,
        duration: 1
      }
    }
  };
  const svgAnimation = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1
      }
    }
  };
  const svgVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1
    }
  };
  const svgTransition = {
    ease: 'easeInOut',
    duration: 0.6
  };
  return (
    <div className="homepageTitleWrapper">
      <motion.h1
        key="homepageTitleHeader"
        variants={line}
        initial="hidden"
        animate="visible"
        className="homepageTitleHeader"
      >
        {title.split('').map((char, i) => (
          <motion.span key={i} variants={letter}>
            {char}
          </motion.span>
        ))}
      </motion.h1>
      <motion.span
        key="homepageTitleDesc"
        variants={desc}
        initial="hidden"
        animate="visible"
        className="homepageTitleDesc"
      >
        {translate('blockchainGames')}
      </motion.span>
      <motion.svg
        key="djackTitleSvg"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 219 90"
        variants={svgAnimation}
        initial="hidden"
        animate="visible"
        className="homepageTitleSvg"
      >
        <motion.g variants={svgVariants} transition={svgTransition}>
          <motion.polyline
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="3"
            points="116.7,59.5 109.2,64 82.7,49 82.7,41 	"
          />
          <motion.polyline
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="3"
            points="82.7,25.5 82.7,18 108.7,3 135.7,18 135.7,49 128.7,52 	"
          />
        </motion.g>
        <motion.g variants={svgVariants} transition={svgTransition}>
          <motion.polyline
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="3"
            points="95.7,64 95.7,71 69.2,86.5 42.7,71 42.7,41 49.5,37.1 	"
          />
          <motion.polyline
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="3"
            points="95.7,49 95.7,41 69.2,25.5 61.7,30 	"
          />
          <motion.polyline
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="3"
            points="175,64 175,71 149,86.5 122.7,71 122.7,41 129.3,37.3 	"
          />
          <motion.polyline
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="3"
            points="140.7,30 148.8,25.5 175,41 175,49 	"
          />
        </motion.g>
        <motion.g variants={svgVariants} transition={svgTransition}>
          <motion.polyline
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="3"
            points="36.7,59.5 29.7,64 3.7,49 3.7,18 29.7,3 55.7,18 55.7,49 49.7,52 	"
            variants={svgVariants}
            transition={svgTransition}
          />
          <motion.polyline
            fill="none"
            stroke="#f8f8f8"
            strokeWidth="3"
            points="162.3,25.5 162.3,18 188.7,3 215,18 215,49 188.7,64 162.3,49 162.7,43 	"
            variants={svgVariants}
            transition={svgTransition}
          />
        </motion.g>
      </motion.svg>
      <motion.h4
        key="homepageTitleInfoHeader"
        variants={info}
        initial="hidden"
        animate="visible"
        className="homepageTitleInfoHeader"
      >
        <span>{translate('subTitle1')}</span>
      </motion.h4>
      <motion.p
        key="homepageTitleInfoText"
        variants={info}
        initial="hidden"
        animate="visible"
        className="homepageTitleInfoText"
      >
        <span>{translate('subTitle2')}</span>
      </motion.p>

      <motion.div
        key="homepageTitleWavesWrapper"
        variants={info}
        initial="hidden"
        animate="visible"
        className="bicolympicsTitleWavesWrapper"
      >
        <svg
          className="bicolympicsTitleWaves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </motion.div>
    </div>
  );
};

export default HomepageTitle;
