import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useContract } from '../../contexts/ContractProvider';
import { CurrencyType } from '../../shared/enums';
import getAddress from '../../shared/functions/getAddress';
import { IReduxState } from '../../shared/interfaces';

const NavbarWalletBalance = () => {
  // states and contexts
  const { account, chainId } = useWeb3React<Web3Provider>();
  const { walletContract } = useContract();
  const [balance, setBalance] = useState<string>();
  const walletJsonRpcProvider = new ethers.providers.JsonRpcProvider(
    getAddress(chainId, 'provider')
  );

  // redux coin name selector
  const coinData = useSelector((state: IReduxState) => state.coin);

  // lifecycle hooks
  useEffect(() => {
    let isSubscribed = true;
    if (chainId && walletContract) {
      walletJsonRpcProvider.on('block', async () => {
        if (isSubscribed) {
          const coinBalance = await walletContract.balance(
            account,
            coinData.id
          );
          const strBalance = ethers.utils.formatUnits(
            coinBalance.toString(),
            coinData.decimal
          );
          setBalance(
            Number(strBalance)
              .toFixed(coinData.type === CurrencyType.ERC1155 ? 0 : 2)
              .toString()
          );
        }
      });
    }
    return () => {
      isSubscribed = false;
      if (walletJsonRpcProvider) {
        walletJsonRpcProvider.off('block');
      }
    };
  }, [account, chainId, coinData, walletContract]);

  return (
    <AnimatePresence key="navbarWalletBalanceCoin" exitBeforeEnter>
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        key={`navbarWalletBalanceCoin-${coinData.name}`}
      >
        {balance ? `${balance} ${coinData.name.toUpperCase()}` : '...'}
      </motion.span>
    </AnimatePresence>
  );
};

export default NavbarWalletBalance;
