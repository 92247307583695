export default [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'coin',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: '_withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_bet',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'betValue',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_block',
        type: 'uint256'
      },
      {
        internalType: 'bytes32',
        name: 'currency',
        type: 'bytes32'
      }
    ],
    name: 'bet',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_block',
        type: 'uint256'
      },
      {
        internalType: 'bytes32',
        name: 'currency',
        type: 'bytes32'
      }
    ],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subscriptionId',
        type: 'uint64'
      },
      {
        internalType: 'address',
        name: 'vrfCoordinator_',
        type: 'address'
      },
      {
        internalType: 'bytes32',
        name: 'keyHash_',
        type: 'bytes32'
      },
      {
        internalType: 'address',
        name: 'wallet_',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'settings_',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'have',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'want',
        type: 'address'
      }
    ],
    name: 'OnlyCoordinatorCanFulfill',
    type: 'error'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'id',
        type: 'bytes32'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'actor',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_type',
        type: 'uint256'
      }
    ],
    name: 'GameLog',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'requestId',
        type: 'uint256'
      },
      {
        internalType: 'uint256[]',
        name: 'randomWords',
        type: 'uint256[]'
      }
    ],
    name: 'rawFulfillRandomWords',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_block',
        type: 'uint256'
      },
      {
        internalType: 'bytes32',
        name: 'currency',
        type: 'bytes32'
      }
    ],
    name: 'roll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'feeMultiplier',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32'
      }
    ],
    name: 'games',
    outputs: [
      {
        internalType: 'bytes32',
        name: 'currency',
        type: 'bytes32'
      },
      {
        internalType: 'uint256',
        name: 'requestId',
        type: 'uint256'
      },
      {
        internalType: 'bytes32',
        name: 'remainingTime',
        type: 'bytes32'
      },
      {
        internalType: 'address',
        name: 'roller',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'betCount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'totalBetAmount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'remainings',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'result',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_block',
        type: 'uint256'
      },
      {
        internalType: 'bytes32',
        name: 'currency',
        type: 'bytes32'
      }
    ],
    name: 'getBetValues',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalB',
        type: 'uint256'
      },
      {
        internalType: 'uint256[6]',
        name: 'bets',
        type: 'uint256[6]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_block',
        type: 'uint256'
      },
      {
        internalType: 'bytes32',
        name: 'currency',
        type: 'bytes32'
      }
    ],
    name: 'getGameId',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32'
      }
    ],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    name: 'requestIds',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    name: 's_randomWords',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 's_requestId',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
];
