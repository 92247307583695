interface IProps {
  white?: boolean;
}

const defaultProps: IProps = {
  white: false
};

export const DJackIconSvg = ({ white }: IProps) => (
  <svg
    key="djackIconSvg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
  >
    <path
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="2"
      d="M17,29H7c-2.2,0-4-1.8-4-4V15c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v10C21,27.2,19.2,29,17,29z"
    />
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="2"
      cx="12"
      cy="20"
      r="1"
    />
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="2"
      cx="8"
      cy="16"
      r="1"
    />
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="2"
      cx="16"
      cy="24"
      r="1"
    />
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="2"
      cx="8"
      cy="24"
      r="1"
    />
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="2"
      cx="16"
      cy="16"
      r="1"
    />
    <path
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="2"
      d="M11,11V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v10c0,2.2-1.8,4-4,4h-4"
    />
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="2"
      cx="24"
      cy="8"
      r="1"
    />
  </svg>
);

DJackIconSvg.defaultProps = defaultProps;

export const TnCIconSvg = ({ white }: IProps) => (
  <svg
    key="tncIconSvg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="-293 385 24 24"
  >
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="1.5"
      cx="-278"
      cy="400"
      r="6"
    />
    <path
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="1.5"
      d="M-278,393.6c0,0.2,0,0.3,0,0.5c0,3.3,2.7,6,6,6c0.1,0,0.2,0,0.4,0"
    />
    <path
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="1.5"
      d="M-278,406c0-3.3-2.7-6-6-6c-0.1,0-0.2,0-0.3,0"
    />
    <line
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="1.5"
      x1="-291"
      y1="387"
      x2="-284"
      y2="394"
    />
    <line
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="1.5"
      x1="-285"
      y1="387"
      x2="-281"
      y2="391"
    />
    <line
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="1.5"
      x1="-291"
      y1="393"
      x2="-287"
      y2="397"
    />
  </svg>
);

TnCIconSvg.defaultProps = defaultProps;

export const DBetIconSvg = ({ white }: IProps) => (
  <svg
    id="DBetIconSvg"
    version="1.1"
    width="100%"
    height="100%"
    viewBox="-5 -5 101 101"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M84.479,88.236c0-1.387-1.125-2.512-2.514-2.512H60.22c-0.129-1.805-0.729-3.244-1.787-4.285      c-2.068-2.033-5.154-1.98-8.418-1.926c-0.555,0.01-1.125,0.02-1.709,0.02h-4.771c-0.584,0-1.154-0.01-1.709-0.02      c-3.266-0.053-6.35-0.107-8.418,1.924c-1.059,1.043-1.658,2.482-1.787,4.287H9.87c-1.387,0-2.512,1.125-2.512,2.512      c0,1.389,1.125,2.514,2.512,2.514h72.096C83.354,90.75,84.479,89.625,84.479,88.236z M36.931,85.021      c0.566-0.557,2.916-0.516,4.809-0.484c0.582,0.01,1.182,0.021,1.795,0.021h4.771c0.613,0,1.213-0.012,1.795-0.021      c1.893-0.031,4.242-0.072,4.809,0.484c0.117,0.115,0.201,0.371,0.254,0.703H36.677C36.729,85.393,36.813,85.137,36.931,85.021z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M73.726,39.836V14.258c0-0.992-0.586-1.893-1.494-2.297L46.942,0.742c-0.65-0.287-1.391-0.287-2.039,0L19.604,11.961      c-0.908,0.404-1.494,1.305-1.494,2.297v25.578c0,0.947,0.533,1.814,1.381,2.242L44.79,54.867c0.355,0.18,0.744,0.27,1.133,0.27      s0.777-0.09,1.133-0.27l25.291-12.789C73.192,41.65,73.726,40.783,73.726,39.836z M45.923,5.787l19.486,8.645L45.923,24.504      L26.431,14.432L45.923,5.787z M23.136,18.387l20.273,10.475v19.678L23.136,38.291V18.387z M48.435,48.539V28.861L68.7,18.389      v19.902L48.435,48.539z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M41.569,30.344c-0.543-1.277-2.018-1.869-3.295-1.33L26.302,34.1c-1.277,0.543-1.871,2.018-1.33,3.295      c0.408,0.957,1.336,1.531,2.314,1.531c0.328,0,0.66-0.064,0.98-0.201l11.973-5.086C41.517,33.096,42.112,31.621,41.569,30.344z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M65.534,34.1l-11.969-5.086c-1.273-0.539-2.75,0.053-3.295,1.33c-0.543,1.275,0.053,2.752,1.33,3.295l11.969,5.086      c0.32,0.137,0.654,0.201,0.982,0.201c0.977,0,1.906-0.574,2.313-1.531C67.407,36.119,66.812,34.643,65.534,34.1z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M48.362,58.869c0-1.387-1.125-2.512-2.512-2.512c-1.389,0-2.514,1.125-2.514,2.512v0.166      c0,1.389,1.125,2.514,2.514,2.514c1.387,0,2.512-1.125,2.512-2.514V58.869z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M48.362,67.355c0-1.389-1.125-2.514-2.512-2.514c-1.389,0-2.514,1.125-2.514,2.514v0.166      c0,1.387,1.125,2.512,2.514,2.512c1.387,0,2.512-1.125,2.512-2.512V67.355z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M45.851,73.328c-1.389,0-2.514,1.125-2.514,2.512v0.166c0,1.389,1.125,2.514,2.514,2.514      c1.387,0,2.512-1.125,2.512-2.514V75.84C48.362,74.453,47.237,73.328,45.851,73.328z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M61.466,56.592l-0.105,0.129c-0.879,1.074-0.721,2.656,0.354,3.535c0.467,0.381,1.031,0.568,1.59,0.568      c0.729,0,1.449-0.314,1.945-0.922l0.105-0.129c0.879-1.074,0.721-2.656-0.354-3.535C63.929,55.359,62.345,55.516,61.466,56.592z      "
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M50.714,69.719l-0.105,0.129c-0.879,1.074-0.721,2.656,0.354,3.535c0.467,0.383,1.031,0.568,1.59,0.568      c0.727,0,1.447-0.314,1.945-0.922l0.105-0.129c0.879-1.074,0.721-2.656-0.354-3.535S51.593,68.645,50.714,69.719z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M56.097,63.148l-0.105,0.127c-0.883,1.072-0.73,2.654,0.34,3.537c0.467,0.387,1.033,0.574,1.596,0.574      c0.725,0,1.443-0.311,1.941-0.914l0.105-0.127c0.883-1.072,0.73-2.654-0.342-3.537C58.562,61.926,56.978,62.078,56.097,63.148z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M66.843,50.027l-0.105,0.129c-0.879,1.074-0.721,2.656,0.354,3.535c0.467,0.381,1.031,0.568,1.59,0.568      c0.729,0,1.449-0.314,1.945-0.922l0.105-0.129c0.879-1.074,0.721-2.656-0.354-3.535S67.722,48.951,66.843,50.027z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M26.501,56.283c-1.068,0.885-1.217,2.469-0.332,3.537l0.105,0.129c0.498,0.6,1.215,0.91,1.938,0.91      c0.564,0,1.131-0.189,1.6-0.578c1.07-0.885,1.219-2.469,0.334-3.537l-0.107-0.129C29.153,55.547,27.569,55.398,26.501,56.283z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M21.11,49.73c-1.072,0.881-1.227,2.465-0.346,3.537l0.105,0.129c0.496,0.604,1.217,0.916,1.943,0.916      c0.561,0,1.125-0.188,1.594-0.57c1.072-0.883,1.227-2.465,0.346-3.537l-0.107-0.129C23.765,49.004,22.181,48.852,21.11,49.73z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M31.907,62.822c-1.072,0.883-1.227,2.465-0.346,3.537l0.105,0.129c0.496,0.605,1.217,0.918,1.943,0.918      c0.561,0,1.125-0.188,1.594-0.572c1.072-0.881,1.227-2.465,0.346-3.537l-0.107-0.129C34.562,62.096,32.979,61.943,31.907,62.822      z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      d="M37.298,69.377c-1.068,0.885-1.217,2.469-0.332,3.537l0.105,0.127c0.498,0.6,1.215,0.91,1.938,0.91      c0.564,0,1.131-0.189,1.6-0.578c1.07-0.885,1.219-2.469,0.334-3.537l-0.107-0.127C39.95,68.641,38.366,68.49,37.298,69.377z"
    />
  </svg>
);

DBetIconSvg.defaultProps = defaultProps;

export const OnEIconSvg = ({ white }: IProps) => (
  <svg
    key="oneIconSvg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 118 118"
    width="100%"
    height="100%"
  >
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="6"
      cx="58"
      cy="35"
      r="19.5"
    />
    <path
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="6"
      d="M57.2,15.5c-20,23.6,23.4,12.2,0,39"
    />
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="6"
      cx="30.3"
      cy="83.4"
      r="19.5"
    />
    <path
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="6"
      d="M24,65c0,16.9,9.6,30.7,21.5,30.7"
    />
    <circle
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="6"
      cx="88.3"
      cy="83.4"
      r="19.5"
    />
    <path
      fill="none"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      strokeWidth="6"
      d="M82,101.9c0-18.3,8.5-33.2,19.1-33.2"
    />
  </svg>
);

OnEIconSvg.defaultProps = defaultProps;

export const RPSIconSvg = ({ white }: IProps) => (
  <svg
    key="rpsIconSvg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-49 -371 1024 1024"
    width="100%"
    height="100%"
  >
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      d="M405.5,308.1c26.9-30.4,23.7-74.8-2.8-101.6c22.8-36.6,9.5-84.5-28-104.6c13.9-52.5-33-103.7-87.4-91.2
	c-1.5-47.4-46.5-81.8-92.3-69.5L140-44c-31.6,8.5-58.1,30.3-72.6,60L10.5,132.4c-2.5,5.1-4.6,4.4-31,11.5
	c-17.1,4.6-27.2,22.1-22.6,39.2l65.2,243.4c4.6,17.1,22.1,27.2,39.2,22.6c22.7-6.1,29.4-8.9,42-7.1l107.3,15.6
	c15.3,2.2,30.6,1.3,45.4-2.7l110.5-29.6C417.6,411.6,438.2,349.9,405.5,308.1z M243.6,408.5c-8.5,2.3-17.4,2.8-26.1,1.5l-107.3-15.6
	c-13.1-1.9-26.2-1.1-39,2.3l-6.8,1.8L7.4,186.1l9.9-2.7c15.8-4.2,29-15.2,36.3-30L110.5,37c8.4-17.2,23.7-29.8,41.9-34.7l55-14.7
	c32.1-8.6,45.9,41.5,12.8,50.4l-39.8,10.7c-4.5,1.2-8.2,4.3-10.2,8.4l-4.1,8.3c-3.9,7.9-0.6,17.5,7.3,21.4c3.5,1.7,7.4,2.1,11.2,1.1
	l112.6-30.2c32-8.6,46,41.5,12.8,50.4l-25.8,6.9c-8.5,2.3-13.6,11.1-11.3,19.6l1.8,6.8c2.3,8.5,11.1,13.6,19.6,11.3l39.7-10.6
	c32-8.6,46,41.5,12.8,50.4L307.7,203c-8.5,2.3-13.6,11.1-11.3,19.6l1.8,6.8c2.3,8.5,11.1,13.6,19.6,11.3l26.5-7.1
	c31.9-8.5,46.1,41.5,12.8,50.4l-25.8,6.9c-8.5,2.3-13.6,11.1-11.3,19.6l1.8,6.8c2.3,8.5,11.1,13.6,19.6,11.3
	c32-8.6,45.9,41.5,12.8,50.4L243.6,408.5z"
    />
    <path
      fill={white ? '#f8f8f8' : '#21202cc0'}
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      d="M559.4,425.3L670,454.9c14.9,4,30.2,4.9,45.4,2.7L822.7,442c12.5-1.8,19.2,1,42,7.1c17.1,4.6,34.6-5.6,39.2-22.6l65.2-243.4
	c4.6-17.1-5.6-34.6-22.6-39.2c-26.4-7.1-28.5-6.5-31-11.5L858.6,16c-14.5-29.7-41-51.5-72.6-60l-55-14.7
	c-45.8-12.3-90.8,22.1-92.3,69.5c-54.4-12.5-101.3,38.6-87.4,91.2c-37.6,20.2-50.8,68-28,104.6c-26.5,26.8-29.7,71.1-2.8,101.6
	C487.8,349.9,508.4,411.6,559.4,425.3z M571.8,378.9c-33.1-8.9-19.2-59,12.8-50.4c8.5,2.3,17.3-2.8,19.6-11.3l1.8-6.8
	c2.3-8.5-2.8-17.3-11.3-19.6l-25.8-6.9c-33.3-8.9-19.1-59,12.8-50.4l26.5,7.1c8.5,2.3,17.3-2.8,19.6-11.3l1.8-6.8
	c2.3-8.5-2.8-17.3-11.3-19.6l-39.1-10.5c-33.2-8.9-19.2-59,12.8-50.4l39.7,10.6c8.5,2.3,17.3-2.8,19.6-11.3l1.8-6.8
	c2.3-8.5-2.8-17.3-11.3-19.6l-25.8-6.9c-33.2-8.9-19.2-59,12.8-50.4l112.6,30.2c3.7,1,7.7,0.6,11.2-1.1c7.9-3.9,11.2-13.5,7.3-21.4
	l-4.1-8.3c-2-4.2-5.8-7.2-10.2-8.4L705.8,38c-33-8.9-19.3-59,12.8-50.4l55,14.7c18.2,4.9,33.5,17.5,41.9,34.7l56.9,116.4
	c7.2,14.8,20.5,25.8,36.3,30l9.9,2.7l-56.9,212.5l-6.8-1.8c-12.8-3.4-25.9-4.2-39-2.3l-107.3,15.6c-8.7,1.3-17.6,0.8-26.1-1.5
	L571.8,378.9z"
    />
    <line
      fill="none"
      strokeWidth="30"
      strokeLinecap="round"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      x1="463"
      y1="-61.3"
      x2="463"
      y2="-191.8"
    />
    <line
      fill="none"
      strokeWidth="30"
      strokeLinecap="round"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      x1="533"
      y1="-63.5"
      x2="566.7"
      y2="-189.5"
    />
    <line
      fill="none"
      strokeWidth="30"
      strokeLinecap="round"
      stroke={white ? '#f8f8f8' : '#21202cc0'}
      x1="393"
      y1="-63.5"
      x2="359.3"
      y2="-189.5"
    />
  </svg>
);

RPSIconSvg.defaultProps = defaultProps;
