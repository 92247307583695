import { motion } from 'framer-motion';

const RPSTitle = () => {
  const title = 'Rock Paper Scissors';
  const line = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 1.6,
        staggerChildren: 0.1
      }
    }
  };
  const letter = {
    hidden: {
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };
  const svgAnimation = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2
      }
    }
  };
  const svgVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1
    }
  };
  const svgTransition = {
    ease: 'easeInOut',
    duration: 0.6
  };
  return (
    <>
      <motion.svg
        key="rpsTitleSvg"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-49 -371 1024 1024"
        variants={svgAnimation}
        initial="hidden"
        animate="visible"
        className="titleSvg"
      >
        <motion.path
          fill="#f8f8f8"
          stroke="#f8f8f8"
          d="M405.5,308.1c26.9-30.4,23.7-74.8-2.8-101.6c22.8-36.6,9.5-84.5-28-104.6c13.9-52.5-33-103.7-87.4-91.2
      c-1.5-47.4-46.5-81.8-92.3-69.5L140-44c-31.6,8.5-58.1,30.3-72.6,60L10.5,132.4c-2.5,5.1-4.6,4.4-31,11.5
      c-17.1,4.6-27.2,22.1-22.6,39.2l65.2,243.4c4.6,17.1,22.1,27.2,39.2,22.6c22.7-6.1,29.4-8.9,42-7.1l107.3,15.6
      c15.3,2.2,30.6,1.3,45.4-2.7l110.5-29.6C417.6,411.6,438.2,349.9,405.5,308.1z M243.6,408.5c-8.5,2.3-17.4,2.8-26.1,1.5l-107.3-15.6
      c-13.1-1.9-26.2-1.1-39,2.3l-6.8,1.8L7.4,186.1l9.9-2.7c15.8-4.2,29-15.2,36.3-30L110.5,37c8.4-17.2,23.7-29.8,41.9-34.7l55-14.7
      c32.1-8.6,45.9,41.5,12.8,50.4l-39.8,10.7c-4.5,1.2-8.2,4.3-10.2,8.4l-4.1,8.3c-3.9,7.9-0.6,17.5,7.3,21.4c3.5,1.7,7.4,2.1,11.2,1.1
      l112.6-30.2c32-8.6,46,41.5,12.8,50.4l-25.8,6.9c-8.5,2.3-13.6,11.1-11.3,19.6l1.8,6.8c2.3,8.5,11.1,13.6,19.6,11.3l39.7-10.6
      c32-8.6,46,41.5,12.8,50.4L307.7,203c-8.5,2.3-13.6,11.1-11.3,19.6l1.8,6.8c2.3,8.5,11.1,13.6,19.6,11.3l26.5-7.1
      c31.9-8.5,46.1,41.5,12.8,50.4l-25.8,6.9c-8.5,2.3-13.6,11.1-11.3,19.6l1.8,6.8c2.3,8.5,11.1,13.6,19.6,11.3
      c32-8.6,45.9,41.5,12.8,50.4L243.6,408.5z"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.path
          fill="#f8f8f8"
          stroke="#f8f8f8"
          d="M559.4,425.3L670,454.9c14.9,4,30.2,4.9,45.4,2.7L822.7,442c12.5-1.8,19.2,1,42,7.1c17.1,4.6,34.6-5.6,39.2-22.6l65.2-243.4
	c4.6-17.1-5.6-34.6-22.6-39.2c-26.4-7.1-28.5-6.5-31-11.5L858.6,16c-14.5-29.7-41-51.5-72.6-60l-55-14.7
	c-45.8-12.3-90.8,22.1-92.3,69.5c-54.4-12.5-101.3,38.6-87.4,91.2c-37.6,20.2-50.8,68-28,104.6c-26.5,26.8-29.7,71.1-2.8,101.6
	C487.8,349.9,508.4,411.6,559.4,425.3z M571.8,378.9c-33.1-8.9-19.2-59,12.8-50.4c8.5,2.3,17.3-2.8,19.6-11.3l1.8-6.8
	c2.3-8.5-2.8-17.3-11.3-19.6l-25.8-6.9c-33.3-8.9-19.1-59,12.8-50.4l26.5,7.1c8.5,2.3,17.3-2.8,19.6-11.3l1.8-6.8
	c2.3-8.5-2.8-17.3-11.3-19.6l-39.1-10.5c-33.2-8.9-19.2-59,12.8-50.4l39.7,10.6c8.5,2.3,17.3-2.8,19.6-11.3l1.8-6.8
	c2.3-8.5-2.8-17.3-11.3-19.6l-25.8-6.9c-33.2-8.9-19.2-59,12.8-50.4l112.6,30.2c3.7,1,7.7,0.6,11.2-1.1c7.9-3.9,11.2-13.5,7.3-21.4
	l-4.1-8.3c-2-4.2-5.8-7.2-10.2-8.4L705.8,38c-33-8.9-19.3-59,12.8-50.4l55,14.7c18.2,4.9,33.5,17.5,41.9,34.7l56.9,116.4
	c7.2,14.8,20.5,25.8,36.3,30l9.9,2.7l-56.9,212.5l-6.8-1.8c-12.8-3.4-25.9-4.2-39-2.3l-107.3,15.6c-8.7,1.3-17.6,0.8-26.1-1.5
	L571.8,378.9z"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.line
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="30"
          strokeLinecap="round"
          x1="463"
          y1="-61.3"
          x2="463"
          y2="-191.8"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.line
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="30"
          strokeLinecap="round"
          x1="533"
          y1="-63.5"
          x2="566.7"
          y2="-189.5"
          variants={svgVariants}
          transition={svgTransition}
        />
        <motion.line
          fill="none"
          stroke="#f8f8f8"
          strokeWidth="30"
          strokeLinecap="round"
          x1="393"
          y1="-63.5"
          x2="359.3"
          y2="-189.5"
          variants={svgVariants}
          transition={svgTransition}
        />
      </motion.svg>
      <motion.span
        key="oneTitleText"
        variants={line}
        initial="hidden"
        animate="visible"
      >
        {title.split('').map((char, i) => (
          <motion.span key={i} variants={letter}>
            {char}
          </motion.span>
        ))}
      </motion.span>
    </>
  );
};

export default RPSTitle;
