import { AnimatePresence, motion } from 'framer-motion';
import DiceIcon1 from '../utils/dice/DiceIcon1';
import DiceIcon2 from '../utils/dice/DiceIcon2';
import DiceIcon3 from '../utils/dice/DiceIcon3';
import DiceIcon4 from '../utils/dice/DiceIcon4';
import DiceIcon5 from '../utils/dice/DiceIcon5';
import DiceIcon6 from '../utils/dice/DiceIcon6';

interface IProps {
  diceValue: number;
}

const DBetGameDiceRoll: React.FC<IProps> = ({ diceValue }: IProps) => {
  // variants
  const slideVariants = {
    enter: {
      y: -20,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1
    },
    exit: {
      y: 20,
      opacity: 0
    }
  };

  let diceImg = <DiceIcon1 />;
  if (diceValue === 1) {
    diceImg = <DiceIcon1 />;
  } else if (diceValue === 2) {
    diceImg = <DiceIcon2 />;
  } else if (diceValue === 3) {
    diceImg = <DiceIcon3 />;
  } else if (diceValue === 4) {
    diceImg = <DiceIcon4 />;
  } else if (diceValue === 5) {
    diceImg = <DiceIcon5 />;
  } else if (diceValue === 6) {
    diceImg = <DiceIcon6 />;
  }

  return (
    <AnimatePresence key="dbetGameDiceRollContainer" exitBeforeEnter>
      <motion.div
        key={`dbetGameDiceRollWrapper-${diceValue}`}
        variants={slideVariants}
        initial="enter"
        animate="visible"
        exit="exit"
        transition={{
          duration: 0.3,
          ease: 'easeInOut'
        }}
        className="diceOutlinedIconLarge"
      >
        {diceImg}
      </motion.div>
    </AnimatePresence>
  );
};

export default DBetGameDiceRoll;
