const DiceIcon2 = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-81 141 448 512"
  >
    <path
      fill="none"
      stroke="#94DAFF"
      strokeWidth="40"
      d="M288.7,193H-2.7c-32.2,0-58.3,26.1-58.3,58.3v291.4c0,32.2,26.1,58.3,58.3,58.3h291.4
	c32.2,0,58.3-26.1,58.3-58.3V251.3C347,219.1,321,193,288.7,193z"
    />
    <circle
      fill="#94DAFF"
      stroke="#94DAFF"
      strokeWidth="20"
      cx="55.6"
      cy="309.6"
      r="29.1"
    />
    <circle
      fill="#94DAFF"
      stroke="#94DAFF"
      strokeWidth="20"
      cx="230.4"
      cy="484.4"
      r="29.1"
    />
  </svg>
);

export default DiceIcon2;
