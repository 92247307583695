import chains from '../constants/chains.json';
import supportedChains from '../constants/supportedChains';

const getChainInfo = (id: number | undefined, key: string) => {
  let address: { [key: string]: string } = {};

  if (!id || !supportedChains.includes(id)) {
    address = chains['97'];
  } else if (id === 43113) {
    address = chains['43113'];
  } else {
    address = chains['97'];
  }

  return address[key.toUpperCase()];
};

export default getChainInfo;
