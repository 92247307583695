import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useContract } from '../../../contexts/ContractProvider';
import translate from '../../../shared/functions/translate';
import AddressCopyButton from '../../utils/buttons/AddressCopyButton';
import PlayerIcon from '../../utils/graphics/PlayerIcon';

interface IProps {
  gameData: {
    address: string;
    players: {
      player1: string;
      player2: string;
    };
    entryFee: string;
    winScore: string;
  };
}

const RPSLobbyJoin: React.FC<IProps> = ({ gameData }: IProps) => {
  // states and contexts
  const [joinButtonDisabled, setJoinButtonDisabled] = useState<boolean>(false); // on joining action
  const { account } = useWeb3React<Web3Provider>();
  const { rpsContract } = useContract();
  const history = useHistory();

  // UI functions
  const onJoin = async () => {
    if (account) {
      try {
        setJoinButtonDisabled(true);
        const receipt = await rpsContract?.join(gameData.address);
        await receipt.wait();
        setJoinButtonDisabled(false);
        history.push(`/rpsgame/${gameData.address}`);
      } catch (error: any) {
        setJoinButtonDisabled(false);
        if (error.data) {
          window.alert(error.data.message);
        } else {
          window.alert(error.message);
        }
      }
    }
  };

  return (
    <motion.div
      key={gameData.address}
      variants={{
        open: {
          opacity: 1,
          width: 'auto',
          transition: {
            duration: 1.2,
            ease: 'easeInOut',
            delayChildren: 0.2,
            staggerChildren: 0.2
          }
        },
        collapsed: { opacity: 0, width: '0px' }
      }}
    >
      <div className="rpsLobbyJoinContainer">
        {Object.entries(gameData.players).map(([key, value]) =>
          value === ethers.constants.AddressZero ? (
            <motion.div
              key={`${gameData.address}-rpsLobbyJoinContentJoinButton${key}`}
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 }
              }}
            >
              <button
                type="button"
                className="rpsLobbyJoinButton"
                onClick={() => onJoin()}
                disabled={
                  !account ||
                  Object.values(gameData.players).includes(account) ||
                  joinButtonDisabled
                }
              >
                +
              </button>
            </motion.div>
          ) : (
            <motion.div
              key={`${gameData.address}-rpsLobbyJoinContent${key}`}
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 }
              }}
            >
              <div className="playerWrapper">
                <div
                  className="playerIcon"
                  onClick={() => history.push(`/rpsgame/${gameData.address}`)}
                  style={{ cursor: 'pointer' }}
                >
                  <PlayerIcon active={value === account} />
                </div>
                <AddressCopyButton
                  address={value}
                  active={!!(account && value === account)}
                />
              </div>
            </motion.div>
          )
        )}
        <motion.div
          key={`${gameData.address}-rpsLobbyJoinContentWinScore`}
          variants={{
            open: { opacity: 1 },
            collapsed: { opacity: 0 }
          }}
        >
          <div
            className="rpsLobbyJoinInfoWrapper"
            onClick={() => history.push(`/rpsgame/${gameData.address}`)}
          >
            <span className="rpsLobbyJoinInfoText">
              {translate('winScore')}
            </span>
            <div className="rpsLobbyJoinInfoValueContainer">
              <span className="rpsLobbyJoinInfoValue">{gameData.winScore}</span>
            </div>
          </div>
        </motion.div>
        <motion.div
          key={`${gameData.address}-rpsLobbyJoinContentGameFee`}
          variants={{
            open: { opacity: 1 },
            collapsed: { opacity: 0 }
          }}
        >
          <div
            className="rpsLobbyJoinInfoWrapper"
            onClick={() => history.push(`/rpsgame/${gameData.address}`)}
          >
            <span className="rpsLobbyJoinInfoText">{translate('joinFee')}</span>
            <div className="rpsLobbyJoinInfoValueContainer">
              <span className="rpsLobbyJoinInfoValue">
                {Number(ethers.utils.formatEther(gameData.entryFee)) < 1
                  ? Math.round(
                      Number(ethers.utils.formatEther(gameData.entryFee)) * 100
                    ) / 100
                  : Math.round(
                      Number(ethers.utils.formatEther(gameData.entryFee)) * 10
                    ) / 10}
              </span>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default RPSLobbyJoin;
