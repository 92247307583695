import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { setNotification } from '../../../redux/notificationSlice';
import translate from '../../../shared/functions/translate';
import { IJSONObject, IReduxState } from '../../../shared/interfaces';

interface IProps {
  title: string;
}

const RoomLinkCopyButton: React.FC<IProps> = ({ title }: IProps) => {
  // copy link on title click
  const { isVisible } = useSelector((state: IReduxState) => state.notification);
  const dispatch = useDispatch();
  const location = useLocation();

  // fixed hooks translation
  const translatedText: IJSONObject = {
    roomLinkCopied: translate('roomLinkCopied')
  };

  const handleTitleClick = () => {
    navigator.clipboard.writeText(
      `https://bitcolympics.com${location.pathname}`
    );
    if (!isVisible) {
      dispatch(setNotification(translatedText.roomLinkCopied));
    }
  };

  return (
    <span onClick={handleTitleClick} style={{ cursor: 'pointer' }}>
      {title}
    </span>
  );
};

export default RoomLinkCopyButton;
