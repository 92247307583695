import { motion } from 'framer-motion';
import { useRef } from 'react';
import RPSInfoCard from '../../components/rps/info/RPSInfoCard';
import RPSLobbyCreate from '../../components/rps/lobby/RPSLobbyCreate';
import RPSLobbyJoinMenu from '../../components/rps/lobby/RPSLobbyJoinMenu';
import RPSTitle from '../../components/utils/graphics/RPSTitle';

const RPSLobby = () => {
  const pageRef = useRef<number>(1);
  const allowPageChange = useRef<boolean>(true);
  const forcePageRefresh = useRef<boolean>(false);
  const createGameBlock = useRef<number>(0);

  return (
    <motion.div
      key="RPSLobby"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="card">
        <div className="cardTitle">
          <RPSTitle />
        </div>
        <div className="innerCard">
          <RPSLobbyCreate
            forcePageRefresh={forcePageRefresh}
            createGameBlock={createGameBlock}
            currentPage={pageRef}
          />
        </div>
        <RPSLobbyJoinMenu
          currentPage={pageRef}
          allowPageChange={allowPageChange}
          forcePageRefresh={forcePageRefresh}
          createGameBlock={createGameBlock}
        />
      </div>
      <RPSInfoCard />
    </motion.div>
  );
};

export default RPSLobby;
