import { ethers } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../redux/notificationSlice';
import translate from '../../../shared/functions/translate';
import { IJSONObject, IReduxState } from '../../../shared/interfaces';

interface IProps {
  address?: string;
  active?: boolean;
  large?: boolean;
  long?: boolean;
}

const defaultProps = {
  address: ethers.constants.AddressZero,
  active: false,
  large: false,
  long: false
};

// shorten the name of the player
const shorter = (str: string, long: boolean | undefined) =>
  long
    ? str?.length > 10
      ? `${str.slice(0, 5)}...${str.slice(-4)}`
      : str
    : str?.length > 8
    ? `${str.slice(0, 4)}...${str.slice(-3)}`
    : str;

const AddressCopyButton: React.FC<IProps> = ({
  address,
  active,
  large,
  long
}: IProps) => {
  // redux
  const { isVisible } = useSelector((state: IReduxState) => state.notification);
  const dispatch = useDispatch();

  // fixed hooks translation
  const translatedText: IJSONObject = {
    copiedToClipboard: translate('copiedToClipboard')
  };

  const handleClick = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      if (!isVisible) {
        dispatch(setNotification(translatedText.copiedToClipboard));
      }
    }
  };

  return (
    <div
      onClick={handleClick}
      className="addressCopyButtonContainer"
      style={{
        color: active ? '#f8f8f8' : '#d3d3d3',
        fontSize: large ? '14px' : '8px'
      }}
    >
      {address && address !== ethers.constants.AddressZero
        ? shorter(address, long)
        : '-'}
    </div>
  );
};

AddressCopyButton.defaultProps = defaultProps;
export default AddressCopyButton;
