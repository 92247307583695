import { motion } from 'framer-motion';

const DBetTitle = () => {
  const title = 'Dice Bet';
  const line = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 2,
        staggerChildren: 0.1
      }
    }
  };
  const letter = {
    hidden: {
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };
  const svgAnimation = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2
      }
    }
  };
  const svgVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1
    }
  };
  const svgTransition = {
    ease: 'easeInOut',
    duration: 0.6
  };
  return (
    <>
      <motion.svg
        key="dbetTitleSvg"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-5 -5 101 101"
        variants={svgAnimation}
        initial="hidden"
        animate="visible"
        className="titleSvg"
      >
        <motion.path
          fill="#f8f8f8"
          variants={svgVariants}
          transition={svgTransition}
          d="M84.479,88.236c0-1.387-1.125-2.512-2.514-2.512H60.22c-0.129-1.805-0.729-3.244-1.787-4.285      c-2.068-2.033-5.154-1.98-8.418-1.926c-0.555,0.01-1.125,0.02-1.709,0.02h-4.771c-0.584,0-1.154-0.01-1.709-0.02      c-3.266-0.053-6.35-0.107-8.418,1.924c-1.059,1.043-1.658,2.482-1.787,4.287H9.87c-1.387,0-2.512,1.125-2.512,2.512      c0,1.389,1.125,2.514,2.512,2.514h72.096C83.354,90.75,84.479,89.625,84.479,88.236z M36.931,85.021      c0.566-0.557,2.916-0.516,4.809-0.484c0.582,0.01,1.182,0.021,1.795,0.021h4.771c0.613,0,1.213-0.012,1.795-0.021      c1.893-0.031,4.242-0.072,4.809,0.484c0.117,0.115,0.201,0.371,0.254,0.703H36.677C36.729,85.393,36.813,85.137,36.931,85.021z"
        />
        <motion.path
          fill="#f8f8f8"
          variants={svgVariants}
          transition={svgTransition}
          d="M73.726,39.836V14.258c0-0.992-0.586-1.893-1.494-2.297L46.942,0.742c-0.65-0.287-1.391-0.287-2.039,0L19.604,11.961      c-0.908,0.404-1.494,1.305-1.494,2.297v25.578c0,0.947,0.533,1.814,1.381,2.242L44.79,54.867c0.355,0.18,0.744,0.27,1.133,0.27      s0.777-0.09,1.133-0.27l25.291-12.789C73.192,41.65,73.726,40.783,73.726,39.836z M45.923,5.787l19.486,8.645L45.923,24.504      L26.431,14.432L45.923,5.787z M23.136,18.387l20.273,10.475v19.678L23.136,38.291V18.387z M48.435,48.539V28.861L68.7,18.389      v19.902L48.435,48.539z"
        />
        <motion.path
          fill="#f8f8f8"
          variants={svgVariants}
          transition={svgTransition}
          d="M41.569,30.344c-0.543-1.277-2.018-1.869-3.295-1.33L26.302,34.1c-1.277,0.543-1.871,2.018-1.33,3.295      c0.408,0.957,1.336,1.531,2.314,1.531c0.328,0,0.66-0.064,0.98-0.201l11.973-5.086C41.517,33.096,42.112,31.621,41.569,30.344z"
        />
        <motion.path
          fill="#f8f8f8"
          variants={svgVariants}
          transition={svgTransition}
          d="M65.534,34.1l-11.969-5.086c-1.273-0.539-2.75,0.053-3.295,1.33c-0.543,1.275,0.053,2.752,1.33,3.295l11.969,5.086      c0.32,0.137,0.654,0.201,0.982,0.201c0.977,0,1.906-0.574,2.313-1.531C67.407,36.119,66.812,34.643,65.534,34.1z"
        />
        <motion.g variants={svgVariants} transition={svgTransition}>
          <motion.path
            fill="#f8f8f8"
            d="M48.362,58.869c0-1.387-1.125-2.512-2.512-2.512c-1.389,0-2.514,1.125-2.514,2.512v0.166      c0,1.389,1.125,2.514,2.514,2.514c1.387,0,2.512-1.125,2.512-2.514V58.869z"
          />
          <motion.path
            fill="#f8f8f8"
            d="M48.362,67.355c0-1.389-1.125-2.514-2.512-2.514c-1.389,0-2.514,1.125-2.514,2.514v0.166      c0,1.387,1.125,2.512,2.514,2.512c1.387,0,2.512-1.125,2.512-2.512V67.355z"
          />
          <motion.path
            fill="#f8f8f8"
            d="M45.851,73.328c-1.389,0-2.514,1.125-2.514,2.512v0.166c0,1.389,1.125,2.514,2.514,2.514      c1.387,0,2.512-1.125,2.512-2.514V75.84C48.362,74.453,47.237,73.328,45.851,73.328z"
          />
        </motion.g>
        <motion.g variants={svgVariants} transition={svgTransition}>
          <motion.path
            fill="#f8f8f8"
            d="M61.466,56.592l-0.105,0.129c-0.879,1.074-0.721,2.656,0.354,3.535c0.467,0.381,1.031,0.568,1.59,0.568      c0.729,0,1.449-0.314,1.945-0.922l0.105-0.129c0.879-1.074,0.721-2.656-0.354-3.535C63.929,55.359,62.345,55.516,61.466,56.592z      "
          />
          <motion.path
            fill="#f8f8f8"
            d="M50.714,69.719l-0.105,0.129c-0.879,1.074-0.721,2.656,0.354,3.535c0.467,0.383,1.031,0.568,1.59,0.568      c0.727,0,1.447-0.314,1.945-0.922l0.105-0.129c0.879-1.074,0.721-2.656-0.354-3.535S51.593,68.645,50.714,69.719z"
          />
          <motion.path
            fill="#f8f8f8"
            d="M56.097,63.148l-0.105,0.127c-0.883,1.072-0.73,2.654,0.34,3.537c0.467,0.387,1.033,0.574,1.596,0.574      c0.725,0,1.443-0.311,1.941-0.914l0.105-0.127c0.883-1.072,0.73-2.654-0.342-3.537C58.562,61.926,56.978,62.078,56.097,63.148z"
          />
          <motion.path
            fill="#f8f8f8"
            d="M66.843,50.027l-0.105,0.129c-0.879,1.074-0.721,2.656,0.354,3.535c0.467,0.381,1.031,0.568,1.59,0.568      c0.729,0,1.449-0.314,1.945-0.922l0.105-0.129c0.879-1.074,0.721-2.656-0.354-3.535S67.722,48.951,66.843,50.027z"
          />
        </motion.g>
        <motion.g variants={svgVariants} transition={svgTransition}>
          <motion.path
            fill="#f8f8f8"
            d="M26.501,56.283c-1.068,0.885-1.217,2.469-0.332,3.537l0.105,0.129c0.498,0.6,1.215,0.91,1.938,0.91      c0.564,0,1.131-0.189,1.6-0.578c1.07-0.885,1.219-2.469,0.334-3.537l-0.107-0.129C29.153,55.547,27.569,55.398,26.501,56.283z"
          />
          <motion.path
            fill="#f8f8f8"
            d="M21.11,49.73c-1.072,0.881-1.227,2.465-0.346,3.537l0.105,0.129c0.496,0.604,1.217,0.916,1.943,0.916      c0.561,0,1.125-0.188,1.594-0.57c1.072-0.883,1.227-2.465,0.346-3.537l-0.107-0.129C23.765,49.004,22.181,48.852,21.11,49.73z"
          />
          <motion.path
            fill="#f8f8f8"
            d="M31.907,62.822c-1.072,0.883-1.227,2.465-0.346,3.537l0.105,0.129c0.496,0.605,1.217,0.918,1.943,0.918      c0.561,0,1.125-0.188,1.594-0.572c1.072-0.881,1.227-2.465,0.346-3.537l-0.107-0.129C34.562,62.096,32.979,61.943,31.907,62.822      z"
          />
          <motion.path
            fill="#f8f8f8"
            d="M37.298,69.377c-1.068,0.885-1.217,2.469-0.332,3.537l0.105,0.127c0.498,0.6,1.215,0.91,1.938,0.91      c0.564,0,1.131-0.189,1.6-0.578c1.07-0.885,1.219-2.469,0.334-3.537l-0.107-0.127C39.95,68.641,38.366,68.49,37.298,69.377z"
          />
        </motion.g>
      </motion.svg>
      <motion.span
        key="dbetTitleText"
        variants={line}
        initial="hidden"
        animate="visible"
      >
        {title.split('').map((char, i) => (
          <motion.span key={i} variants={letter}>
            {char}
          </motion.span>
        ))}
      </motion.span>
    </>
  );
};

export default DBetTitle;
