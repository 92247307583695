import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import DiceIcon1 from '../utils/dice/DiceIcon1';
import DiceIcon2 from '../utils/dice/DiceIcon2';
import DiceIcon3 from '../utils/dice/DiceIcon3';
import DiceIcon4 from '../utils/dice/DiceIcon4';
import DiceIcon5 from '../utils/dice/DiceIcon5';
import DiceIcon6 from '../utils/dice/DiceIcon6';

interface IProps {
  selectedDice: number;
  setSelectedDice: React.Dispatch<React.SetStateAction<number>>;
}

const DBetGameDiceDropdown: React.FC<IProps> = ({
  selectedDice,
  setSelectedDice
}: IProps) => {
  const [expanded, setExpanded] = useState(false);

  const dices = [
    { key: 1, icon: <DiceIcon1 /> },
    { key: 2, icon: <DiceIcon2 /> },
    { key: 3, icon: <DiceIcon3 /> },
    { key: 4, icon: <DiceIcon4 /> },
    { key: 5, icon: <DiceIcon5 /> },
    { key: 6, icon: <DiceIcon6 /> }
  ];

  return (
    <motion.div
      className="dbetGameDiceDropdownContainer"
      key="dbetGameDiceDropdownContainer"
      initial="collapsed"
      animate={expanded ? 'open' : 'collapsed'}
      exit="collapsed"
      variants={{
        open: {
          height: `403px`
        },
        collapsed: {
          height: '57px'
        }
      }}
      transition={{ duration: 0.6, ease: 'easeInOut' }}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={`dbetGameDiceDropdown-${selectedDice}`}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className="dbetGameDiceDropdown"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="dbetGameDiceDropdownIcon">
            {dices[selectedDice - 1].icon}
          </div>
        </motion.div>
      </AnimatePresence>
      {expanded && (
        <motion.div
          className="dbetGameDiceDropdownList"
          key="dbetGameDiceDropdownList"
          variants={{
            collapsed: { opacity: 0, height: 0 },
            open: {
              opacity: 1,
              height: '398px',
              transition: {
                delayChildren: 0.1,
                staggerChildren: 0.1
              }
            }
          }}
          initial="collapsed"
          animate="open"
          exit="collapsed"
        >
          {dices.map((dice) => (
            <motion.div
              variants={{
                open: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.3,
                    ease: 'easeInOut'
                  }
                },
                collapsed: {
                  opacity: 0,
                  x: 10
                }
              }}
              key={`dbetGameDiceDropdownIcon-${dice.key}`}
              className="dbetGameDiceDropdownIcon"
              onClick={() => {
                setSelectedDice(dice.key);
                setExpanded(false);
              }}
            >
              {dice.icon}
            </motion.div>
          ))}
        </motion.div>
      )}
    </motion.div>
  );
};

export default DBetGameDiceDropdown;
