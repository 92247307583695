import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILanguageState } from '../shared/interfaces';

const initialState: ILanguageState = {
  name: 'en'
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    }
  }
});

export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;
