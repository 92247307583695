import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationState } from '../shared/interfaces';

const initialState: INotificationState = {
  isVisible: false,
  message: ''
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<string>) => {
      state.isVisible = true;
      state.message = action.payload;
    },
    clearNotification: (state) => {
      state.isVisible = false;
      state.message = '';
    }
  }
});

export const { setNotification, clearNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
