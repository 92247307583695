import walletIcon from '../../shared/constants/icons/wallet_icon.png';

interface IProps {
  onClick: () => void;
}

const NavbarMenuButton: React.FC<IProps> = ({ onClick }: IProps) => (
  <button type="button" onClick={onClick} className="navbarMenuButton">
    <img src={walletIcon} alt="walletIcon" className="navbarMenuButtonIcon" />
  </button>
);

export default NavbarMenuButton;
