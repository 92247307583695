import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import PlayerIcon from '../../utils/graphics/PlayerIcon';
import RPSGameTotalPointsText from './RPSGameTotalPointsText';
import RPSGameTotalPointsRecent from './RPSGameTotalPointsRecent';
import RPSGameTotalPointsScreen from './RPSGameTotalPointsScreen';
import AddressCopyButton from '../../utils/buttons/AddressCopyButton';
import translate from '../../../shared/functions/translate';

interface IProps {
  players: string[];
  scores: number[];
  selections: number[];
  winScore: number;
}

const RPSGameTotalPointsRight: React.FC<IProps> = ({
  players,
  scores,
  selections,
  winScore
}: IProps) => {
  // states and contexts
  const [winState, setWinState] = useState<string>('draw');
  const { account } = useWeb3React<Web3Provider>();

  // functions
  const calculateWinState = () => {
    if (scores[1] === scores[0]) {
      setWinState('draw');
    } else if (scores[1] > scores[0]) {
      setWinState('win');
    } else {
      setWinState('lose');
    }
  };

  useEffect(() => {
    calculateWinState();
  }, [players, scores]);

  return (
    <div className="rpsGameTotalPointsWrapper">
      <div className="rpsGameTotalPointsPlayerContainer">
        <div className="rpsGameTotalPointsTextContainer">
          <span className="rpsGameTotalPointsHeader">{translate('score')}</span>
          <RPSGameTotalPointsText totalPointsInput={scores[1]} />
        </div>
        <div className="playerWrapper">
          <div className="playerIcon">
            <PlayerIcon active={!!(account && players[1] === account)} />
          </div>
          <AddressCopyButton
            address={players[1]}
            active={!!(account && players[1] === account)}
          />
        </div>
        <RPSGameTotalPointsRecent
          positionIndex={1}
          selections={selections}
          players={players}
        />
      </div>
      <RPSGameTotalPointsScreen
        totalPointsInput={scores[1]}
        winState={winState}
        winScore={winScore}
      />
    </div>
  );
};

export default RPSGameTotalPointsRight;
