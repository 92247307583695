import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import infoIcon from '../../shared/constants/icons/info_icon.png';
import arrowUpIcon from '../../shared/constants/icons/arrow_up_icon.png';
import arrowDownIcon from '../../shared/constants/icons/arrow_down_icon.png';
import { IJSONObject } from '../../shared/interfaces';
import translate from '../../shared/functions/translate';

const DBetGameInfoCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  // fixed hooks translation
  const translatedText: IJSONObject = {
    gameInfo: translate('gameInfo'),
    howToPlay: translate('howToPlay'),
    dbetHowToPlay1: translate('dbetHowToPlay1'),
    dbetHowToPlay2: translate('dbetHowToPlay2'),
    dbetHowToPlay3: translate('dbetHowToPlay3'),
    dbetHowToPlay4: translate('dbetHowToPlay4'),
    dbetHowToPlay5: translate('dbetHowToPlay5'),
    dbetHowToPlay6: translate('dbetHowToPlay6'),
    dbetHowToPlay7: translate('dbetHowToPlay7'),
    dbetHowToPlay8: translate('dbetHowToPlay8'),
    rules: translate('rules'),
    dbetRules1: translate('dbetRules1'),
    dbetRules2: translate('dbetRules2'),
    dbetRules3: translate('dbetRules3'),
    dbetRules4: translate('dbetRules4'),
    dbetRules5: translate('dbetRules5'),
    dbetRules6: translate('dbetRules6'),
    dbetRules7: translate('dbetRules7'),
    dbetRules8: translate('dbetRules8')
  };

  return (
    <div className="card">
      <div className="cardTitle">
        <img src={infoIcon} alt="infoIcon" className="gameInfoIcon" />
        {translatedText.gameInfo}
      </div>
      <AnimatePresence key="gameInfoButton" exitBeforeEnter>
        {!isOpen ? (
          <motion.button
            key="arrowDownButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowDownIcon}
              alt="arrowDownIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        ) : (
          <motion.button
            key="arrowUpButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowUpIcon}
              alt="arrowUpIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        )}
      </AnimatePresence>
      <motion.div
        key="gameInfoCard"
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: '685px',
            transition: {
              delayChildren: 0.6,
              staggerChildren: 0.6,
              duration: 1.2,
              ease: 'easeInOut'
            }
          },
          collapsed: {
            height: '0px'
          }
        }}
        transition={{ duration: 1.2, ease: 'easeInOut' }}
      >
        {isOpen && (
          <>
            <motion.div
              key="gameInfoHowToPlay"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="innerCard"
            >
              <div className="innerCardTitle">{translatedText.howToPlay}</div>
              <ul className="gameInfoCardBody">
                <li>
                  <p>
                    {translatedText.dbetHowToPlay1}
                    <a href="https://metamask.io/">MetaMask</a>
                    {translatedText.dbetHowToPlay2}
                  </p>
                </li>
                <li>
                  <p>{translatedText.dbetHowToPlay3}</p>
                </li>
                <li>
                  <p>{translatedText.dbetHowToPlay4}</p>
                </li>
                <li>
                  <p>{translatedText.dbetHowToPlay5}</p>
                </li>
                <li>
                  <p>{translatedText.dbetHowToPlay6}</p>
                </li>
                <li>
                  <p>{translatedText.dbetHowToPlay7}</p>
                </li>
                <li>
                  <p>{translatedText.dbetHowToPlay8}</p>
                </li>
              </ul>
            </motion.div>
            <motion.div
              key="gameInfoRules"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="innerCard"
            >
              <div className="innerCardTitle">{translatedText.rules}</div>
              <ul className="gameInfoCardBody">
                <li>
                  <p>{translatedText.dbetRules1}</p>
                </li>
                <li>
                  <p>{translatedText.dbetRules2}</p>
                </li>
                <li>
                  <p>{translatedText.dbetRules3}</p>
                </li>
                <li>
                  <p>{translatedText.dbetRules4}</p>
                </li>
                <li>
                  <p>{translatedText.dbetRules5}</p>
                </li>
                <li>
                  <p>{translatedText.dbetRules6}</p>
                </li>
                <li>
                  <p>{translatedText.dbetRules7}</p>
                </li>
                <li>
                  <p>{translatedText.dbetRules8}</p>
                </li>
              </ul>
            </motion.div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default DBetGameInfoCard;
