interface IProps {
  positionIndex?: number;
  color?: string;
}

const defaultProps = {
  positionIndex: 0,
  color: '#f8f8f8'
};

const RPSGameScissorsIcon: React.FC<IProps> = ({
  positionIndex,
  color
}: IProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-49 141 512 512"
  >
    {positionIndex === 0 ? (
      <path
        fill={color}
        stroke={color}
        d="M271,535.1c21.6-13.7,34-38.8,30.3-65H389c40.8,0,74-32.8,74-73.1c0-40.3-33.2-73.1-74-73.1l-94,9.1l78.9-18.7
	c38.3-14.7,57-57.5,41.9-95.1c-15-37.5-57.8-55.8-95.6-41.2l-144.9,55.8c-25-30.6-57.8-44-92.2-24.7l-98,54.9
	c-21,11.7-34,33.8-34,57.7v205.7c0,30.7,21.4,57.6,51.8,64.6l119,27.4c5,1.1,10.1,1.7,15.2,1.7l70,0C252,621,284.3,577.7,271,535.1z
	 M207,573l-70,0c-1.5,0-3-0.2-4.4-0.5l-119-27.4C5,543.1-1,535.8-1,527.3V321.6c0-6.5,3.6-12.5,9.4-15.8l98-54.9
	c8.1-4.6,18.7-2.6,24.5,4.5l21.8,26.6c4.4,5.4,11.7,7.3,18.1,4.8l166.5-64.1c32.7-12.6,50.8,34.6,19.2,46.7l-155.4,59.8
	c-6.2,2.4-10.3,8.3-10.3,14.9v11.6c0,8.8,7.2,16,16,16h182c34.4,0,34.4,50.3,0,50.3H207c-8.8,0-16,7.2-16,16v6.9c0,8.8,7.2,16,16,16
	h28c25.1,0,25.2,36.6,0,36.6h-28c-8.8,0-16,7.2-16,16v6.9c0,8.8,7.2,16,16,16C232.1,536.4,232.2,573,207,573L207,573z"
      />
    ) : (
      <path
        fill={color}
        stroke={color}
        d="M207,621l70,0c5.1,0,10.2-0.6,15.2-1.7l119-27.4c30.4-7,51.8-33.8,51.8-64.6V321.6c0-23.8-13-46-34-57.7L331,209
	c-34.4-19.3-67.3-5.8-92.2,24.7L93.9,178c-37.9-14.6-80.6,3.7-95.6,41.2c-15.1,37.6,3.6,80.4,41.9,95.1L119,333l-94-9.1
	c-40.8,0-74,32.8-74,73.1c0,40.3,33.2,73.1,74,73.1h87.6c-3.7,26.2,8.7,51.3,30.3,65C129.7,577.7,162,621,207,621z M207,573
	c-25.2,0-25.1-36.6,0-36.6c8.8,0,16-7.2,16-16v-6.9c0-8.8-7.2-16-16-16h-28c-25.2,0-25.1-36.6,0-36.6h28c8.8,0,16-7.2,16-16v-6.9
	c0-8.8-7.2-16-16-16H25c-34.4,0-34.4-50.3,0-50.3h182c8.8,0,16-7.2,16-16v-11.6c0-6.6-4.1-12.6-10.3-14.9L57.4,269.5
	c-31.6-12.1-13.4-59.3,19.2-46.7l166.5,64.1c6.5,2.5,13.8,0.5,18.1-4.8l21.8-26.6c5.8-7.1,16.3-9.1,24.5-4.5l98,54.9
	c5.8,3.3,9.4,9.3,9.4,15.8v205.7c0,8.5-6,15.8-14.6,17.8l-119,27.4c-1.5,0.3-2.9,0.5-4.4,0.5L207,573L207,573z"
      />
    )}
  </svg>
);

RPSGameScissorsIcon.defaultProps = defaultProps;
export default RPSGameScissorsIcon;
