import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrencyType } from '../shared/enums';
import { ICoinState } from '../shared/interfaces';

const initialState: ICoinState = {
  id: '0x4b9b437db6596ded472bd778407c1d426bf8e89425ee60f2a55ef766a0cc30e9',
  address: '0xb783e8429A71ED2413806b5A1f4Cff1132D1638e',
  tokenId: 0,
  name: 'BITCO',
  decimal: 18,
  type: CurrencyType.ERC20,
  logo: '/icons/babi_icon.png'
};

const coinSlice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    changeCoin: (state, action: PayloadAction<ICoinState>) => {
      state.id = action.payload.id;
      state.address = action.payload.address;
      state.tokenId = action.payload.tokenId;
      state.name = action.payload.name;
      state.decimal = action.payload.decimal;
      state.type = action.payload.type;
      state.logo = action.payload.logo;
    }
  }
});

export const { changeCoin } = coinSlice.actions;
export default coinSlice.reducer;
