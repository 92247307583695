import { useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { AnimatePresence, motion } from 'framer-motion';
import RPSGamePaperIcon from './icons/RPSGamePaperIcon';
import RPSGameRockIcon from './icons/RPSGameRockIcon';
import RPSGameScissorsIcon from './icons/RPSGameScissorsIcon';
import { IJSONObject } from '../../../shared/interfaces';
import translate from '../../../shared/functions/translate';

interface IProps {
  positionIndex: number;
  selections: number[];
  players: string[];
}

const RPSGameTotalPointsRecent: React.FC<IProps> = ({
  positionIndex,
  selections,
  players
}: IProps) => {
  // states
  const [color, setColor] = useState<string>();
  const { account } = useWeb3React<Web3Provider>();

  // effects
  useEffect(() => {
    if (
      !selections.includes(0) &&
      (selections[positionIndex] - 1) % 3 ===
        selections[(positionIndex + 1) % 2] % 3
    ) {
      setColor('#5e8f46');
    } else if (
      !selections.includes(0) &&
      (selections[positionIndex] + 1) % 3 ===
        selections[(positionIndex + 1) % 2] % 3
    ) {
      setColor('#b1423a');
    } else if (account && players[positionIndex] === account) {
      setColor('#f8f8f8');
    } else {
      setColor('#d3d3d3');
    }
  }, [selections, positionIndex, players, account]);

  // variants
  const animateVariants = {
    enter: {
      opacity: 0
    },
    visible: {
      opacity: 1
    },
    exit: {
      opacity: 0
    }
  };

  // fixed hooks translation
  const translatedText: IJSONObject = {
    rock: translate('rock'),
    paper: translate('paper'),
    scissors: translate('scissors')
  };

  return (
    <AnimatePresence key="rpsGameTotalPointsRecentText" exitBeforeEnter>
      <motion.div
        key={`rpsGameTotalPointsRecentValue-${positionIndex}`}
        variants={animateVariants}
        initial="enter"
        animate="visible"
        exit="exit"
        transition={{
          duration: 0.5,
          ease: 'easeInOut'
        }}
        className="rpsGameTotalPointsRecentWrapper"
      >
        {selections[positionIndex] !== 0 &&
          (selections[positionIndex] === 1 ? (
            <div className="rpsGameTotalPointsRecentWrapper">
              <div className="rpsGameUIBottomIcon">
                <RPSGameRockIcon positionIndex={positionIndex} color={color} />
              </div>
              <div className="rpsGameUIBottomText" style={{ color }}>
                {translatedText.rock}
              </div>
            </div>
          ) : selections[positionIndex] === 2 ? (
            <div className="rpsGameTotalPointsRecentWrapper">
              <div className="rpsGameUIBottomIcon">
                <RPSGamePaperIcon positionIndex={positionIndex} color={color} />
              </div>
              <div className="rpsGameUIBottomText" style={{ color }}>
                {translatedText.paper}
              </div>
            </div>
          ) : selections[positionIndex] === 3 ? (
            <div className="rpsGameTotalPointsRecentWrapper">
              <div className="rpsGameUIBottomIcon">
                <RPSGameScissorsIcon
                  positionIndex={positionIndex}
                  color={color}
                />
              </div>
              <div className="rpsGameUIBottomText" style={{ color }}>
                {translatedText.scissors}
              </div>
            </div>
          ) : (
            ''
          ))}
      </motion.div>
    </AnimatePresence>
  );
};

export default RPSGameTotalPointsRecent;
