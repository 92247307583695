import { useSelector } from 'react-redux';
import en from '../constants/languages/en.json';
import tr from '../constants/languages/tr.json';
import zh from '../constants/languages/zh.json';
import de from '../constants/languages/de.json';
import { IReduxState } from '../interfaces';

const translate = (key: string) => {
  const languageData = useSelector((state: IReduxState) => state.language.name);

  let langData: { [key: string]: string } = {};

  if (languageData === 'zh') {
    langData = zh;
  } else if (languageData === 'de') {
    langData = de;
  } else if (languageData === 'tr') {
    langData = tr;
  } else {
    langData = en;
  }
  return langData[key];
};

export default translate;
