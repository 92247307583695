import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useContract } from '../../contexts/ContractProvider';
import getAddress from '../../shared/functions/getAddress';
import getChainInfo from '../../shared/functions/getChainInfo';
import translate from '../../shared/functions/translate';
import { IJSONObject, IReduxState } from '../../shared/interfaces';

interface IDbetEventLog {
  time: number;
  text: string;
}

interface IDbetEventDict {
  [key: number]: string;
}

interface IDbetEvent {
  blockNumber: number;
  gameId: string;
  player: string;
  amount: number;
  value: number;
  log: number;
}

const shorter = (str: string) =>
  str?.length > 8 ? `${str.slice(0, 5)}...${str.slice(-4)}` : str;

const DBetGameEventsLog = () => {
  // states
  const [dbetEventsLog, setDbetEventsLog] = useState<IDbetEventLog[]>([]);
  const isSubscribed = useRef<boolean>(false); // memory leak fix

  // context and provider
  const { chainId } = useWeb3React<Web3Provider>();
  const { dbetContract } = useContract();

  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  const location = useLocation();
  const path = location.pathname.split('/')[2];

  // fixed hooks translation
  const translatedText: IJSONObject = {
    dbetEvents0: translate('dbetEvents0'),
    dbetEvents1: translate('dbetEvents1'),
    dbetEvents2: translate('dbetEvents2')
  };

  // log query listener
  const listener = async (currentBlock: number) => {
    if (!dbetContract) {
      return;
    }
    const event = dbetContract.filters.GameLog();
    const fetchedGameId = await dbetContract.getGameId(path, coinData.id);
    const allEvents = await dbetContract.queryFilter(
      event,
      currentBlock - Number(getChainInfo(chainId, 'event-range')),
      currentBlock
    );

    const events = allEvents.map((el: ethers.Event) => ({
      blockNumber: el.blockNumber,
      gameId: el.args && el.args[0],
      player: el.args && el.args[1],
      amount: el.args && (Number(ethers.utils.formatEther(el.args[2])) as any),
      value: el.args && el.args[3].toNumber(),
      log: el.args && el.args[4].toNumber()
    }));

    const dict: IDbetEventDict = {
      0: translatedText.dbetEvents0,
      1: translatedText.dbetEvents1,
      2: translatedText.dbetEvents2
    };

    const parsedEvents = events
      .filter((el: IDbetEvent) => el.gameId === fetchedGameId)
      .map((el: IDbetEvent) => {
        let text = '';
        const { player, amount, value } = el;
        if (el.log === 0) {
          text = dict[el.log]
            .replace(
              '{0}',
              player === ethers.constants.AddressZero ? '' : shorter(player)
            )
            .replace('{1}', amount.toString())
            .replace('{2}', coinData.name)
            .replace('{3}', value.toString());
        } else if (el.log === 1) {
          text = dict[Number(el.log)].replace(
            '{0}',
            player === ethers.constants.AddressZero ? '' : shorter(player)
          );
        } else {
          text = dict[Number(el.log)]
            .replace(
              '{0}',
              player === ethers.constants.AddressZero ? '' : shorter(player)
            )
            .replace('{1}', amount.toString())
            .replace('{2}', coinData.name);
        }
        return { time: el.blockNumber, text };
      });
    if (isSubscribed.current) {
      setDbetEventsLog(parsedEvents);
    }
  };

  // lifecycle hooks
  useEffect(() => {
    isSubscribed.current = true;
    const dbetEventsLogJsonRpcProvider = new ethers.providers.JsonRpcProvider(
      getAddress(chainId, 'provider')
    );
    if (dbetContract) {
      dbetEventsLogJsonRpcProvider.on('block', (blockNumber: number) => {
        if (isSubscribed.current) listener(blockNumber);
      });
    }
    return () => {
      isSubscribed.current = false;
      dbetEventsLogJsonRpcProvider.off('block');
    };
  }, [dbetContract]);

  return dbetEventsLog.length === 0 ? (
    <div className="eventsLogTextDisabled">No events received yet...</div>
  ) : (
    <div className="eventsLogContainer">
      <ul className="eventsLogList">
        {dbetEventsLog.map((event, index) => (
          <li key={index}>
            <span>{`#${event.time}: `}</span>
            <span>{event.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DBetGameEventsLog;
