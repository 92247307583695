import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Contract, ethers } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import getAddress from '../shared/functions/getAddress';
import { IReduxState } from '../shared/interfaces';
import erc20ABI from '../shared/contracts/ABIs/erc20';
import erc1155ABI from '../shared/contracts/ABIs/erc1155';
import walletABI from '../shared/contracts/ABIs/wallet';
import rpsABI from '../shared/contracts/ABIs/rps';
import oneABI from '../shared/contracts/ABIs/one';
// import djackABI from '../shared/contracts/ABIs/djack';
import dbetABI from '../shared/contracts/ABIs/dbet';
import supportedChains from '../shared/constants/supportedChains';

interface IProps {
  children: React.ReactNode;
}

interface IContractContext {
  jsonRpcProvider: ethers.providers.JsonRpcProvider | undefined;
  erc20Contract: Contract | undefined;
  erc1155Contract: Contract | undefined;
  walletContract: Contract | undefined;
  rpsContract: Contract | undefined;
  oneContract: Contract | undefined;
  djackContract: Contract | undefined;
  dbetContract: Contract | undefined;
  tncContract: Contract | undefined;
}

const ContractContext = createContext<IContractContext>({
  jsonRpcProvider: undefined,
  erc20Contract: undefined,
  erc1155Contract: undefined,
  walletContract: undefined,
  rpsContract: undefined,
  oneContract: undefined,
  djackContract: undefined,
  dbetContract: undefined,
  tncContract: undefined
});

const ContractProvider = ({ children }: IProps) => {
  const [jsonRpcProvider, setJsonRpcProvider] =
    useState<ethers.providers.JsonRpcProvider>();
  const [erc20Contract, setErc20Contract] = useState<Contract>();
  const [erc1155Contract, setErc1155Contract] = useState<Contract>();
  const [walletContract, setWalletContract] = useState<Contract>();
  const [rpsContract, setRpsContract] = useState<Contract>();
  const [oneContract, setOneContract] = useState<Contract>();
  const [djackContract, setDjackContract] = useState<Contract>();
  const [dbetContract, setDbetContract] = useState<Contract>();
  const [tncContract, setTncContract] = useState<Contract>();

  // web3 provider
  const { library, chainId, account } = useWeb3React<Web3Provider>();

  // redux
  const coinAddress = useSelector((state: IReduxState) => state.coin.address);

  // JSON RPC Provider
  useEffect(() => {
    if (!chainId) {
      return;
    }
    setJsonRpcProvider(
      new ethers.providers.JsonRpcProvider(getAddress(chainId, 'provider'))
    );
  }, [chainId]);

  useEffect(() => {
    // erc20 contract
    setErc20Contract(
      new Contract(
        coinAddress,
        erc20ABI,
        library?.getSigner() && chainId && supportedChains.includes(chainId)
          ? library?.getSigner()
          : jsonRpcProvider
      )
    );

    // erc1155 contract
    setErc1155Contract(
      new Contract(
        coinAddress,
        erc1155ABI,
        library?.getSigner() && chainId && supportedChains.includes(chainId)
          ? library?.getSigner()
          : jsonRpcProvider
      )
    );

    // wallet contract
    setWalletContract(
      new Contract(
        getAddress(chainId, 'wallet'),
        walletABI,
        library?.getSigner() && chainId && supportedChains.includes(chainId)
          ? library?.getSigner()
          : jsonRpcProvider
      )
    );

    // RPS contract
    setRpsContract(
      new Contract(
        getAddress(chainId, 'rps'),
        rpsABI,
        library?.getSigner() && chainId && supportedChains.includes(chainId)
          ? library?.getSigner()
          : jsonRpcProvider
      )
    );

    // ONE contract
    setOneContract(
      new Contract(
        getAddress(chainId, 'one'),
        oneABI,
        library?.getSigner() && chainId && supportedChains.includes(chainId)
          ? library?.getSigner()
          : jsonRpcProvider
      )
    );

    // DJACK contract FAKE!
    setDjackContract(
      new Contract(
        getAddress(chainId, 'djack'),
        oneABI,
        library?.getSigner() && chainId && supportedChains.includes(chainId)
          ? library?.getSigner()
          : jsonRpcProvider
      )
    );

    // DBET contract
    setDbetContract(
      new Contract(
        getAddress(chainId, 'dbet'),
        dbetABI,
        library?.getSigner() && chainId && supportedChains.includes(chainId)
          ? library?.getSigner()
          : jsonRpcProvider
      )
    );

    // TNC contract FAKE!
    setTncContract(
      new Contract(
        getAddress(chainId, 'tnc'),
        oneABI,
        library?.getSigner() && chainId && supportedChains.includes(chainId)
          ? library?.getSigner()
          : jsonRpcProvider
      )
    );
  }, [jsonRpcProvider, account]);

  return (
    <ContractContext.Provider
      value={{
        jsonRpcProvider,
        erc20Contract,
        erc1155Contract,
        walletContract,
        rpsContract,
        oneContract,
        djackContract,
        dbetContract,
        tncContract
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

export const useContract = () => useContext(ContractContext);

export default ContractProvider;
