import { useState, useEffect, useRef } from 'react';

interface IProps {
  totalPointsInput: number;
  winState: string;
}

const OnEGameTotalPointsScreen: React.FC<IProps> = ({
  totalPointsInput,
  winState
}: IProps) => {
  // states
  const [totalPoints, setTotalPoints] = useState<number>(totalPointsInput);
  const interval = useRef<NodeJS.Timeout | null>(null);

  // effects
  useEffect(() => {
    if (totalPointsInput > totalPoints) {
      interval.current = setInterval(() => {
        setTotalPoints((prevTotalPoints) => prevTotalPoints + 1);
      }, 700);
    } else if (totalPointsInput < totalPoints) {
      interval.current = setInterval(() => {
        setTotalPoints((prevTotalPoints) => prevTotalPoints - 1);
      }, 700);
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [totalPointsInput]);

  useEffect(() => {
    if (interval.current && totalPoints === totalPointsInput) {
      clearInterval(interval.current);
    }
  }, [totalPoints, totalPointsInput]);

  // styles for single points
  const singlePointColors: string[] = Array(20).fill(
    'oneGameSinglePointTransparent'
  );

  if (winState === 'win') {
    for (let i = 0; i < totalPoints; i++) {
      singlePointColors[i] = 'oneGameSinglePointWin';
    }
  } else if (winState === 'draw') {
    for (let i = 0; i < totalPoints; i++) {
      singlePointColors[i] = 'oneGameSinglePointDraw';
    }
  } else if (winState === 'lose') {
    for (let i = 0; i < totalPoints; i++) {
      singlePointColors[i] = 'oneGameSinglePointLose';
    }
  }

  return (
    <>
      <div className="oneGameTotalPointsScreenWrapper">
        {Array.from({ length: 20 }, (_, i) => i).map((i) => (
          <span
            key={19 - i}
            className={`oneGameSinglePoint oneGameSinglePointOutlined ${
              singlePointColors[19 - i]
            }`}
          />
        ))}
      </div>
    </>
  );
};

export default OnEGameTotalPointsScreen;
