import { motion } from 'framer-motion';

const FaucetTitle = () => {
  const title = 'Bitcolympics Faucet';
  const line = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.7,
        staggerChildren: 0.1
      }
    }
  };
  const letter = {
    hidden: {
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };
  const svgAnimation = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2
      }
    }
  };
  const svgVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1
    }
  };
  const svgTransition = {
    ease: 'easeInOut',
    duration: 0.6
  };
  return (
    <>
      <motion.svg
        key="faucetTitleSvg"
        version="1.0"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        variants={svgAnimation}
        initial="hidden"
        animate="visible"
        className="titleSvg"
      >
        <motion.path
          fill="#f8f8f8"
          d="M21 10H7V7c0-1.103.897-2 2-2s2 .897 2 2h2c0-2.206-1.794-4-4-4S5 4.794 5 7v3H3a1 1 0 0 0-1 1v2c0 2.606 1.674 4.823 4 5.65V22h2v-3h8v3h2v-3.35c2.326-.827 4-3.044 4-5.65v-2a1 1 0 0 0-1-1zm-1 3c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4v-1h16v1z"
          variants={svgVariants}
          transition={svgTransition}
        />
      </motion.svg>
      <motion.span
        key="faucetTitleText"
        variants={line}
        initial="hidden"
        animate="visible"
      >
        {title.split('').map((char, i) => (
          <motion.span key={i} variants={letter}>
            {char}
          </motion.span>
        ))}
      </motion.span>
    </>
  );
};

export default FaucetTitle;
