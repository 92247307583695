import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useContract } from '../../../contexts/ContractProvider';
import getAddress from '../../../shared/functions/getAddress';
import getChainInfo from '../../../shared/functions/getChainInfo';
import translate from '../../../shared/functions/translate';
import { IReduxState } from '../../../shared/interfaces';
import LobbyPagination from '../../utils/buttons/LobbyPagination';
import LoadingSpinner from '../../utils/graphics/LoadingSpinner';
import OnELobbyJoin from './OnELobbyJoin';

interface IOnEGameInfo {
  address: string;
  players: {
    player1: string;
    player2: string;
  };
  entryFee: string;
}

interface IProps {
  currentPage: React.MutableRefObject<number>;
  allowPageChange: React.MutableRefObject<boolean>;
  forcePageRefresh: React.MutableRefObject<boolean>;
  createGameBlock: React.MutableRefObject<number>;
}

const OnELobbyJoinMenu: React.FC<IProps> = ({
  currentPage,
  allowPageChange,
  forcePageRefresh,
  createGameBlock
}: IProps) => {
  // states and contexts
  const { account, chainId } = useWeb3React<Web3Provider>();
  const { oneContract } = useContract();
  const [gameInfoArray, setGameInfoArray] = useState<IOnEGameInfo[]>([]);
  const isSubscribed = useRef<boolean>(false); // memory leak fix

  const history = useHistory();

  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  // fetch functions
  const getSingleGameFromContract = async (gameAddress: BigNumber) => {
    try {
      const info = await oneContract?.getGameInfo(gameAddress.toString());
      return {
        address: info[0].toString(),
        players: {
          player1: info[1][0] ? info[1][0] : undefined,
          player2: info[1][1] ? info[1][1] : undefined
        },
        entryFee: info[9].toString()
      };
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const populateGameInfo = async (addresses: BigNumber[]) => {
    const extendedGameInfo: IOnEGameInfo[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const gameAddress of addresses) {
      // eslint-disable-next-line no-await-in-loop
      const info = await getSingleGameFromContract(gameAddress);
      if (info && info.address !== '0') {
        extendedGameInfo.push(info);
      }
    }
    allowPageChange.current = true;
    if (isSubscribed.current) {
      setGameInfoArray(extendedGameInfo);
    }
    if (account && createGameBlock.current !== 0) {
      extendedGameInfo.forEach((game) => {
        if (
          game.players.player1 === account ||
          game.players.player2 === account
        ) {
          history.push(`/onegame/${game.address}`);
        }
      });
    }
  };

  const getGameAdresses = async () => {
    try {
      const fetchedGameAdresses = await oneContract?.getGames(
        '8',
        ((currentPage.current - 1) * 8).toString(),
        coinData.id
      );
      await populateGameInfo(
        fetchedGameAdresses.filter(
          (address: BigNumber) => address.toNumber() !== 0
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  // cleanup
  const cleanup = () => {
    setGameInfoArray([]);
  };

  useEffect(() => {
    isSubscribed.current = true;
    getGameAdresses();
    return () => {
      isSubscribed.current = false;
      cleanup();
    };
  }, [currentPage.current, chainId, coinData, oneContract]);

  // pagination
  const onPageChange = (newPage: number) => {
    if (allowPageChange.current) {
      setGameInfoArray([]);
      allowPageChange.current = false;
      currentPage.current = newPage;
    }
  };

  // listen block changes for force refresh
  useEffect(() => {
    isSubscribed.current = true;
    const oneLobbyJsonRpcProvider = new ethers.providers.JsonRpcProvider(
      getAddress(chainId, 'provider')
    );
    oneLobbyJsonRpcProvider.on('block', (blockNumber: number) => {
      if (
        forcePageRefresh.current &&
        blockNumber >
          createGameBlock.current +
            Number(getChainInfo(chainId, 'block-interval'))
      ) {
        setGameInfoArray([]);
        getGameAdresses();
        forcePageRefresh.current = false;
      }
    });
    return () => {
      isSubscribed.current = false;
      oneLobbyJsonRpcProvider.off('block');
      cleanup();
    };
  }, [chainId]);

  return (
    <motion.div
      key="oneLobbyJoinCard"
      initial="collapsed"
      animate={gameInfoArray.length > 0 ? 'open' : 'collapsed'}
      exit="collapsed"
      variants={{
        open: {
          height: `${78 + gameInfoArray.length * 69}px`
        },
        collapsed: {
          height: currentPage.current === 1 ? '165px' : '203px'
        }
      }}
      transition={{ duration: 0.6, ease: 'easeInOut' }}
    >
      <motion.div
        key="oneLobbyJoinInnerCard"
        initial="collapsed"
        animate={gameInfoArray.length > 0 ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: `${63 + gameInfoArray.length * 69}px`
          },
          collapsed: {
            height: currentPage.current === 1 ? '150px' : '188px'
          }
        }}
        transition={{ duration: 0.6, ease: 'easeInOut' }}
        className="innerCard"
      >
        <div className="innerCardTitle">{translate('joinLobby')}</div>
        <AnimatePresence exitBeforeEnter>
          {gameInfoArray.length > 0 ? (
            <motion.div
              key="oneLobbyJoinList"
              variants={{
                collapsed: { opacity: 0 },
                open: {
                  opacity: 1,
                  transition: {
                    delayChildren: 0.2,
                    staggerChildren: 0.2
                  }
                }
              }}
              initial="collapsed"
              animate="open"
              exit="collapsed"
            >
              {gameInfoArray.map((c) => (
                <OnELobbyJoin key={c.address} gameData={c} />
              ))}
            </motion.div>
          ) : (
            <motion.div
              key="oneLobbyJoinListError"
              variants={{
                collapsed: { opacity: 0 },
                open: {
                  opacity: 1
                }
              }}
              initial="collapsed"
              animate="open"
              exit="collapsed"
            >
              <div className="oneLobbyJoinInfoLoader">
                <LoadingSpinner />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        {(gameInfoArray.length > 0 || currentPage.current !== 1) && (
          <LobbyPagination
            currentPage={currentPage.current}
            isLastPage={gameInfoArray.length < 8 || !allowPageChange.current}
            onPageChange={onPageChange}
          />
        )}
      </motion.div>
    </motion.div>
  );
};

export default OnELobbyJoinMenu;
