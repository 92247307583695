import { useSelector } from 'react-redux';
import DiceIcon1 from '../utils/dice/DiceIcon1';
import DiceIcon2 from '../utils/dice/DiceIcon2';
import DiceIcon3 from '../utils/dice/DiceIcon3';
import DiceIcon4 from '../utils/dice/DiceIcon4';
import DiceIcon5 from '../utils/dice/DiceIcon5';
import DiceIcon6 from '../utils/dice/DiceIcon6';
import translate from '../../shared/functions/translate';
import { IReduxState } from '../../shared/interfaces';

interface IProps {
  totalBets: number[];
}

const sum = (a: number, b: number) => a + b;
const getRate = (singleBet: number, totalBets: number[]) => {
  if (singleBet === 0) {
    return 0;
  }
  const total = totalBets.reduce(sum, 0);
  return Math.round((total * 100) / singleBet) / 100;
};

const DBetGameCurrentRates: React.FC<IProps> = ({ totalBets }: IProps) => {
  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);

  const data = [
    {
      key: 1,
      icon: <DiceIcon1 />,
      rate: getRate(totalBets[0], totalBets),
      bet: totalBets[0]
    },
    {
      key: 2,
      icon: <DiceIcon2 />,
      rate: getRate(totalBets[1], totalBets),
      bet: totalBets[1]
    },
    {
      key: 3,
      icon: <DiceIcon3 />,
      rate: getRate(totalBets[2], totalBets),
      bet: totalBets[2]
    },
    {
      key: 4,
      icon: <DiceIcon4 />,
      rate: getRate(totalBets[3], totalBets),
      bet: totalBets[3]
    },
    {
      key: 5,
      icon: <DiceIcon5 />,
      rate: getRate(totalBets[4], totalBets),
      bet: totalBets[4]
    },
    {
      key: 6,
      icon: <DiceIcon6 />,
      rate: getRate(totalBets[5], totalBets),
      bet: totalBets[5]
    }
  ];

  return (
    <>
      <div className="dbetGameCurrentRatesRow">
        {data.slice(0, 3).map((row) => (
          <div
            key={`dbetGameRateWrapper-${row.key}`}
            className="dbetGameRateWrapper"
          >
            <div className="diceOutlinedIcon">{row.icon}</div>
            <div className="prizeWrapper">
              <div className="prizeText">{translate('dynamicRate')}</div>
              <div className="prizeValue">
                {row.rate === 0 ? '~' : `${row.rate}x`}
              </div>
            </div>
            <div className="prizeWrapper">
              <div className="prizeText">{translate('totalBets')}</div>
              <div className="prizeValue prizeUnit">
                {`${Math.round(row.bet * 1000) / 1000} `}
                <span className="prizeUnit">{coinData.name}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="dbetGameCurrentRatesRow">
        {data.slice(3, 6).map((row) => (
          <div
            key={`dbetGameRateWrapper-${row.key}`}
            className="dbetGameRateWrapper"
          >
            <div className="diceOutlinedIcon">{row.icon}</div>
            <div className="prizeWrapper">
              <div className="prizeText">{translate('dynamicRate')}</div>
              <div className="prizeValue">
                {row.rate === 0 ? '~' : `${row.rate}x`}
              </div>
            </div>
            <div className="prizeWrapper">
              <div className="prizeText">{translate('totalBets')}</div>
              <div className="prizeValue prizeUnit">
                {`${Math.round(row.bet * 1000) / 1000} `}
                <span className="prizeUnit">{coinData.name}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DBetGameCurrentRates;
