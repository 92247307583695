import { useState } from 'react';

interface IFaucetEventLog {
  time: number;
  text: string;
}

const FaucetEventLog = () => {
  // states
  const [faucetEventsLog] = useState<IFaucetEventLog[]>([]);

  return (
    <>
      <div className="innerCardTitle">Request Log</div>
      {faucetEventsLog.length === 0 ? (
        <div className="eventsLogTextDisabled">
          No recent requests received...
        </div>
      ) : (
        <div className="eventsLogContainer">
          <ul className="eventsLogList">
            {faucetEventsLog.map((event, index) => (
              <li key={index}>
                <span className="eventsLogTime">{`#${event.time}: `}</span>
                <span className="eventsLogText">{event.text}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default FaucetEventLog;
