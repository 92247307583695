import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import App from './App';
import ScrollToTop from './components/utils/functions/ScrollToTop';
import { store, persistor } from './redux/store';
import ContractProvider from './contexts/ContractProvider';
import ModalProvider from './contexts/ModalProvider';

function getLibrary(provider: any) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <PersistGate loading="null" persistor={persistor}>
          <ContractProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </ContractProvider>
        </PersistGate>
      </Web3ReactProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
