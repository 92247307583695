interface IOnButtonClick {
  onClick: () => Promise<void> | undefined;
}

interface IProps {
  onClick: IOnButtonClick | undefined;
  buttonClass?: string;
  buttonText: string | JSX.Element;
  buttonDisabled?: boolean;
}

const defaultProps = {
  buttonClass: 'buttonOutlined',
  buttonDisabled: false
};

const Button: React.FC<IProps> = ({
  onClick,
  buttonClass = defaultProps.buttonClass,
  buttonText,
  buttonDisabled
}: IProps) => (
  <button
    type="button"
    className={buttonClass}
    onClick={onClick?.onClick}
    disabled={buttonDisabled}
  >
    {buttonText}
  </button>
);

Button.defaultProps = defaultProps;

export default Button;
