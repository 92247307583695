import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import NavbarBottomSheet from './components/navbar/NavbarBottomSheet';
import CopyNotification from './components/utils/feedback/CopyNotification';
import './styles/global.scss';
import DBetGame from './views/dbet/DBetGame';
import Faucet from './views/faucet/Faucet';
// import DJackGame from './views/djack/DJackGame';
// import DJackLobby from './views/djack/DJackLobby';
import Homepage from './views/homepage/Homepage';
import OnEGame from './views/one/OnEGame';
import OnELobby from './views/one/OnELobby';
import RPSGame from './views/rps/RPSGame';
import RPSLobby from './views/rps/RPSLobby';
// import TnCGame from './views/tnc/TnCGame';
// import TnCLobby from './views/tnc/TnCLobby';

function App() {
  // bottom sheet
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  function onSheetClose() {
    setIsSheetOpen(false);
  }
  function onSheetOpen() {
    setIsSheetOpen(true);
  }
  function sheetToggle() {
    setIsSheetOpen(!isSheetOpen);
  }

  return (
    <div className="container">
      <Navbar sheetToggle={sheetToggle} />
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          {/* <Route path="/tnclobby">
            <TnCLobby />
          </Route>
          <Route path="/djacklobby">
            <DJackLobby />
          </Route> */}
          <Route path="/onelobby">
            <OnELobby />
          </Route>
          <Route path="/rpslobby">
            <RPSLobby />
          </Route>
          {/* <Route path="/djackgame">
            <DJackGame />
          </Route> */}
          <Route path="/dbetgame">
            <DBetGame />
          </Route>
          <Route path="/onegame">
            <OnEGame />
          </Route>
          <Route path="/rpsgame">
            <RPSGame />
          </Route>
          {/* <Route path="/tncgame">
            <TnCGame />
          </Route> */}
          <Route path="/faucet">
            <Faucet />
          </Route>
        </Switch>
      </AnimatePresence>
      <NavbarBottomSheet
        onSheetOpen={onSheetOpen}
        isSheetOpen={isSheetOpen}
        onSheetClose={onSheetClose}
      />
      <CopyNotification />
    </div>
  );
}

export default App;
