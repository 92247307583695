import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { ethers } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useContract } from '../../../contexts/ContractProvider';
import getChainInfo from '../../../shared/functions/getChainInfo';
import translate from '../../../shared/functions/translate';
import { IJSONObject } from '../../../shared/interfaces';
import getAddress from '../../../shared/functions/getAddress';

interface IRpsEventLog {
  time: number;
  text: string;
  logType: number;
}

interface IRpsEventDict {
  [key: number]: string;
}

interface IRpsEvent {
  blockNumber: number;
  gameId: string;
  player: string;
  value: number;
  log: number;
}

const shorter = (str: string) =>
  str?.length > 8 ? `${str.slice(0, 5)}...${str.slice(-4)}` : str;

const RPSGameEventsLog = () => {
  // states
  const [rpsEventsLog, setRpsEventsLog] = useState<IRpsEventLog[]>([]);
  const isSubscribed = useRef<boolean>(false); // memory leak fix

  // context and provider
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const { chainId } = useWeb3React<Web3Provider>();
  const { rpsContract } = useContract();

  // fixed hooks translation
  const translatedText: IJSONObject = {
    rpsEvents1: translate('rpsEvents1'),
    rpsEvents2: translate('rpsEvents2'),
    rpsEvents3: translate('rpsEvents3'),
    rpsEvents4: translate('rpsEvents4'),
    rpsEvents5: translate('rpsEvents5'),
    rpsEvents6: translate('rpsEvents6'),
    rpsEvents7: translate('rpsEvents7'),
    rpsEvents8: translate('rpsEvents8'),
    rpsEvents9: translate('rpsEvents9'),
    rpsEvents10: translate('rpsEvents10'),
    rpsEvents11: translate('rpsEvents11')
  };

  // log query listener
  const listener = async (currentBlock: number) => {
    if (!rpsContract) {
      return;
    }
    const event = rpsContract.filters.GameLog();
    const allEvents = await rpsContract.queryFilter(
      event,
      currentBlock - Number(getChainInfo(chainId, 'event-range')),
      currentBlock
    );
    const events = allEvents.map((el: ethers.Event) => ({
      blockNumber: el.blockNumber,
      gameId: el.args && el.args[0].toString(),
      player: el.args && el.args[1],
      value: el.args && el.args[2].toNumber(),
      log: el.args && el.args[3].toNumber()
    }));

    const dict: IRpsEventDict = {
      5: translatedText.rpsEvents1,
      6: translatedText.rpsEvents2,
      7: translatedText.rpsEvents3,
      8: translatedText.rpsEvents4,
      9: translatedText.rpsEvents5,
      10: translatedText.rpsEvents6,
      11: translatedText.rpsEvents7,
      12: translatedText.rpsEvents8
    };

    const rpsTranslate: IRpsEventDict = {
      1: translatedText.rpsEvents9,
      2: translatedText.rpsEvents10,
      3: translatedText.rpsEvents11
    };

    const parsedEvents = events
      .filter((el: IRpsEvent) => el.gameId === path)
      .map((el: IRpsEvent) => {
        const actor = el.player;
        const logType = el.log;
        let text = '';
        if (logType === 10) {
          text = `${
            actor === ethers.constants.AddressZero ? '' : shorter(actor)
          } ${dict[logType]} ${rpsTranslate[el.value]}.`;
        } else if (logType === 11) {
          text = `${dict[logType]}`;
        } else {
          text = `${
            actor === ethers.constants.AddressZero ? '' : shorter(actor)
          } ${dict[logType]}`;
        }
        return { time: el.blockNumber, text, logType };
      });
    if (isSubscribed.current) {
      setRpsEventsLog(parsedEvents);
    }
  };

  // lifecycle hooks
  useEffect(() => {
    isSubscribed.current = true;
    const rpsEventsLogJsonRpcProvider = new ethers.providers.JsonRpcProvider(
      getAddress(chainId, 'provider')
    );
    if (rpsContract) {
      rpsEventsLogJsonRpcProvider.on('block', (blockNumber: number) => {
        if (isSubscribed.current) {
          listener(blockNumber);
        }
      });
    }
    return () => {
      isSubscribed.current = false;
      rpsEventsLogJsonRpcProvider.off('block');
    };
  }, [rpsContract]);

  return rpsEventsLog.length === 0 ? (
    <div className="eventsLogTextDisabled">No events received yet...</div>
  ) : (
    <div className="eventsLogContainer">
      <ul className="eventsLogList">
        {rpsEventsLog.map((event, index) => (
          <li key={index}>
            <span
              className={
                event.logType === 12 ? 'eventsLogAlternate' : 'eventsLogTime'
              }
            >{`#${event.time}: `}</span>
            <span
              className={
                event.logType === 12 ? 'eventsLogAlternate' : 'eventsLogText'
              }
            >
              {event.text}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RPSGameEventsLog;
