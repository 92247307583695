interface IProps {
  color?: string;
}

const defaultProps = {
  color: '#f8f8f8'
};

const NavbarBottomSheetWalletDepositIcon: React.FC<IProps> = ({
  color
}: IProps) => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <style>
      {`
        .st0 {
          transition: fill 0.3s;
        }
      `}
    </style>
    <path
      fill={color}
      className="st0"
      d="M110.22,117.75h-80a10,10,0,0,0,0,20h80a10,10,0,0,0,0-20Z"
    />
    <path
      fill={color}
      className="st0"
      d="M177.22,125.75a9.67,9.67,0,0,0-14,0l-8,7.5V42.75a10,10,0,0,0-20,0v113.5a8.29,8.29,0,0,0,3,8,9.67,9.67,0,0,0,14,0l24.5-24.5a10.13,10.13,0,0,0,.5-14Z"
    />
    <path
      fill={color}
      className="st0"
      d="M110.22,37.75h-80a10,10,0,0,0,0,20h80a10,10,0,0,0,0-20Z"
    />
    <path
      fill={color}
      className="st0"
      d="M30.22,97.75h70a10,10,0,0,0,0-20h-70a10,10,0,0,0,0,20Z"
    />
  </svg>
);

NavbarBottomSheetWalletDepositIcon.defaultProps = defaultProps;
export default NavbarBottomSheetWalletDepositIcon;
