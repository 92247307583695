import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { changeCoin } from '../../redux/coinSlice';
import searchIcon from '../../shared/constants/icons/search_icon.png';
import { ICoinState, IReduxState } from '../../shared/interfaces';
import getCoinInfo from '../../shared/functions/getCoinInfo';
import { useContract } from '../../contexts/ContractProvider';

interface IProps {
  buttonText: JSX.Element;
}

const NavbarWalletButton: React.FC<IProps> = ({ buttonText }: IProps) => {
  // states
  const [expanded, setExpanded] = useState(false);
  const [searchedCoin, setSearchedCoin] = useState<string>();

  // contexts
  const { chainId } = useWeb3React<Web3Provider>();
  const { walletContract } = useContract();

  // redux
  const coinData = useSelector((state: IReduxState) => state.coin);
  const dispatch = useDispatch();

  const coins: ICoinState[] = getCoinInfo(chainId);

  const handleChangeCoin = async (coin: ICoinState) => {
    const currencySettings = await walletContract?.currencies(coin.id);
    dispatch(changeCoin({ ...coin, type: currencySettings[3].toNumber() }));
    setExpanded(false);
    setSearchedCoin(undefined);
  };

  return (
    <div className="navbarWalletButtonWrapper">
      <motion.div
        className="navbarWalletButtonContainer"
        key="navbarWalletButtonContainer"
        initial="collapsed"
        animate={expanded ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: `209px`
          },
          collapsed: {
            height: '28px'
          }
        }}
        transition={{ duration: 0.6, ease: 'easeInOut' }}
      >
        <AnimatePresence key="navbarWalletButtonSelected" exitBeforeEnter>
          <motion.div
            key={`navbarWalletButton-${coinData.name}`}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="navbarWalletButton"
            onClick={() => setExpanded(!expanded)}
          >
            <img
              src={coinData.logo}
              alt={`coinIcon-${coinData.name}`}
              className="navbarWalletButtonIcon"
            />
            {buttonText}
          </motion.div>
        </AnimatePresence>
        <AnimatePresence key="navbarWalletButtonSearch">
          {expanded && (
            <motion.div
              key="navbarWalletButtonSearch"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
              className="navbarWalletButtonSearchWrapper"
            >
              <div className="navbarWalletButtonSearchContainer">
                <img
                  src={searchIcon}
                  alt="searchIcon"
                  className="navbarWalletButtonSearchIcon"
                />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchedCoin(e.target.value)}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence key="navbarWalletButtonList">
          {expanded && (
            <motion.div
              className="navbarWalletButtonList"
              key="navbarWalletButtonList"
              variants={{
                open: {
                  transition: {
                    delayChildren: 0.1,
                    staggerChildren: 0.1
                  }
                }
              }}
              initial="initial"
              animate="open"
              exit="exit"
            >
              {coins
                .filter((coin: ICoinState) =>
                  searchedCoin
                    ? coin.name.startsWith(searchedCoin.toUpperCase())
                    : true
                )
                .map(
                  (coin: ICoinState) =>
                    coin !== coinData && (
                      <motion.div
                        variants={{
                          initial: {
                            opacity: 0,
                            x: 10
                          },
                          open: {
                            opacity: 1,
                            x: 0,
                            transition: {
                              duration: 0.3,
                              ease: 'easeInOut'
                            }
                          },
                          exit: {
                            opacity: 0
                          }
                        }}
                        key={`navbarWalletButtonIcon-${coin.name}`}
                        className="navbarWalletButtonIconWrapper"
                        onClick={() => handleChangeCoin(coin)}
                      >
                        <img
                          src={coin.logo}
                          alt={`coinIcon-${coinData}`}
                          className="navbarWalletButtonIcon"
                        />
                        <span>{coin.name}</span>
                      </motion.div>
                    )
                )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default NavbarWalletButton;
