import { useEffect, useState } from 'react';

interface IProps {
  remainingTime: number;
  gameState: number;
}

const OnEGameCountdown: React.FC<IProps> = ({
  remainingTime,
  gameState
}: IProps) => {
  const [remainingSeconds, setRemainingSeconds] = useState(remainingTime);
  const [timePercentage, setTimePercentage] = useState<number>(100);

  useEffect(() => {
    setRemainingSeconds(remainingTime);
    const interval = setInterval(() => {
      if (gameState === 2 && remainingTime > 0) {
        setRemainingSeconds((r) => (r > 0 ? r - 1 : 0));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [remainingTime]);

  useEffect(() => {
    setTimePercentage((remainingSeconds / 60) * 100);
  }, [remainingSeconds]);

  return (
    <>
      <div className="countdownTextWrapper">{remainingSeconds}</div>
      <div className="countdownProgressContainer">
        <div className="countdownProgressLeft">
          <div
            className="countdownProgressBar"
            style={{ width: `${100 - timePercentage}%` }}
          />
        </div>
        <div className="countdownProgressRight">
          <div
            className="countdownProgressBar"
            style={{ width: `${timePercentage}%` }}
          />
        </div>
      </div>
    </>
  );
};

export default OnEGameCountdown;
