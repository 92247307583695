import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { motion, PanInfo, useAnimation, useDragControls } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/userSlice';
import translate from '../../shared/functions/translate';
import { IJSONObject } from '../../shared/interfaces';
import NavbarBottomSheetWallet from './NavbarBottomSheetWallet';

// types
interface IProps {
  isSheetOpen: boolean;
  onSheetClose: () => void;
  onSheetOpen: () => void;
}

// animations
const usePrevious = (value: boolean) => {
  const previousValueRef = useRef<boolean>();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
};

// component
const NavbarBottomSheet: React.FC<IProps> = ({
  isSheetOpen,
  onSheetClose,
  onSheetOpen
}) => {
  // update viewport height on resize
  const [vh, setVh] = useState(window.innerHeight);
  useEffect(() => {
    window.addEventListener('resize', () => setVh(window.innerHeight));
    return () =>
      window.removeEventListener('resize', () => setVh(window.innerHeight));
  }, []);

  // animations
  const prevIsSheetOpen = usePrevious(isSheetOpen);
  const controls = useAnimation();

  const onDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const shouldClose = info.velocity.y > 20 || info.point.y > vh - 160;
    if (shouldClose) {
      controls.start('hidden');
      onSheetClose();
    } else {
      controls.start({ y: 0 });
      onSheetOpen();
    }
  };

  const dragControls = useDragControls();

  const startDrag = (event: React.MouseEvent) => {
    dragControls.start(event);
  };

  useEffect(() => {
    if (prevIsSheetOpen && !isSheetOpen) {
      controls.start('hidden');
    } else if (!prevIsSheetOpen && isSheetOpen) {
      controls.start('visible');
    }
  }, [controls, isSheetOpen, prevIsSheetOpen]);

  // wallet
  const { deactivate } = useWeb3React<Web3Provider>();

  // redux
  const dispatch = useDispatch();

  // logout
  async function disconnect() {
    try {
      await deactivate();
      dispatch(logout());
      controls.start('hidden');
      onSheetClose();
    } catch (err) {
      console.error(err);
    }
  }

  // fixed hooks translation
  const translatedText: IJSONObject = {
    logout: translate('logout')
  };

  return (
    <motion.div
      key="bottom"
      drag="y"
      onDragEnd={onDragEnd}
      dragControls={dragControls}
      dragListener={false}
      initial="hidden"
      animate={controls}
      transition={{
        type: 'spring',
        damping: 40,
        stiffness: 400
      }}
      variants={{
        visible: { bottom: 0, y: 0 },
        hidden: { bottom: 0, y: 323 }
      }}
      dragConstraints={{ top: 0 }}
      dragElastic={0}
      style={{
        position: 'fixed',
        backgroundColor: '#202020',
        width: '100%',
        maxWidth: 420,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        zIndex: 5
      }}
    >
      <div className="navbarBottomSheetLineWrapper" onPointerDown={startDrag}>
        <div className="navbarBottomSheetLine" />
      </div>
      <div className="navbarBottomSheetCard">
        <NavbarBottomSheetWallet />
      </div>
      <div className="navbarBottomSheetCard navbarBottomSheetCenter">
        <button
          type="button"
          className="navbarBottomSheetLogoutButton"
          onClick={disconnect}
        >
          {translatedText.logout}
        </button>
      </div>
    </motion.div>
  );
};

export default NavbarBottomSheet;
