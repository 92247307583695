import { useRef } from 'react';
import { motion } from 'framer-motion';
import OnEInfoCard from '../../components/one/info/OnEInfoCard';
import OnELobbyCreate from '../../components/one/lobby/OnELobbyCreate';
import OnELobbyJoinMenu from '../../components/one/lobby/OnELobbyJoinMenu';
import OnETitle from '../../components/utils/graphics/OnETitle';

const OnELobby = () => {
  const pageRef = useRef<number>(1);
  const allowPageChange = useRef<boolean>(true);
  const forcePageRefresh = useRef<boolean>(false);
  const createGameBlock = useRef<number>(0);

  return (
    <motion.div
      key="OnELobby"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="card">
        <div className="cardTitle">
          <OnETitle />
        </div>
        <div className="innerCard">
          <OnELobbyCreate
            forcePageRefresh={forcePageRefresh}
            createGameBlock={createGameBlock}
            currentPage={pageRef}
          />
        </div>
        <OnELobbyJoinMenu
          currentPage={pageRef}
          allowPageChange={allowPageChange}
          forcePageRefresh={forcePageRefresh}
          createGameBlock={createGameBlock}
        />
      </div>
      <OnEInfoCard />
    </motion.div>
  );
};

export default OnELobby;
