import { Bytes, SignatureLike } from '@ethersproject/bytes';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useContract } from '../../../contexts/ContractProvider';
import { changeData } from '../../../redux/rpsDataSlice';
import translate from '../../../shared/functions/translate';
import { IJSONObject, IReduxState } from '../../../shared/interfaces';
import LoadingDots from '../../utils/graphics/LoadingDots';
import LoadingSpinner from '../../utils/graphics/LoadingSpinner';
import RPSGamePaperIcon from './icons/RPSGamePaperIcon';
import RPSGameRockIcon from './icons/RPSGameRockIcon';
import RPSGameScissorsIcon from './icons/RPSGameScissorsIcon';

interface IProps {
  gameState: number;
  players: string[];
  isNextAvailable: boolean;
  isLoading: boolean;
  round: number;
  isTxSendable: boolean;
  isSigned: boolean[];
}

const RPSGameUIButton: React.FC<IProps> = ({
  gameState,
  players,
  isNextAvailable,
  isLoading,
  round,
  isTxSendable,
  isSigned
}: IProps) => {
  // contract definition
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  // contexts
  const { rpsContract } = useContract();

  // states
  const [joinButtonDisabled, setJoinButtonDisabled] = useState<boolean>(false);
  const [startButtonDisabled, setStartButtonDisabled] =
    useState<boolean>(false);
  const [claimButtonDisabled, setClaimButtonDisabled] =
    useState<boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
  const [playButtonDisabled, setPlayButtonDisabled] = useState<boolean>(false);
  const [revealButtonDisabled, setRevealButtonDisabled] =
    useState<boolean>(false);
  const [prevRound, setPrevRound] = useState<number>(round);
  const [prevGameState, setPrevGameState] = useState<number>(gameState);
  const [positionIndex, setPositionIndex] = useState<number>(0);
  const { account, library } = useWeb3React<Web3Provider>();

  // hold button colors
  const [rockButtonColor, setRockButtonColor] = useState<string>('#1ab2ff');
  const [paperButtonColor, setPaperButtonColor] = useState<string>('#1ab2ff');
  const [scissorsButtonColor, setScissorsButtonColor] =
    useState<string>('#1ab2ff');

  // redux
  const rpsGameData = useSelector((state: IReduxState) => state.rpsData.data);
  const dispatch = useDispatch();

  // functions
  const handleJoin = async () => {
    if (!players.includes(ethers.constants.AddressZero)) {
      window.alert('There is no empty space in the room.');
    }
    try {
      setJoinButtonDisabled(true);
      const receipt = await rpsContract?.join(path);
      await receipt.wait();
    } catch (error: any) {
      setJoinButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  const handleStart = async () => {
    try {
      setStartButtonDisabled(true);
      const receipt = await rpsContract?.start(path);
      await receipt.wait();
    } catch (error: any) {
      setStartButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  const handleSign = async (message: Bytes) => {
    const signer = library?.getSigner();
    const flatSig = await signer?.signMessage(message);
    const sig = ethers.utils.splitSignature(flatSig as SignatureLike);
    return sig;
  };

  const handleHold = async (holdValue: number) => {
    if (!rpsContract) {
      return;
    }
    try {
      setPlayButtonDisabled(true);
      const currentTime = Math.round(Date.now() / 1000);
      const message = currentTime * 10 + holdValue;
      const hash = ethers.utils.solidityKeccak256(['uint256'], [message]);
      const bytesDataHash = ethers.utils.arrayify(hash);
      const { v, r, s } = await handleSign(bytesDataHash);
      const storedHash = ethers.utils.solidityKeccak256(
        ['uint8', 'bytes32', 'bytes32'],
        [v, r, s]
      );
      dispatch(changeData({ message, sig: { v, r, s } }));
      const estimatedGas = await rpsContract.estimateGas.setSignature(
        path,
        storedHash
      );
      const receipt = await rpsContract.setSignature(path, storedHash, {
        gasLimit: estimatedGas.mul(ethers.BigNumber.from(2))
      });
      await receipt.wait();
    } catch (error: any) {
      setPlayButtonDisabled(false);
      window.alert(error.message);
    }
  };

  const handleReveal = async () => {
    if (!rpsContract) {
      return;
    }
    try {
      setRevealButtonDisabled(true);
      const estimatedGas = await rpsContract.estimateGas.setTransaction(
        path,
        rpsGameData?.message,
        rpsGameData?.sig?.v,
        rpsGameData?.sig?.r,
        rpsGameData?.sig?.s
      );
      const receipt = await rpsContract.setTransaction(
        path,
        rpsGameData?.message,
        rpsGameData?.sig?.v,
        rpsGameData?.sig?.r,
        rpsGameData?.sig?.s,
        {
          gasLimit: estimatedGas.mul(ethers.BigNumber.from(2))
        }
      );
      await receipt.wait();
    } catch (error: any) {
      setRevealButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  // light cleanup
  const cleanup = () => {
    setJoinButtonDisabled(false);
    setStartButtonDisabled(false);
    setClaimButtonDisabled(false);
    setNextButtonDisabled(false);
    setPlayButtonDisabled(false);
    setRevealButtonDisabled(false);
  };

  const handleNext = async () => {
    try {
      setNextButtonDisabled(true);
      const receipt = await rpsContract?.nextRound(path);
      await receipt.wait();
    } catch (error: any) {
      setNextButtonDisabled(false);
      if (error.data) {
        window.alert(error.data.message);
      } else {
        window.alert(error.message);
      }
    }
  };

  // reset messages on round and game state change
  useEffect(() => {
    if (round !== prevRound && prevRound !== 0) {
      dispatch(changeData({}));
    }
    setPrevRound(round);
  }, [round]);

  useEffect(() => {
    if (gameState !== prevGameState && prevGameState !== 0) {
      dispatch(changeData({}));
    }
    setPrevGameState(gameState);
  }, [gameState]);

  // light cleanup on game state change
  useEffect(() => {
    cleanup();
    if (account && players.includes(account)) {
      setPositionIndex(players.indexOf(account));
    }
  }, [gameState, account, round]);

  // fixed hooks translation
  const translatedText: IJSONObject = {
    start: translate('start'),
    join: translate('join'),
    hold: translate('hold'),
    rock: translate('rock'),
    paper: translate('paper'),
    scissors: translate('scissors'),
    compare: translate('compare'),
    next: translate('next')
  };

  // button render
  let rpsButton;
  if (gameState === 1) {
    if (account && players.includes(account)) {
      rpsButton = (
        <button
          type="button"
          className="buttonOutlined buttonGolden"
          onClick={handleStart}
          disabled={
            players.filter((player) => player !== ethers.constants.AddressZero)
              .length < 2 || startButtonDisabled
          }
        >
          {startButtonDisabled ? <LoadingDots /> : translatedText.start}
        </button>
      );
    } else {
      rpsButton = (
        <button
          type="button"
          className="buttonOutlined buttonGreen"
          onClick={handleJoin}
          disabled={
            !account ||
            !players.includes(ethers.constants.AddressZero) ||
            joinButtonDisabled
          }
        >
          {joinButtonDisabled ? <LoadingDots /> : translatedText.join}
        </button>
      );
    }
  } else if (gameState === 2 && !isNextAvailable) {
    if (!account || (!players.includes(account) && !isTxSendable)) {
      rpsButton = (
        <button type="button" className="buttonOutlined buttonBlue" disabled>
          {playButtonDisabled ? <LoadingDots /> : translatedText.hold}
        </button>
      );
    } else if (!isTxSendable && !isSigned[players.indexOf(account)]) {
      rpsButton = (
        <div className="rpsGameUIHoldSelectionWrapper">
          <button
            type="button"
            onClick={() => handleHold(1)}
            onMouseOver={() => setRockButtonColor('#66ccff')}
            onMouseOut={() => setRockButtonColor('#1ab2ff')}
            onMouseDown={() => setRockButtonColor('#66ccff')}
            disabled={playButtonDisabled}
          >
            {playButtonDisabled ? (
              <LoadingDots />
            ) : (
              <>
                <div className="rpsGameUIHoldSelectionIcon">
                  <RPSGameRockIcon
                    positionIndex={positionIndex}
                    color={rockButtonColor}
                  />
                </div>
                <div className="rpsGameUIHoldSelectionText">
                  {translatedText.rock}
                </div>
              </>
            )}
          </button>
          <button
            type="button"
            onClick={() => handleHold(2)}
            onMouseOver={() => setPaperButtonColor('#66ccff')}
            onMouseOut={() => setPaperButtonColor('#1ab2ff')}
            onMouseDown={() => setPaperButtonColor('#66ccff')}
            disabled={playButtonDisabled}
          >
            {playButtonDisabled ? (
              <LoadingDots />
            ) : (
              <>
                <div className="rpsGameUIHoldSelectionIcon">
                  <RPSGamePaperIcon
                    positionIndex={positionIndex}
                    color={paperButtonColor}
                  />
                </div>
                <div className="rpsGameUIHoldSelectionText">
                  {translatedText.paper}
                </div>
              </>
            )}
          </button>
          <button
            type="button"
            onClick={() => handleHold(3)}
            onMouseOver={() => setScissorsButtonColor('#66ccff')}
            onMouseOut={() => setScissorsButtonColor('#1ab2ff')}
            onMouseDown={() => setScissorsButtonColor('#66ccff')}
            disabled={playButtonDisabled}
          >
            {playButtonDisabled ? (
              <LoadingDots />
            ) : (
              <>
                <div className="rpsGameUIHoldSelectionIcon">
                  <RPSGameScissorsIcon
                    positionIndex={positionIndex}
                    color={scissorsButtonColor}
                  />
                </div>

                <div className="rpsGameUIHoldSelectionText">
                  {translatedText.scissors}
                </div>
              </>
            )}
          </button>
        </div>
      );
    } else {
      rpsButton = (
        <div className="rpsGameUIButtonWrapper">
          <div className="rpsGameUICenterContainer">
            <button
              type="button"
              className="buttonOutlined buttonBlue"
              onClick={handleReveal}
              disabled={revealButtonDisabled || !rpsGameData || !isTxSendable}
            >
              {revealButtonDisabled ? <LoadingDots /> : translatedText.compare}
            </button>
          </div>
          {!!rpsGameData && !!rpsGameData.message && (
            <div className="rpsGameUIBottomContainer">
              <div className="rpsGameUIBottomWrapper">
                <div className="rpsGameUIBottomIcon">
                  {rpsGameData.message % 10 === 1 ? (
                    <RPSGameRockIcon positionIndex={positionIndex} />
                  ) : rpsGameData.message % 10 === 2 ? (
                    <RPSGamePaperIcon positionIndex={positionIndex} />
                  ) : rpsGameData.message % 10 === 3 ? (
                    <RPSGameScissorsIcon positionIndex={positionIndex} />
                  ) : (
                    ''
                  )}
                </div>
                <div className="rpsGameUIBottomText">
                  {rpsGameData.message % 10 === 1
                    ? translatedText.rock
                    : rpsGameData.message % 10 === 2
                    ? translatedText.paper
                    : rpsGameData.message % 10 === 3
                    ? translatedText.scissors
                    : ''}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  } else if (gameState === 2) {
    rpsButton = (
      <>
        <button
          type="button"
          className="buttonOutlined buttonBlue"
          onClick={handleNext}
          disabled={!account || claimButtonDisabled || nextButtonDisabled}
        >
          {nextButtonDisabled ? <LoadingDots /> : translatedText.next}
        </button>
      </>
    );
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoading ? (
        <motion.div
          key="rpsGameRollButtonLoader"
          variants={{
            collapsed: { opacity: 0 },
            open: {
              opacity: 1
            }
          }}
          initial="collapsed"
          animate="open"
          exit="collapsed"
        >
          <LoadingSpinner />
        </motion.div>
      ) : (
        <motion.div
          key="rpsGameRollButtonWrapper"
          variants={{
            collapsed: { opacity: 0 },
            open: {
              opacity: 1
            }
          }}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          className="GameDiceValWrapper"
        >
          {rpsButton}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RPSGameUIButton;
