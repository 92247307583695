const LoadingDots = () => (
  <div className="loadingDotsWrapper">
    <ul className="loadingDots">
      <li />
      <li />
      <li />
    </ul>
  </div>
);

export default LoadingDots;
