import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import OnEGameTotalPointsScreen from './OnEGameTotalPointsScreen';
import OnEGameTotalPointsText from './OnEGameTotalPointsText';
import PlayerIcon from '../../utils/graphics/PlayerIcon';
import OnEGameTotalPointsRecent from './OnEGameTotalPointsRecent';
import AddressCopyButton from '../../utils/buttons/AddressCopyButton';
import translate from '../../../shared/functions/translate';

interface IProps {
  players: string[];
  scores: number[];
  holderIndex: number;
  gameState: number;
}

const OnEGameTotalPointsLeft: React.FC<IProps> = ({
  players,
  scores,
  holderIndex,
  gameState
}: IProps) => {
  // states and contexts
  const [winState, setWinState] = useState<string>('draw');
  const { account } = useWeb3React<Web3Provider>();

  // functions
  const calculateWinState = () => {
    if (scores[0] === scores[1]) {
      setWinState('draw');
    } else if (scores[0] > scores[1]) {
      setWinState('win');
    } else {
      setWinState('lose');
    }
  };

  useEffect(() => {
    calculateWinState();
  }, [players, scores]);

  return (
    <div className="oneGameTotalPointsWrapper">
      <OnEGameTotalPointsScreen
        totalPointsInput={scores[0]}
        winState={winState}
      />
      <div className="oneGameTotalPointsPlayerContainer">
        <div className="oneGameTotalPointsTextContainer">
          <span className="oneGameTotalPointsHeader">{translate('score')}</span>
          <OnEGameTotalPointsText totalPointsInput={scores[0]} />
        </div>
        <div className="playerWrapper">
          <div className="playerIcon">
            <PlayerIcon active={!!(account && players[0] === account)} />
          </div>
          <AddressCopyButton
            address={players[0]}
            active={!!(account && players[0] === account)}
          />
        </div>
        <OnEGameTotalPointsRecent
          positionIndex={0}
          totalPointsInput={scores[0]}
          holderIndex={holderIndex}
          gameState={gameState}
        />
      </div>
    </div>
  );
};

export default OnEGameTotalPointsLeft;
