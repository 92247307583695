import { useLocation } from 'react-router';
import translate from '../../shared/functions/translate';

interface IProps {
  currentBlock: number;
}

const shorter = (str: string) =>
  str?.length > 6 ? `...${str.slice(-4)}` : str;

const DBetGameBlockStats: React.FC<IProps> = ({ currentBlock }: IProps) => {
  // get roll block
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  return (
    <>
      <div className="prizeWrapper">
        <div className="prizeText">{translate('rollBlock')}</div>
        <div className="prizeValue">{`#${shorter(path)}`}</div>
      </div>
      <div className="prizeWrapper">
        <div className="prizeText">{translate('blocksLeft')}</div>
        <div className="prizeValue">{`#${
          currentBlock !== 0 && Number(path) - currentBlock > 0
            ? Number(path) - currentBlock
            : 0
        }`}</div>
      </div>
      <div className="prizeWrapper">
        <div className="prizeText">{translate('currentBlock')}</div>
        <div className="prizeValue">{`#${shorter(
          currentBlock.toString()
        )}`}</div>
      </div>
    </>
  );
};

export default DBetGameBlockStats;
