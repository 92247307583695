import { useHistory, useLocation } from 'react-router';
import arrowLeftIcon from '../../shared/constants/icons/arrow_left_icon.png';
import arrowRightIcon from '../../shared/constants/icons/arrow_right_icon.png';
import translate from '../../shared/functions/translate';
import { IJSONObject } from '../../shared/interfaces';

const DBetGameNavigate = () => {
  // redirect functions
  const location = useLocation();
  const path = Number(location.pathname.split('/')[2]);
  const history = useHistory();

  const nextGame = () => path !== 0 && history.push(`/dbetgame/${path + 100}`);
  const prevGame = () => path !== 0 && history.push(`/dbetgame/${path - 100}`);

  // fixed hooks translation
  const translatedText: IJSONObject = {
    lobbyNext: translate('lobbyNext'),
    lobbyPrev: translate('lobbyPrev')
  };

  return (
    <>
      <div
        className="dbetGameNavigateIconContainer dbetGameNavigateIconContainerLeft"
        onClick={prevGame}
      >
        <img
          src={arrowLeftIcon}
          alt="arrowLeftIcon"
          className="dbetGameNavigateIcon"
        />
        <div className="dbetGameNavigateText">{translatedText.lobbyPrev}</div>
      </div>
      <div
        className="dbetGameNavigateIconContainer dbetGameNavigateIconContainerRight"
        onClick={nextGame}
      >
        <img
          src={arrowRightIcon}
          alt="arrowLeftIcon"
          className="dbetGameNavigateIcon"
        />
        <div className="dbetGameNavigateText">{translatedText.lobbyNext}</div>
      </div>
    </>
  );
};

export default DBetGameNavigate;
