import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import infoIcon from '../../../shared/constants/icons/info_icon.png';
import arrowUpIcon from '../../../shared/constants/icons/arrow_up_icon.png';
import arrowDownIcon from '../../../shared/constants/icons/arrow_down_icon.png';
import { IJSONObject } from '../../../shared/interfaces';
import translate from '../../../shared/functions/translate';

const OnEInfoCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  // fixed hooks translation
  const translatedText: IJSONObject = {
    gameInfo: translate('gameInfo'),
    howToPlay: translate('howToPlay'),
    rules: translate('rules'),
    oneHowToPlay1: translate('oneHowToPlay1'),
    oneHowToPlay2: translate('oneHowToPlay2'),
    oneHowToPlay3: translate('oneHowToPlay3'),
    oneHowToPlay4: translate('oneHowToPlay4'),
    oneHowToPlay5: translate('oneHowToPlay5'),
    oneHowToPlay6: translate('oneHowToPlay6'),
    oneHowToPlay7: translate('oneHowToPlay7'),
    oneHowToPlay8: translate('oneHowToPlay8'),
    oneHowToPlay9: translate('oneHowToPlay9'),
    oneHowToPlay10: translate('oneHowToPlay10'),
    oneHowToPlay11: translate('oneHowToPlay11'),
    oneRules1: translate('oneRules1'),
    oneRules2: translate('oneRules2'),
    oneRules3: translate('oneRules3'),
    oneRules4: translate('oneRules4'),
    oneRules5: translate('oneRules5'),
    oneRules6: translate('oneRules6'),
    oneRules7: translate('oneRules7'),
    oneRules8: translate('oneRules8')
  };

  return (
    <div className="card">
      <div className="cardTitle">
        <img src={infoIcon} alt="infoIcon" className="gameInfoIcon" />
        {translatedText.gameInfo}
      </div>
      <AnimatePresence key="gameInfoButton" exitBeforeEnter>
        {!isOpen ? (
          <motion.button
            key="arrowDownButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowDownIcon}
              alt="arrowDownIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        ) : (
          <motion.button
            key="arrowUpButton"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="gameInfoButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          >
            <img
              src={arrowUpIcon}
              alt="arrowUpIcon"
              className="gameInfoCollapseIcon"
            />
          </motion.button>
        )}
      </AnimatePresence>
      <motion.div
        key="gameInfoCard"
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: '802px',
            transition: {
              delayChildren: 0.6,
              staggerChildren: 0.6,
              duration: 1.2,
              ease: 'easeInOut'
            }
          },
          collapsed: {
            height: '0px'
          }
        }}
        transition={{ duration: 1.2, ease: 'easeInOut' }}
      >
        {isOpen && (
          <>
            <motion.div
              key="gameInfoHowToPlay"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="innerCard"
            >
              <div className="innerCardTitle">{translatedText.howToPlay}</div>
              <ul className="gameInfoCardBody">
                <li>
                  <p>
                    {translatedText.oneHowToPlay1}
                    <a href="https://metamask.io/">MetaMask</a>
                    {translatedText.oneHowToPlay2}
                  </p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay3}</p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay4}</p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay5}</p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay6}</p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay7}</p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay8}</p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay9}</p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay10}</p>
                </li>
                <li>
                  <p>{translatedText.oneHowToPlay11}</p>
                </li>
              </ul>
            </motion.div>
            <motion.div
              key="gameInfoRules"
              variants={{
                open: {
                  opacity: 1
                },
                collapsed: { opacity: 0 }
              }}
              className="innerCard"
            >
              <div className="innerCardTitle">{translatedText.rules}</div>
              <ul className="gameInfoCardBody">
                <li>
                  <p>{translatedText.oneRules1}</p>
                </li>
                <li>
                  <p>{translatedText.oneRules2}</p>
                </li>
                <li>
                  <p>{translatedText.oneRules3}</p>
                </li>
                <li>
                  <p>{translatedText.oneRules4}</p>
                </li>
                <li>
                  <p>{translatedText.oneRules5}</p>
                </li>
                <li>
                  <p>{translatedText.oneRules6}</p>
                </li>
                <li>
                  <p>{translatedText.oneRules7}</p>
                </li>
                <li>
                  <p>{translatedText.oneRules8}</p>
                </li>
              </ul>
            </motion.div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default OnEInfoCard;
